import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import api from 'services/api';
import { validatePermission } from 'actions';
import Text from 'v3/components/Text';
import Button from 'v3/components/Button';
import Select from 'v3/components/Select';
import Tooltip from 'v3/components/Tooltip';
import Card, { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { useSnackbar } from 'v3/components/Snackbar';
import { useTravel } from 'v3/pages/Travel';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import EditableContent from '../EditableContent';
import { FaQuestionCircle } from 'react-icons/fa';
import theme from 'v3/theme';

export default function MercosulStatus({ mercosulRef }) {
  const { isTravelBlocked } = useContext(BlockedTravelContext);
  const travel = useTravel();
  const snackbar = useSnackbar();
  const [mercosulStatus, setMercosulStatus] = useState(null);
  const hasPermissionToEditStatus = validatePermission('ALTERAR_VIAGEM');
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [mercosulTravel, setMercosulTravel] = useState(false);
  const [mercosulOptions, setMercosulOptions] = useState([]);

  const validTravelStatus = ['ST6', 'ST7', 'ST10'].includes(travel?.data?.travelStatus?.code)

  useEffect(() => {
    async function fetchMercosulStatusOptions() {
      const response = await api.get('v3/status-mercosul');
      setMercosulOptions(
        response.data.map(item => {
          return {
            value: item.id,
            label: item.title,
          };
        })
      );
    }
    fetchMercosulStatusOptions();
  }, []);

  useEffect(() => {
    if (travel.data?.statusMercosulId && mercosulOptions) {
      setMercosulStatus(
        mercosulOptions.find(
          item => item.value === travel.data.statusMercosulId
        )
      );
    } else {
      setMercosulStatus(null);
    }
  }, [travel?.data?.statusMercosulId, mercosulOptions]);

  useEffect(() => {
    if (
      travel.data?.loads?.loadOrigins &&
      travel.data?.loads?.loadDestinations
    ) {
      const origin = travel?.data.loads.loadOrigins.map(item => {
        return item?.countrySlug;
      });
      const destinations = travel?.data?.loads?.loadDestinations.map(item => {
        return item?.countrySlug;
      });
      const places = new Set([...origin, ...destinations]);
      if (places.size > 1) {
        setMercosulTravel(true);
      }
    }
  }, [travel?.data]);

  async function handleSubmit() {
    const mercosulStatusId = mercosulStatus?.value;
    try {
      setLoading(true);
      await api.put(`travel-data/updateMercosulStatus/${travel?.data?.id}`, {
        status_mercosul_id: mercosulStatusId,
      });
      snackbar.show(<Text>Status da MERCOSUL atualizado!</Text>, {
        type: 'success',
      });
    } catch (error) {
      snackbar.show(
        <Text>
          Não foi possível alterar o status da MERCOSUL. Tente novamente mais
          tarde.
        </Text>,
        { type: 'error' }
      );
    } finally {
      setEditing(false);
      setLoading(false);
    }
  }
  return (
    <Card
      ref={mercosulRef}
      loading={travel.isLoading}
      LoadingBodyComponent={() => (
        <DefaultLoadingBodyComponent linesCount={4} perLine={2} />
      )}
      header={
        <Text color="dark" type="header">
          MERCOSUL
        </Text>
      }
      HeaderRightComponent={
        hasPermissionToEditStatus && (
          <div>
            {editing && (
              <Button
                variant="secondary"
                loading={loading}
                className="py-2 mx-2"
                onClick={() => {
                  setEditing(false);
                  setMercosulStatus(
                    travel.data.statusMercosulId
                      ? mercosulOptions.find(
                        item => item.value === travel.data.statusMercosulId
                      )
                      : null
                  );
                }}
              >
                <Text color="dark" type="regular" weight="500">
                  Cancelar
                </Text>
              </Button>
            )}
            <Button
              disabled={!mercosulTravel || isTravelBlocked || validTravelStatus}
              onClick={editing ? () => handleSubmit() : () => setEditing(true)}
              variant={editing ? 'primary' : 'secondary'}
              loading={loading}
              className="py-2"
            >
              <Text
                color={editing ? 'white' : 'dark'}
                weight="500"
                type="regular"
              >
                {editing ? 'Salvar' : 'Editar'}
              </Text>
            </Button>

            {

              !editing && (
                <span style={{ marginLeft: '10px' }}>
                  <Tooltip
                    content={
                      <Text type="label" color="white">
                        {
                          `Este card não pode ser editado se a viagem estiver nos seguintes status: Finalizada, Transbordada, Cancelada,
                          ou se a viagem não for da MERCOSUL`
                        }
                      </Text>
                    }
                  >
                    <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
                  </Tooltip>
                </span>
              )
            }
          </div>
        )
      }
    >
      <Row>
        {mercosulTravel ? (
          <Col xs={12}>
            <EditableContent
              label="Status Mercosul"
              display={
                <Text type="regular" color="gray">
                  {mercosulStatus
                    ? `${mercosulStatus?.label}`
                    : 'Não informado'}
                </Text>
              }
              isEditing={editing}
              EditingComponent={({ label }) => (
                <Select
                  label={label}
                  placeholder="Selecione..."
                  value={mercosulStatus}
                  onChange={value => setMercosulStatus(value)}
                  options={mercosulOptions}
                />
              )}
            />
          </Col>
        ) : (
          <Col xs={12}>
            <Text type="regular" color="gray">
              Não informado
            </Text>
          </Col>
        )}

      </Row>
    </Card>
  );
}

import {
  formatDateTime,
  formatCurrency,
  formatPercentage,
} from 'v3/utils/formatter';

export function capitalize(value) {
  if (value?.length) {
    let colUppercase = JSON.parse(
      localStorage.getItem('travelsControlTowerUserColumnUppercase')
    );
    if (!colUppercase) {
      colUppercase = false;
    }
    if (colUppercase) {
      return value.toUpperCase();
    }
    return value
      .toLowerCase()
      .split(' ')
      .map(word => {
        if (word.length > 2) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
      })
      .join(' ');
  }
  return value;
}

export const avaliableColumnsWithDefinitions = [
  {
    title: capitalize('Status Tracking'),
    slug: 'trackingStatusName',
    order: 1,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    size: 200,
    component: 'trackingStatusName',
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
  },
  {
    title: capitalize('Andamento Viagem (%)'),
    slug: 'trackingStatusPercentage',
    order: 2,
    classes: 'headerCells',
    columnStyles: { minWidth: 150, lineHeight: '1rem' },
    component: 'trackingStatusPercentage',
    componentStyles: { color: 'gray', fontSize: '0.75rem' },
  },
  {
    title: capitalize('Última Posição Raster'),
    slug: 'lastPositionDescription',
    order: 3,
    columnStyles: { minWidth: 250, lineHeight: '1rem', whiteSpace: 'nowrap' },
    component: 'lastPositionDescription',
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
  },
  {
    title: capitalize('Centro de Custo da Carga'),
    slug: 'cargCostCenterName',
    order: 4,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Tags da Carga'),
    slug: 'load_tags',
    order: 5,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Status Viagem'),
    slug: 'statusName',
    order: 6,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Motorista'),
    slug: 'driverName',
    order: 7,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Placa Tração'),
    slug: 'mainVehiclePlate',
    order: 8,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Placa dos Implementos'),
    slug: 'wagons_plate',
    order: 9,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Cliente Tomador'),
    slug: 'clientName',
    order: 10,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Cidade Origem'),
    slug: 'originCity',
    order: 11,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Cidade Destino'),
    slug: 'destinationCity',
    order: 12,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Distância Total'),
    slug: 'trackingTotalDistanceInMeters',
    order: 13,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? capitalize(`${value} km`) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Distância Restante'),
    slug: 'trackingRemainingDistanteInMeters',
    order: 14,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value !== null && value !== undefined
        ? capitalize(`${value} km`)
        : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Datas Agendadas Início Coletas'),
    slug: 'collectStartSchedule',
    order: 15,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value
        ? capitalize(value.map(v => formatDateTime(v)).join(', '))
        : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize(' Datas Agendadas Máxima Coletas'),
    slug: 'collectScheduledTimes',
    order: 16,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value
        ? capitalize(value.map(v => formatDateTime(v)).join(', '))
        : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Previsão de Chegada Calculada'),
    slug: 'trackingApproximateArrivalTime',
    order: 17,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? capitalize(formatDateTime(value)) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Nº da Carga'),
    slug: 'id',
    order: 18,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Link',
    to: value => `/cargas/${value}`,
  },
  {
    title: capitalize('Nº CTE/CRT'),
    slug: 'cteCrtNumbers',
    order: 19,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value?.map(v => v).join(', ') || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Nº NF-E'),
    slug: 'nfNumbers',
    order: 20,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value?.map(v => v).join(', ') || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize(' Último Log da Viagem'),
    slug: 'lastObs',
    order: 21,
    columnStyles: { minWidth: 120, lineHeight: '1rem', whiteSpace: 'nowrap' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize(' Destinos da Viagem'),
    slug: 'travelDestinations',
    order: 22,
    columnStyles: {
      minWidth: 120,
      lineHeight: '1rem',
      whiteSpace: 'nowrap',
    },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
    componentStyles: {
      color: 'gray',
      fontSize: '0.75rem',
      type: 'regular',
    },
  },
  {
    title: capitalize('PCP'),
    slug: 'pcpName',
    order: 23,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Negociador'),
    slug: 'negotiatorName',
    order: 24,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
    component: 'Text',
  },
  {
    title: capitalize('Nº Viagem Cooperplace'),
    slug: 'travel_id',
    order: 26,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => value || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Nº Viagem Benner'),
    slug: 'travelNumber',
    order: 27,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => value || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Data Emissão CTE/CRT'),
    slug: 'firstCTEDateEmitted',
    order: 28,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(formatDateTime(value)),
    component: 'Text',
  },
  {
    title: capitalize('UF / Pais Origem'),
    slug: 'originProvince',
    order: 29,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('UF / Pais Destino'),
    slug: 'destinationProvince',
    order: 30,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Data Chegada Coleta'),
    slug: 'currentCollectLocationArrivalTime',
    order: 35,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? formatDateTime(value) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Data Saída Coleta'),
    slug: 'currentCollectLocationEffectiveTime',
    order: 36,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? formatDateTime(value) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Data Chegada Entrega'),
    slug: 'currentDeliveryLocationArrivalTime',
    order: 37,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? formatDateTime(value) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Data Saída Entrega'),
    slug: 'currentDeliveryLocationEffectiveTime',
    order: 38,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? formatDateTime(value) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Distância Efetuada'),
    slug: 'trackingDistanceCoveredInMeters',
    order: 41,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    component: 'Text',
    format: value =>
      value !== null && value !== undefined
        ? capitalize(`${value} km`)
        : capitalize('Não informado'),
  },
  {
    title: capitalize('Datas Agendadas Início Aduana'),
    slug: 'customHouseStartSchedule',
    order: 42,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value?.length
        ? value.map(v => formatDateTime(v)).join(', ')
        : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Datas Agendadas Máxima Aduana'),
    slug: 'customHouseScheduledTimes',
    order: 43,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value?.length
        ? value.map(v => formatDateTime(v)).join(', ')
        : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Datas Agendadas Início Entrega'),
    slug: 'deliveryStartSchedule',
    order: 44,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value
        ? capitalize(value.map(v => formatDateTime(v)).join(', '))
        : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Datas Agendadas Máxima Entrega'),
    slug: 'deliveryScheduledTimes',
    order: 45,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value
        ? capitalize(value.map(v => formatDateTime(v)).join(', '))
        : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Rentabilidade bruta da viagem'),
    slug: 'profitability',
    order: 46,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? formatCurrency(value) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Margem bruta da viagem'),
    slug: 'margin',
    order: 47,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? formatPercentage(value / 100) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Ocorrências'),
    slug: 'occurrences',
    order: 48,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    component: 'occurencesComponent',
  },
  {
    title: capitalize('Margens'),
    slug: 'margins',
    order: 49,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => value || capitalize('Não informado'),
    component: 'marginsComponent',
  },
  {
    title: capitalize('Número Pedido'),
    slug: 'clientOrderNumber',
    order: 50,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    component: 'Text',
    format: value =>
      value !== null && value !== undefined
        ? value
        : capitalize('Não informado'),
  },
  {
    title: capitalize('Tipo Veículo de Tração'),
    slug: 'vehicleType',
    order: 51,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Tipo de Carroceria'),
    slug: 'vehicleBody',
    order: 52,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Segmento Implemento'),
    slug: 'vehicleSegment',
    order: 53,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Averbação'),
    slug: 'endorsement',
    order: 58,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value),
    component: 'endorsement',
  },
  {
    title: capitalize('Empresa'),
    slug: 'company',
    order: 52,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    component: 'Text',
  },
  {
    title: capitalize('Postos'),
    slug: 'routeStations',
    order: 59,
    columnStyles: { minWidth: 120, lineHeight: '1rem', WordWrap: 'normal' },
    format: value => {
      if (!value) {
        return null;
      }

      const stations = value.split(';;');
      return stations.map(station => {
        const [name, checkout] = station.split('##');
        return { name, checkout };
      });
    },
    component: 'RouteStation',
  },
  {
    title: capitalize('Telefone Motorista'),
    slug: 'driverPhone',
    order: 60,
    columnStyles: { minWidth: 120, lineHeight: '1rem', WordWrap: 'normal' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Produto'),
    slug: 'product',
    order: 61,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => capitalize(value) || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Transportadora'),
    slug: 'shippingCompanyName',
    order: 62,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    component: 'Text',
  },
  {
    title: capitalize('Temperatura da carga'),
    slug: 'temperature',
    order: 58,
    columnStyles: { minWidth: 200, lineHeight: '1rem' },
    component: 'temperature',
    format: value => (value ? capitalize(value) : capitalize('Não informado')),
  },
  {
    title: capitalize('Invoice'),
    slug: 'invoice',
    order: 63,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value => value || capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: 'Origem e Destinos',
    slug: 'destinations',
    order: 62,
    columnStyles: { minWidth: 210, lineHeight: '1rem' },
    component: 'Destinations',
  },
  {
    title: capitalize('Obs Averbação'),
    slug: 'endorsementObs',
    order: 63,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    component: 'Text',
  },
  {
    title: capitalize('Data Criação Viagem'),
    slug: 'travelCreatedAt',
    order: 64,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    format: value =>
      value ? formatDateTime(value) : capitalize('Não informado'),
    component: 'Text',
  },
  {
    title: capitalize('Detalhes'),
    slug: 'detailsButton',
    order: 99,
    columnStyles: { minWidth: 120, lineHeight: '1rem' },
    component: 'DetailsButton',
  },

];

function isNullOrEmpty(value) {
  if (value === null || value === undefined) {
    return '-1';
  }
  return value;
}

function isDateValid(dateValue) {
  if (dateValue === undefined || dateValue === null) {
    return new Date();
  }
  return new Date(dateValue);
}

function isNumberValid(numberValue) {
  if (numberValue === undefined || numberValue === null) {
    return Infinity;
  }
  return numberValue;
}

export const getCustomSortFunction = (rowA, rowB, sortColumn) => {
  switch (sortColumn) {
    case 'trackingStatusName':
    case 'lastPositionDescription':
    case 'load_tags':
    case 'statusName':
    case 'driverName':
    case 'mainVehiclePlate':
    case 'wagons_plate':
    case 'clientName':
    case 'originCity':
    case 'destinationCity':
    case 'lastObs':
    case 'travelDestinations':
    case 'pcpName':
    case 'negotiatorName':
    case 'originProvince':
    case 'destinationProvince':
    case 'travelNumber':
    case 'clientOrderNumber':
    case 'vehicleType':
    case 'vehicleSegment':
    case 'endorsement':
    case 'company':
    case 'driverPhone':
    case 'product':
    case 'invoice':
    case 'shippingCompanyName':
      return (a = rowA, b = rowB) => {
        return isNullOrEmpty(a.original[sortColumn]).localeCompare(
          isNullOrEmpty(b.original[sortColumn])
        );
      };
    case 'routeStations':
    case 'trackingStatusPercentage':
    case 'trackingTotalDistanceInMeters':
    case 'trackingRemainingDistanteInMeters':
    case 'id':
    case 'travel_id':
    case 'trackingDistanceCoveredInMeters':
    case 'profitability':
    case 'margin':
      return (a = rowA, b = rowB) => {
        return (
          isNumberValid(a.original[sortColumn]) -
          isNumberValid(b.original[sortColumn])
        );
      };
    case 'margins':
      return (a = rowA, b = rowB) => {
        return (
          isNumberValid(a.original.travelPendingMargins) -
          isNumberValid(b.original.travelPendingMargins)
        );
      };
    case 'cargCostCenterName':
      return (a = rowA, b = rowB) => {
        return (
          a.original[sortColumn].split(' ')[0] -
          b.original[sortColumn].split(' ')[0]
        );
      };
    case 'trackingApproximateArrivalTime':
    case 'firstCTEDateEmitted':
    case 'scheduled_time':
    case 'currentCollectLocationArrivalTime':
    case 'currentCollectLocationEffectiveTime':
    case 'currentDeliveryLocationArrivalTime':
    case 'currentDeliveryLocationEffectiveTime':
    case 'cteCrtNumbers':
    case 'nfNumbers':
      return (a = rowA, b = rowB) => {
        return (
          isNumberValid(a.original[sortColumn][0]) -
          isNumberValid(b.original[sortColumn][0])
        );
      };
    case 'collectStartSchedule':
    case 'collectScheduledTimes':
    case 'customHouseStartSchedule':
    case 'customHouseScheduledTimes':
    case 'deliveryScheduledTimes':
    case 'deliveryStartSchedule':
      return (a = rowA, b = rowB) => {
        return (
          isDateValid(a.original[sortColumn][0]) -
          isDateValid(b.original[sortColumn][0])
        );
      };
    case 'occurrences':
      return (a = rowA, b = rowB) => {
        return (
          isNumberValid(Number(b.original.travelFinishedOccurrences)) -
          isNumberValid(Number(a.original.travelFinishedOccurrences))
        );
      };
    case 'temperature':
      return (a = rowA, b = rowB) => {
        return (
          isNumberValid(a.original[sortColumn]) -
          isNumberValid(b.original[sortColumn])
        );
      };
    default:
      return 'text';
  }
};

export const trackingStatusColor = {
  CURRENT_POSITION_NOT_AVAILABLE: 'warning',
  ON_SCHEDULE: 'success',
  LATE_ON_SCHEDULE: 'danger',
  EARLY_ON_TARGET: 'success',
  ON_SCHEDULE_AND_TARGET: 'success',
  LATE_ON_TARGET: 'danger',
  ON_SCHEDULE_FOR_COLLECT: 'success',
  LATE_FOR_COLLECT: 'danger',
};

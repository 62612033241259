import React, { useState, useEffect, useRef } from 'react';

import api from 'services/api';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Radio from 'v3/components/Radio';
import { Modal, Text, Select, Button } from 'v3/components';
import InputAddress from 'v3/components/InputAddress';
import Input from 'v3/components/Input';
import { useSnackbar } from 'v3/components/Snackbar';

import { fetchCountries } from 'services/api/Locations';
import { useClient } from '../context';

export default function AddClientModal({
  isOpen,
  setOpen,
  onClose,
  editingAddress,
}) {
  const client = useClient();
  const params = useParams();
  const snackbar = useSnackbar();
  const addressRef = useRef(null);

  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);
  const [addressDisabled, setAddressDisabled] = useState(false);
  const [isCurrent, setIsCurrent] = useState(false);
  const [fullAddress, setFullAddress] = useState({});
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState({
    id: 1,
    name: 'Brasil',
    abbreviation: 'br',
    ddi: '55',
    currency: 'BRL',
    currency_symbol: 'R$',
  });

  useEffect(() => {
    if (editingAddress) {
      const cityModel = editingAddress.city_id
        ? {
            name: editingAddress.city_name || editingAddress.city,
            id: editingAddress.city_id,
            province: editingAddress?.province_id
              ? {
                  name: editingAddress?.province || editingAddress?.uf_name,
                  id: editingAddress?.province_id,
                }
              : null,
          }
        : null;
      setIsCurrent(!!editingAddress.current);
      setFullAddress({
        ...editingAddress,
        city: cityModel,
        province: editingAddress.uf_name || editingAddress.province,
      });
      if (editingAddress?.formatted_address) {
        disableAddress(editingAddress, cityModel);
      }
    }
  }, [editingAddress]);

  function handleResetStates() {
    setAddressDisabled(false);
    setDisableCity(false);
    setDisableNeighborhood(false);
    setDisableProvince(false);
    setDisableNumber(false);
    setDisableZipCode(false);
    setFullAddress({
      city: null,
      formatted_address: '',
      address: '',
      zip_code: '',
      number: '',
      neighborhood: '',
      province: '',
      complement: '',
      lat: '',
      lng: '',
    });
  }

  function handleClose() {
    handleResetStates();
    onClose();
  }

  async function onSave(event) {
    event.preventDefault();
    if (params.id) {
      try {
        const response = await api.put(`v3/client/${client.data.id}/address`, {
          data: {
            id: editingAddress?.id ? editingAddress.id : undefined,
            city_name: fullAddress.city?.name,
            current: !client.data.addresses.length ? true : isCurrent,
            address: fullAddress.address,
            person_id: client.data.id,
            zip_code: fullAddress.zip_code,
            number: fullAddress.number,
            complement: fullAddress.complement,
            neighborhood: fullAddress.neighborhood,
            uf_name: fullAddress.province,
            lat: fullAddress?.lat,
            lng: fullAddress?.lng,
            city_id: fullAddress.city?.id,
            province_id: fullAddress?.city?.province_id,
            country_id: country?.id,
            formatted_address:fullAddress?.formatted_address
          },
        });
        client.setData({ addresses: response.data });
      } catch (error) {
        snackbar.show(<Text>Erro ao adicionar endereço</Text>, {
          type: 'error',
        });
      }
    } else {
      client.setData({
        addresses: [
          ...client.data.addresses.map(item =>
            isCurrent ? { ...item, current: false } : item
          ),
          {
            ...fullAddress,
            city: fullAddress.city?.name,
            province_name: fullAddress?.province,
            city_name: fullAddress.city?.name,
            current: client.data?.addresses?.length ? isCurrent : true,
            city_id: fullAddress.city?.id,
            country_id: country?.id,
            province_id: fullAddress?.city?.province_id,
          },
        ],
      });
    }
    setAddressDisabled(false);
    setFullAddress({});
    setIsCurrent(false);
    setOpen(false);
  }

  useEffect(() => {
    if (isOpen) {
      addressRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    fetchCountries().then(setCountries);
  }, []);

  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function disableAddress(address, city) {
    setAddressDisabled(address?.address ? true : false);
    setDisableNumber(address.number ? true : false);
    setDisableZipCode(address.zip_code ? true : false);
    setDisableCity(city ? true : false);
    setDisableProvince(city?.province ? true : false);
    setDisableNeighborhood(address.neighborhood ? true : false);
  }

  return (
    <Modal
      show={isOpen}
      handleClose={handleClose}
      heading={
        <Text type="header" color="dark">
          Adicionar endereço
        </Text>
      }
      body={
        <form onSubmit={onSave}>
          <Row className="px-2 form">
            <Col xs={12}>
              <Select
                label="País"
                options={countries}
                value={country}
                onChange={value => {
                  handleResetStates();
                  setCountry(value);
                }}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                disableClear
              />
            </Col>
            <Col xs={12}>
              <InputAddress
                ref={addressRef}
                label="Procure seu endereço *"
                placeholder="Digite seu endereço"
                country={country.abbreviation}
                types={[]}
                onChange={value => {
                  setFullAddress(prev => ({
                    ...prev,
                    formatted_address: value.target.value,
                  }));
                }}
                onSelectedAddress={async place => {
                  const [city] = await fetchProvinces(
                    place.city,
                    place.province,
                    place.country_abbreviation
                  );
                  disableAddress(place, city);

                  setFullAddress(prev => ({
                    ...prev,
                    address: place.address ? place.address : '',
                    number: place?.number ? place.number : '',
                    zip_code: place.zip_code ? place.zip_code : '',
                    city: city,
                    complement: place.name ? place.name : '',
                    neighborhood: place.neighborhood ? place.neighborhood : '',
                    province: city?.province?.uf || '',
                    lat: place.lat,
                    lng: place.lng,
                    formatted_address: place.formatted_address,
                  }));
                }}
                value={fullAddress?.formatted_address}
              />
            </Col>

            <>
              <Col xs={12}>
                <Input
                  label="Rua *"
                  placeholder="Digite sua rua"
                  onChange={value => {
                    setFullAddress(prev => ({
                      ...prev,
                      address: value.target.value,
                    }));
                  }}
                  value={fullAddress?.address}
                  disabled={addressDisabled}
                />
              </Col>
              <Col md={6} xs={12}>
                <Input
                  label="Número"
                  value={fullAddress?.number}
                  onChange={event =>
                    setFullAddress({
                      ...fullAddress,
                      number: event.target.value,
                    })
                  }
                  disabled={disableNumber}
                />
              </Col>
              <Col md={6} xs={12}>
                <Input
                  label="Complemento"
                  value={fullAddress?.complement}
                  onChange={event =>
                    setFullAddress({
                      ...fullAddress,
                      complement: event.target.value,
                    })
                  }
                />
              </Col>
              <Col md={6} xs={12}>
                <Input
                  label="Bairro *"
                  value={fullAddress?.neighborhood}
                  onChange={event =>
                    setFullAddress({
                      ...fullAddress,
                      neighborhood: event.target.value,
                    })
                  }
                  disabled={disableNeighborhood}
                />
              </Col>
              <Col md={6} xs={12}>
                <Input
                  label="CEP"
                  value={fullAddress?.zip_code}
                  onChange={event =>
                    setFullAddress({
                      ...fullAddress,
                      zip_code: event.target.value,
                    })
                  }
                  disabled={disableZipCode}
                />
              </Col>
              <Col md={8} xs={12}>
                <Select.Async
                  label="Cidade *"
                  onSearch={city =>
                    fetchProvinces(city, '', country?.abbreviation)
                  }
                  value={fullAddress?.city}
                  horizontal
                  onChange={value =>
                    setFullAddress(old => ({
                      ...old,
                      city: value,
                      province: value?.province?.uf || '',
                    }))
                  }
                  getOptionLabel={option =>
                    `${`${option.name} ${
                      option.province?.uf ? `- ${option.province?.uf}` : ''
                    }`}`
                  }
                  getOptionValue={option =>
                    `${`${option.name} ${
                      option.province?.uf ? `- ${option.province?.uf}` : ''
                    }`}`
                  }
                  isDisabled={disableCity}
                />
              </Col>
              <Col md={4} xs={12}>
                <Input
                  label="UF *"
                  value={fullAddress?.province}
                  onChange={event =>
                    setFullAddress({
                      ...fullAddress,
                      province: event.target.value,
                    })
                  }
                  disabled={disableProvince}
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Latitude"
                  disabled={true}
                  value={fullAddress?.lat}
                  tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                />
              </Col>
              <Col md={6}>
                <Input
                  label="Longitude"
                  disabled={true}
                  value={fullAddress?.lng}
                  tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                />
              </Col>
            </>

            {!!client.data.addresses?.length && (
              <Col xs={12} className="mt-3">
                <Radio.Group
                  label="Tornar o endereço principal"
                  onChange={({ target }) => setIsCurrent(target.value)}
                  value={isCurrent}
                  horizontal
                >
                  <Radio value>
                    <Text color="dark" type="label">
                      Sim
                    </Text>
                  </Radio>
                  <Radio value={false}>
                    <Text color="dark" type="label">
                      Não
                    </Text>
                  </Radio>
                </Radio.Group>
              </Col>
            )}
          </Row>
          <div className="text-center">
            <Button
              type="submit"
              disabled={
                !fullAddress.city ||
                !fullAddress.formatted_address ||
                !fullAddress.address ||
                !fullAddress.neighborhood ||
                !fullAddress.lat ||
                !fullAddress.lng
              }
            >
              Salvar
            </Button>
          </div>
        </form>
      }
    />
  );
}

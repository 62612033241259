import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import theme from 'v3/theme';

export const BoxContainer = styled(Col)`
  display: flex;
  flex-direction: Row;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  & > span {
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }
`;

export const ColStyled = styled.div`
  display: flex;
  flex-direction: Row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
`;

export const StatusColStyled = styled.div`
  display: flex;
  flex-direction: Row;
  align-items: center;
  margin-left: auto;
  line-height: 1;
`;

export const ColContainer = styled(Col)``;

export const InfoContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-content: space-between;
  padding: 4px 8px;
  span {
    font-size: 2rem;
    font-weight: bold;
  }
  #onTimeMdfesText {
    color: blue;
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  #delayedMdfesText {
    color: purple;
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  #onTimeText {
    color: black;
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  #onTime {
    color: green;
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  #delayed {
    color: ${theme.colors.danger};
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  #noRaster {
    color: orange;
    font-weight: bold !important;
    font-size: 1.1rem !important;
  }
  #customText {
    color: #878787;
    font-size: 1.1rem !important;
    font-weight: bold !important;
  }
`;

export const DelayInfoContainer = styled.div`
  background: ${theme.colors.dark_blue};
  color: ${theme.colors.white};
  flex: 1;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledSpan = styled.span`
  title: ${props => props.title};
  cursor: help;
  &::before {
    content: '';
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: ${props => props.color};
  }
`;

export const TravelStatusCardLabel = styled(Col)`
  width: 100%;
  color: ${theme.colors.dark_blue};
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & > span::before {
    content: '';
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 25%;
    background-color: red;
    margin-right: 0.3rem;
  }
  & > span:first-child::before {
    background-color: green;
  }
`;

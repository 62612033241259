import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useSnackbar } from 'v3/components/Snackbar';
import { currencyOptions } from 'v3/utils/formatter';
import { validatePermission } from 'actions';
import { Button, Card, Text } from 'v3/components';
import api, { cooperplaceApi } from 'services/api';
import { getPercentageFromValue } from 'v3/utils/functions';
import { Col } from 'react-bootstrap';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { MinimumMarginModal } from 'v3/pages/Travel/shared';
import { useModal } from 'hooks';
import { FreightDetails } from './FreightDetails';
import {
  LoadValueTypeOptions,
  NegotiationTypeOptions,
} from '../../Register/PriceData/utils';
import { editFreightSchema } from './validator';
import { FreightEditing } from './FreightEditing';

export function FreightValues({
  loadingFreightOptions,
  clientFreightOptions,
  load,
  getLoad,
  isFetching,
  onGoingTravelStatusId,
  rateResponse,
  loadingRate,
  showDailyRate,
  setShowDailyRate,
  clientCardToggle,
  setClientCardToggle,
  inputsCardToggle,
  setInputsCardToggle,
  negotiatedCardToggle,
  setNegotiatedCardToggle,
  discountsCardToggle,
  setDiscountsCardToggle,
  transferCardToggle,
  setTransferCardToggle,
}) {
  const { isTravelBlocked, isTravelReproved, isMarginAbove } =
    useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const lowerMarginModal = useModal();
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [hasPermissionToEdit, setPermissionToEdit] = useState();
  const [errors, setErrors] = useState({});

  // prefreight states
  const [exchange, setExchange] = useState(null);
  const [exchangeDay, setExchangeDay] = useState(null);
  const [currencyExchange, setCurrencyExchange] = useState(null);
  const [loadType, setLoadType] = useState(null);
  const [negotiationType, setNegotiationType] = useState(null);

  // client states
  const [cargoValue, setCargoValue] = useState(null);
  const [takerValue, setTakerValue] = useState(null);
  const [toll, setToll] = useState(null);
  const [tollCode, setTollCode] = useState(null);
  const [discharge, setDischarge] = useState(null);
  const [dischargeCode, setDischargeCode] = useState(null);
  const [griss, setGriss] = useState(null);
  const [grissPercentage, setGrissPercentage] = useState(null);
  const [grissAdCode, setGrissAdCode] = useState(null);
  const [adValorem, setAdValorem] = useState(null);
  const [adValoremPercentage, setAdValoremPercentage] = useState(null);
  const [charge, setCharge] = useState(null);
  const [chargeCode, setChargeCode] = useState(null);
  const [ferry, setFerry] = useState(null);
  const [ferryCode, setFerryCode] = useState(null);
  const [icms, setIcms] = useState(null);
  const [icmsPercentage, setIcmsPercentage] = useState(null);
  const [icmsCode, setIcmsCode] = useState(null);
  const [clientExtraFields, setClientExtraFields] = useState(null);

  // inputs states
  const [cooperInsuranceId, setCooperInsuranceId] = useState(null);
  const [cooperInsurance, setCooperInsurance] = useState(null);
  const [cooperInsurancePercentage, setCooperInsurancePercentage] =
    useState(null);
  const [inputsExtraFields, setInputsExtraFields] = useState(null);
  const [totalInputs, setTotalInputs] = useState(null);

  // shipper discounts
  const [issueRate, setIssueRate] = useState(null);
  const [freightMargin, setFreightMargin] = useState(null);
  const [shipperDiscountsExtraFields, setShipperDiscountsExtraFields] =
    useState(null);
  const [totalDiscounts, setTotalDiscounts] = useState(null);

  // shipper transfer
  const [shipperTransferExtraFields, setShipperTransferExtraFields] =
    useState(null);
  const [totalShipperTransfer, setTotalShipperTransfer] = useState(null);
  const [pcpObs, setPcpObs] = useState(null);

  // is attended
  const [attendedInfo, setAttendedInfo] = useState({
    isAttended: false,
    serviceLevel: '',
  });

  const totalTakerMemo = useMemo(() => {
    let total = 0;
    if (clientExtraFields?.length) {
      total = clientExtraFields.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        }
        return acc + Number(curr?.value);
      }, 0);
    }
    if (takerValue) {
      total += Number(takerValue);
    }
    if (toll && tollCode?.code !== 'client_provides_card') {
      total += Number(toll);
    }
    if (discharge) {
      total += Number(discharge);
    }
    if (griss) {
      total += Number(griss);
    }
    if (adValorem) {
      total += Number(adValorem);
    }
    if (icms) {
      total += Number(icms);
    }
    if (ferry) {
      total += Number(ferry);
    }
    if (charge) {
      total += Number(charge);
    }
    return total;
  }, [
    takerValue,
    clientExtraFields,
    toll,
    discharge,
    charge,
    griss,
    adValorem,
    icms,
    ferry,
    tollCode,
  ]);

  const totalItems = useMemo(() => {
    let total = 0;
    if (clientExtraFields?.length) {
      total = clientExtraFields.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        }
        return acc + Number(curr?.value);
      }, 0);
    }
    if (takerValue) {
      total += Number(takerValue);
    }
    if (toll && tollCode?.code !== 'client_provides_card') {
      total += Number(toll);
    }
    if (discharge) {
      total += Number(discharge);
    }
    if (griss) {
      total += Number(griss);
    }
    if (adValorem) {
      total += Number(adValorem);
    }
    if (ferry) {
      total += Number(ferry);
    }
    if (charge) {
      total += Number(charge);
    }
    return total;
  }, [
    takerValue,
    clientExtraFields,
    toll,
    discharge,
    charge,
    griss,
    adValorem,
    ferry,
    tollCode,
  ]);

  function setDefaultValue(key, code) {
    return clientFreightOptions[key]?.find(item => item?.code === code);
  }
  function setLoadValues(load) {
    // pre freight
    setExchange(load?.freightExchange?.toFixed(4) || load?.exchange);
    setExchangeDay(load?.exchangeDay?.toFixed(4) || load?.exchange);
    setCurrencyExchange(
      currencyOptions.find(item => item.value === load?.currency)
    );
    const loadValueType = LoadValueTypeOptions.find(
      item => item.value === load?.loadValueType
    );
    setLoadType(loadValueType || null);
    const negotiatioValueType = NegotiationTypeOptions.find(
      item => item.value === load?.negotiationType
    );
    setNegotiationType(negotiatioValueType || null);

    // client
    setCargoValue(load?.cargoValue?.toFixed(2));
    setTakerValue(load?.takerValue?.toFixed(2));
    setToll(load?.tollValue?.toFixed(2));
    setTollCode(
      load?.tollObservation || setDefaultValue('toll', 'not_selected')
    );
    setDischarge(load?.dischargeValue?.toFixed(2));
    setDischargeCode(
      load?.dischargeObservation ||
        setDefaultValue('discharge', 'not_selected_discharge')
    );
    setGriss(load?.gris?.toFixed(2));
    setGrissAdCode(
      load?.grissAdObservation ||
        setDefaultValue('griss_ad_valorem', 'not_selected_griss')
    );
    setGrissPercentage(
      load?.grisPercentage ||
        getPercentageFromValue(load?.gris, load?.cargoValue)
    );
    setAdValorem(load?.adValorem?.toFixed(2));
    setAdValoremPercentage(
      load?.adValoremPercentage ||
        getPercentageFromValue(load?.adValorem, load?.cargoValue)
    );
    setCharge(load?.chargeValue?.toFixed(2));
    setChargeCode(
      load?.chargeObservation ||
        setDefaultValue('loading_fee', 'not_selected_fee')
    );
    setFerry(load?.ferry?.toFixed(2));
    setFerryCode(
      load?.ferryObservation || setDefaultValue('ferry', 'not_selected_ferry')
    );
    setIcms(load?.icms?.toFixed(2));
    setIcmsCode(
      load?.icmsObservation || setDefaultValue('icms', 'not_selected_icms')
    );

    const totalItemsWithoutIcms = load?.totalTakerValue - load?.icms;
    setIcmsPercentage(
      load?.icmsPercentage ||
        Number(
          100 *
            (1 -
              (
                totalItemsWithoutIcms /
                (totalItemsWithoutIcms + Number(load?.icms))
              )?.toFixed(2))
        )?.toFixed(2)
    );
    setClientExtraFields(
      load?.extraFields?.map(item => {
        return { ...item, value: item?.value?.toFixed(2) };
      })
    );

    // inputs
    const cooperIns = load?.cargInputs?.find(
      item => item.label.toLowerCase() === 'seguro coopercarga'
    );
    setCooperInsurance(cooperIns ? cooperIns.value?.toFixed(2) : null);
    setCooperInsuranceId(cooperIns ? cooperIns.id : null);
    setCooperInsurancePercentage(
      cooperIns
        ? getPercentageFromValue(cooperIns.value, load?.cargoValue)
        : null
    );
    const cargInputsWithoutCooperInsurance = load?.cargInputs?.filter(
      item => item.label.toLowerCase() !== 'seguro coopercarga'
    );
    setInputsExtraFields(
      cargInputsWithoutCooperInsurance?.map(item => {
        return { ...item, value: item?.value?.toFixed(2) };
      })
    );
    setTotalInputs(load?.totalInputs);

    // shipper discounts
    setFreightMargin(load?.fareCompany?.toFixed(2));
    const issue = load?.shipmentDiscounts?.find(
      item => item.label.toLowerCase() === 'taxa de emissão'
    );
    setIssueRate(issue ? issue.value?.toFixed(2) : null);
    const cargShipmentDiscountsWithoutTaxEmmited =
      load?.shipmentDiscounts?.filter(
        item => item.label.toLowerCase() !== 'taxa de emissão'
      );
    setShipperDiscountsExtraFields(
      cargShipmentDiscountsWithoutTaxEmmited?.map(item => {
        return { ...item, value: item?.value?.toFixed(2) };
      })
    );
    setTotalDiscounts(load?.totalShipmentDiscounts);

    // shipper transfer
    setShipperTransferExtraFields(
      load?.shipmentTransfers?.map(item => {
        return { ...item, value: item?.value?.toFixed(2) };
      })
    );
    setTotalShipperTransfer(load?.totalShipmentTransfer);
    setPcpObs(load?.pcpObservation);

    // is attended
    setAttendedInfo({
      isAttended: !!load?.travel?.id,
      serviceLevel:
        load?.travel?.shipper?.shippingCompany?.descriptionServiceLevel,
    });
  }

  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_CARGAS'));
  }, []);

  useEffect(() => {
    if (load) {
      setLoadValues(load);
    }
  }, [load, editing]);

  useEffect(() => {
    if (totalTakerMemo >= 0 && totalInputs >= 0) {
      setFreightMargin(
        (Number(totalTakerMemo) - Number(totalInputs))?.toFixed(2)
      );
    }
  }, [totalTakerMemo, totalInputs]);

  useEffect(() => {
    if (!takerValue || takerValue === '0') {
      setCooperInsurance('0');
      setCooperInsurancePercentage('0');
    } else {
      setCooperInsurance((cooperInsurance / 100) * takerValue);
    }
  }, [takerValue]);
  useEffect(() => {
    if (!totalItems || totalItems == '0.00') {
      setIcms(0);
      setIcmsPercentage(0);
    } else if (totalItems && icmsPercentage) {
      const computedValue = Number(
        totalItems / (1 - icmsPercentage / 100) - totalItems
      )?.toFixed(2);
      setIcms(computedValue);
    }
  }, [totalItems]);

  async function validate(data, setErrors) {
    const errorList = {};
    try {
      await editFreightSchema.validate(data, { abortEarly: false });
      setErrors({ ...errorList });
      return true;
    } catch (err) {
      if (err.inner) {
        err.inner.forEach(error => {
          errorList[error.path] = error.message;
        });
        setErrors({ ...errorList });
        return false;
      }
    }
  }
  async function handleSubmit(submitOptions) {
    const data = { takerValue, pcpObs, exchange, loadType, negotiationType };
    try {
      setLoading(true);
      if (!(await validate(data, setErrors))) {
        snackbar.show(<Text>Verifique os campos obrigatórios.</Text>, {
          type: 'error',
        });
        return false;
      }
      const editedData = {
        toll_value: +toll,
        action_toll: tollCode?.id,
        discharge_value: +discharge,
        action_discharge: dischargeCode?.id,
        charge_value: +charge,
        action_charge: chargeCode?.id,
        ferry: +ferry,
        action_ferry: ferryCode?.id,
        ICMS: +icms,
        action_icms: icmsCode?.id,
        action_griss_advalorem: grissAdCode?.id,
        fare_company: Number(freightMargin)?.toFixed(2),
        taker_value: +takerValue,
        load_value_type: loadType?.value || null,
        gris: +griss,
        ad_valorem: +adValorem,
        negotiation_type: negotiationType?.value || null,
        freight_exchange: +exchange,
        currency: currencyExchange?.value,
        total_taker_value: +totalTakerMemo?.toFixed(2),
        total_shipment_transfer: +totalShipperTransfer?.toFixed(2),
        total_inputs: +totalInputs?.toFixed(2),
        total_shipment_discounts: +totalDiscounts?.toFixed(2),
        pcp_observation: pcpObs,
        extra_fields: clientExtraFields,
        gris_percentage: +grissPercentage || null,
        ad_valorem_percentage: +adValoremPercentage || null,
        icms_percentage: +icmsPercentage || null,
      };

      const actualMargin =
        ((Number(editedData?.fare_company || 0) -
          Number(load?.attended?.balance || 0)) /
          Number(editedData?.fare_company || 0)) *
        100;

      if (!submitOptions?.confirmMinimiumMargin && load?.attended?.id) {
        const isMarginERPAbove = await isMarginAbove({
          actualMargin,
          isFreightValues: true,
        });

        if (isMarginERPAbove) {
          lowerMarginModal.open();
          return;
        }
      }

      await api.put(`v3/load/values/${load.id}`, editedData);
      let processedInputsExtraFields = [];
      if (inputsExtraFields) {
        processedInputsExtraFields = [...inputsExtraFields]?.map(item => {
          return { ...item, label: item.label.trim(), load_id: load?.id };
        });
      }

      const processedInsurance = {
        label: 'Seguro Coopercarga',
        manual: true,
        value: Number(cooperInsurance),
        showToDriver: false,
        load_id: load?.id,
      };
      if (cooperInsuranceId) {
        processedInsurance.id = cooperInsuranceId;
      }
      processedInputsExtraFields.push(processedInsurance);

      let processedShipperTransferExtraFields = [];
      if (shipperTransferExtraFields) {
        processedShipperTransferExtraFields = [
          ...shipperTransferExtraFields,
        ]?.map(item => {
          return { ...item, label: item.label.trim(), load_id: load?.id };
        });
      }
      let processedShipperDiscounts = [];
      if (shipperDiscountsExtraFields) {
        processedShipperDiscounts = [...shipperDiscountsExtraFields]?.map(
          item => {
            return { ...item, label: item.label.trim(), load_id: load?.id };
          }
        );
      }

      try {
        await cooperplaceApi.put(`/carg-inputs`, {
          inputsExtraFields: processedInputsExtraFields,
        });
        await cooperplaceApi.put(`/carg-shipment-discounts`, {
          discountsExtraFields: processedShipperDiscounts,
        });
        await cooperplaceApi.put('carg-shipment-transfers', {
          transferExtraFields: processedShipperTransferExtraFields,
        });
      } catch (error) {}
      await getLoad();

      setEditing(false);
    } catch (error) {
      snackbar.show(
        <Text>
          {error?.response?.data?.message ||
            'Algo deu errado ao atualizar a carga, tente novamente.'}
        </Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  }
  const isBRLCurrency =
    currencyExchange?.value === 'BRL'
      ? true
      : currencyExchange?.value === 'USD'
      ? false
      : '';
  const dollarToUse = showDailyRate ? exchangeDay : exchange;

  function handleConfirmLowerMargin(value) {
    handleSubmit(value);
  }

  return (
    <>
      <Col xs={12}>
        <Card
          header={
            <Text color="#464E5F" type="header">
              Composição de valores de frete
            </Text>
          }
          loading={isFetching || loadingRate || loadingFreightOptions}
          HeaderRightComponent={
            hasPermissionToEdit &&
            (!isTravelBlocked || isTravelReproved) && (
              <div>
                {editing && (
                  <Button
                    variant="secondary"
                    loading={loading}
                    className="mr-2"
                    onClick={() => {
                      setClientCardToggle(false);
                      setInputsCardToggle(false);
                      setDiscountsCardToggle(false);
                      setTransferCardToggle(false);
                      setEditing(false);
                    }}
                    id="button-cancelar-card-frete"
                  >
                    <Text color="dark" type="regular">
                      Cancelar
                    </Text>
                  </Button>
                )}
                <Button
                  disabled={onGoingTravelStatusId(load?.attended?.status_id)}
                  onClick={
                    editing ? () => handleSubmit() : () => setEditing(true)
                  }
                  variant={editing ? 'primary' : 'secondary'}
                  loading={loading}
                  id={`button-${editing ? 'salvar' : 'editar'}-card-frete`}
                >
                  <Text color={editing ? 'white' : 'dark'} type="regular">
                    {editing ? 'Salvar' : 'Editar'}
                  </Text>
                </Button>
              </div>
            )
          }
        >
          {editing === false ? (
            <FreightDetails
              load={load}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              showDailyRate={showDailyRate}
              setShowDailyRate={setShowDailyRate}
              clientCardToggle={clientCardToggle}
              setClientCardToggle={setClientCardToggle}
              inputsCardToggle={inputsCardToggle}
              setInputsCardToggle={setInputsCardToggle}
              negotiatedCardToggle={negotiatedCardToggle}
              setNegotiatedCardToggle={setNegotiatedCardToggle}
              discountsCardToggle={discountsCardToggle}
              setDiscountsCardToggle={setDiscountsCardToggle}
              transferCardToggle={transferCardToggle}
              setTransferCardToggle={setTransferCardToggle}
              attendedInfo={attendedInfo}
            />
          ) : (
            <>
              <FreightEditing
                showDailyRate={showDailyRate}
                setShowDailyRate={setShowDailyRate}
                rateResponse={rateResponse}
                isBRLCurrency={isBRLCurrency}
                dollarToUse={dollarToUse}
                exchange={exchange}
                setExchange={setExchange}
                exchangeDay={exchangeDay}
                setExchangeDay={setExchangeDay}
                currencyExchange={currencyExchange}
                setCurrencyExchange={setCurrencyExchange}
                loadValueCalculationType={loadType}
                setLoadValueCalculationType={setLoadType}
                negotiatioValueType={negotiationType}
                setNegotiationType={setNegotiationType}
                clientFreightOptions={clientFreightOptions}
                cargoValue={cargoValue}
                takerValue={takerValue}
                setTakerValue={setTakerValue}
                toll={toll}
                setToll={setToll}
                tollCode={tollCode}
                setTollCode={setTollCode}
                discharge={discharge}
                setDischarge={setDischarge}
                dischargeCode={dischargeCode}
                setDischargeCode={setDischargeCode}
                griss={griss}
                setGriss={setGriss}
                grissPercentage={grissPercentage}
                setGrissPercentage={setGrissPercentage}
                grissAdCode={grissAdCode}
                setGrissAdCode={setGrissAdCode}
                adValorem={adValorem}
                setAdValorem={setAdValorem}
                adValoremPercentage={adValoremPercentage}
                setAdValoremPercentage={setAdValoremPercentage}
                charge={charge}
                setCharge={setCharge}
                chargeCode={chargeCode}
                setChargeCode={setChargeCode}
                ferry={ferry}
                setFerry={setFerry}
                ferryCode={ferryCode}
                setFerryCode={setFerryCode}
                icms={icms}
                setIcms={setIcms}
                icmsPercentage={icmsPercentage}
                setIcmsPercentage={setIcmsPercentage}
                icmsCode={icmsCode}
                setIcmsCode={setIcmsCode}
                clientExtraFields={clientExtraFields}
                setClientExtraFields={setClientExtraFields}
                totalTakerMemo={totalTakerMemo}
                cooperInsurance={cooperInsurance}
                setCooperInsurance={setCooperInsurance}
                cooperInsurancePercentage={cooperInsurancePercentage}
                setCooperInsurancePercentage={setCooperInsurancePercentage}
                inputsExtraFields={inputsExtraFields}
                setInputsExtraFields={setInputsExtraFields}
                setTotalInputs={setTotalInputs}
                totalInputs={totalInputs}
                pcpObs={pcpObs}
                setPcpObs={setPcpObs}
                setTotalShipperTransfer={setTotalShipperTransfer}
                totalShipperTransfer={totalShipperTransfer}
                shipperTransferExtraFields={shipperTransferExtraFields}
                setShipperTransferExtraFields={setShipperTransferExtraFields}
                freightMargin={freightMargin}
                shipperDiscountsExtraFields={shipperDiscountsExtraFields}
                setShipperDiscountsExtraFields={setShipperDiscountsExtraFields}
                setTotalDiscounts={setTotalDiscounts}
                totalDiscounts={totalDiscounts}
                errors={errors}
                issueRate={issueRate}
                clientCardToggle={clientCardToggle}
                setClientCardToggle={setClientCardToggle}
                setInputsCardToggle={setInputsCardToggle}
                inputsCardToggle={inputsCardToggle}
                discountsCardToggle={discountsCardToggle}
                setDiscountsCardToggle={setDiscountsCardToggle}
                transferCardToggle={transferCardToggle}
                setTransferCardToggle={setTransferCardToggle}
                setNegotiatedCardToggle={setNegotiatedCardToggle}
                negotiatedCardToggle={negotiatedCardToggle}
                attendedInfo={attendedInfo}
                totalItems={totalItems}
                load={load}
              />
            </>
          )}
        </Card>
      </Col>
      <MinimumMarginModal
        isOpen={lowerMarginModal.isOpen}
        onClose={lowerMarginModal.close}
        confirm={handleConfirmLowerMargin}
      />
    </>
  );
}

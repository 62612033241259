import React, { useRef, useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import api from 'services/api';
import { fetchCountries } from 'services/api/Locations';
import { useValidation } from 'hooks';

import ModalRaw from 'v3/components/Modal';
import Button from 'v3/components/Button';
import Text from 'v3/components/Text';
import Input from 'v3/components/Input';
import InputAddress from 'v3/components/InputAddress';
import Select from 'v3/components/Select';
import { useSnackbar } from 'v3/components/Snackbar';

import { addressSchema } from '../../validator';
import { useShippingCompany } from '../../context';

function Modal({ isOpen, onClose }) {
  const inputRef = useRef();
  const shippingCompany = useShippingCompany();
  const params = useParams();
  const snackbar = useSnackbar();
  const [errors, { validate }] = useValidation(addressSchema);

  const [data, setData] = useState({});
  const [countries, setCountries] = useState();

  const [loading, setLoading] = useState(false);
  const [streetDisabled, setStreetDisabled] = useState(false);

  useEffect(() => {
    shippingCompany.setErrors(errors);
  }, [errors]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();;
    }

    if (shippingCompany?.data?.addresses?.[0]) {
      setData(shippingCompany?.data?.addresses?.[0]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (shippingCompany.data?.country && isOpen) {
      setData({
        ...data,
        country: shippingCompany.data?.country,
      });
    }

    if (!shippingCompany.data?.addresses?.[0]?.country && isOpen) {
      setData({
        ...data,
        country: {
          name: 'Brasil',
          id: 1,
          abbreviation: 'br',
        },
      });
    }
  }, [isOpen, shippingCompany.data?.country?.name]);

  useEffect(() => {
    fetchCountries().then(setCountries);
  }, []);

  async function handleSubmit() {
    const [isValid, validData] = await validate(data);

    if (!isValid) {
      snackbar.show(<Text>Verifique os campos obrigatórios </Text>, {
        type: 'error',
      });
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      shippingCompany.setData({
        addresses: [validData],
        country: validData?.country,
      });

      if (params.id) {
        await api.put(`v3/shipping-company/address/${params.id}`, validData);
      }

      onClose();
      snackbar.show(<Text>Dados atualizados</Text>, {
        type: 'success',
      });
    } catch (ex) {
      snackbar.show(<Text>Algo deu errado, tente novamente mais tarde</Text>, {
        type: 'error',
      });
    } finally {
      setLoading(false);
      setStreetDisabled(false);
      shippingCompany.setErrors(null);
    }
  }

  return (
    <>
      <ModalRaw
        show={isOpen}
        onHide={onClose}
        heading={
          <Text type="header" color="dark">
            Endereço
          </Text>
        }
        body={
          <Row>
            <form>
              <Row className="px-2 form">
                <Col xs={12}>
                  <Select
                    label="País"
                    options={countries}
                    value={data?.country}
                    onChange={value => setData({ ...data, country: value })}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    disableClear
                  />
                </Col>
                <Col xs={12}>
                  <InputAddress
                    ref={inputRef}
                    label="Procure seu endereço *"
                    placeholder="Digite seu endereço"
                    country={data?.country?.abbreviation}
                    types={[]}
                    onSelectedAddress={value => {
                      if (value.address) {
                        setStreetDisabled(true);
                      }

                      setData(prev => ({
                        ...data,
                        address: value.address || data?.address,
                        uf_name: value.province || data?.uf_name,
                        city_name: value.city || data?.city_name,
                        neighborhood: value.neighborhood || data?.neighborhood,
                        number: value.number || data?.number,
                        zip_code: value.zip_code || data?.zip,
                        complement: value.name || data?.complement,
                      }));
                    }}
                    value={data?.formated_address}
                  />
                </Col>
                <>
                  <Col xs={12}>
                    <Input
                      label="Rua *"
                      placeholder="Digite sua rua"
                      onChange={event => {
                        setData({ ...data, address: event.target.value });
                      }}
                      value={data?.address}
                      disabled={streetDisabled}
                      error={
                        errors.address ||
                        shippingCompany.errors?.['address.address']
                      }
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <Input
                      label="Número"
                      value={data?.number}
                      onChange={event =>
                        setData({ ...data, number: event.target.value })
                      }
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <Input
                      label="Complemento"
                      value={data?.complement}
                      onChange={event =>
                        setData({ ...data, complement: event.target.value })
                      }
                    />
                  </Col>
                  <Col md={6} xs={12}>
                    <Input
                      label="Bairro *"
                      value={data?.neighborhood}
                      onChange={event =>
                        setData({ ...data, neighborhood: event.target.value })
                      }
                      error={
                        errors.neighborhood ||
                        shippingCompany.errors?.['address.neighborhood']
                      }
                    />
                  </Col>

                  <Col md={6} xs={12}>
                    <Input
                      label="CEP"
                      value={data?.zip_code}
                      onChange={event =>
                        setData({ ...data, zip_code: event.target.value })
                      }
                    />
                  </Col>

                  <Col md={8} xs={12}>
                    <Input
                      label="Cidade *"
                      value={data?.city_name}
                      onChange={event =>
                        setData({ ...data, city_name: event.target.value })
                      }
                      error={
                        errors.city_name ||
                        shippingCompany.errors?.['address.city_name']
                      }
                    />
                  </Col>
                  <Col md={4} xs={12}>
                    <Input
                      label="UF *"
                      value={data?.uf_name}
                      onChange={event =>
                        setData({ ...data, uf_name: event.target.value })
                      }
                      error={
                        errors.uf_name ||
                        shippingCompany.errors?.['address.uf_name']
                      }
                    />
                  </Col>
                </>
              </Row>
            </form>
          </Row>
        }
        footer={
          <Row>
            <Col md={6} xs={12}>
              <Button
                className="py-2"
                onClick={() => handleSubmit()}
                loading={loading}
              >
                <Text weight="500" type="regular">
                  Salvar
                </Text>
              </Button>
            </Col>
          </Row>
        }
      />
    </>
  );
}

export default Modal;

import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import api from 'services/api';
import * as Styled from './styles';
import Input from '../Input';
import { cooperplaceApi } from 'services/api';
import Loader from 'v3/components/Loader';
import Text from 'v3/components/Text';

function InputAddress(
  {
    label,
    error,
    country = 'br',
    name,
    onSelectedAddress,
    value,
    onChange,
    typesRestriction = [],
    countryRestriction = null,
    removeCountryRestriction = false,
    id,
    ...props
  },
  ref
) {

  const [inputValue, setInputValue] = useState(value);
  const [countryValue, setCountryValue] = useState(country);
  const [suggestions, setSuggestions] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchGoogleAddressDetails = async (data) => {
    try {
      const addressDetails = await cooperplaceApi.get('google/address-autocomplete-details', {
        params: { placeId: data.place_id }
      });
      return addressDetails.data;
    } catch (error) {
      return null;
    }
  };

  const fetchGoogleAddress = async (searchValue, country) => {
    try {
      const fetchSuggestions = await cooperplaceApi.get('google/address-autocomplete',    {
        params: {
          types: typesRestriction,
          input: searchValue,
          country: removeCountryRestriction ? null : country
        }
      });
      setSuggestions(fetchSuggestions.data);
    } catch (error) {
      setSuggestions([]);
    } finally {
      setLoading(false);
    };
  }
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    setSuggestions(null);
    setCountryValue(country);
  }, [country]);

  const debouncedUpdate = useCallback(
    debounce((val, country) => {
      fetchSuggestions(val, country);
    }, 2000),
    [countryValue]
  );

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setSuggestions(null);
    setLoading(true);
    debouncedUpdate(newValue, countryValue);
  };

  const fetchSuggestions = async (searchValue, country) => {
    if (searchValue.trim() === '') {
      setSuggestions(null);
      setLoading(false);
      return;
    }
    await fetchGoogleAddress(searchValue, country);
  };

  const handleSelectSuggestion = (suggestion) => {
    setInputValue(suggestion.description);
    setSuggestions(null);
    handleSelected(suggestion);
  };

  async function handleSelected(data) {
    const addressDetails = await fetchGoogleAddressDetails(data);
    const locale = {};

    async function fetchProvinces(search, uf, country) {
      try {
        const response = await api.get('cities', { params: { search, uf, country } });
        return response.data;
      } catch (ex) {
        return null;
      }
    }

    if (addressDetails.address_components) {
      addressDetails.address_components.forEach(address => {
        const [addressType] = address.types;
        if (addressType === 'administrative_area_level_1') {
          locale.province = address.short_name;
          locale.province_name = address.long_name;
        }

        if (addressType === 'locality') {
          locale.locality = address.long_name;
        }

        if (addressType === 'administrative_area_level_2') {
          locale.administrative_area_level_2 = address.long_name;
        }

        if (addressType === 'postal_code') {
          locale.zip_code = address.long_name;
        }

        if (addressType === 'sublocality_level_1') {
          locale.neighborhood = address.long_name;
        }

        if (addressType === 'street_number') {
          locale.number = address.long_name;
        }

        if (addressType === 'country') {
          locale.country_abbreviation = address.short_name.toLowerCase();
        }

        if (addressType === 'route') {
          locale.address = address.long_name;
        }
      });

      locale.lat = addressDetails.geometry.location.lat;
      locale.lng = addressDetails.geometry.location.lng;
      locale.formatted_address = addressDetails.formatted_address;
      locale.name = addressDetails.name;
      locale.isEstablishment = addressDetails.types.includes('establishment');
      locale.address = locale.address || inputValue;

      if (locale.country_abbreviation !== 'br') {
        locale.city = locale.locality;
        locale.province = locale.province_name;
      } else {
        locale.city = locale.administrative_area_level_2;
      }

      if (addressDetails.name && locale.isEstablishment) {
        locale.formatted_address = `${addressDetails.name} - ${locale.formatted_address}`;
      }
    }

    if (locale.city && locale.province) {
      const city = await fetchProvinces(locale.city, locale.province, locale.country_abbreviation);

      if (city?.length) {
        locale.city_id = city[0]?.name === locale.city ? city[0].id : null;
      } else {
        locale.city_id = locale.city === city?.name ? city.id : null;
      }
    }

    onSelectedAddress(locale);
  }

  return (
    <Styled.Container>
      {label && (
        <Styled.Label color="#464e5f" type="label" weight="500">
          {label}
        </Styled.Label>
      )}
      <Input
        ref={ref}
        type="text"
        name={name}
        id={id}
        value={inputValue}
        onChange={handleChange}
        hasError={!!error}
        {...props}
      />
      {loading ? (
        <Styled.SuggestionsListLoading>
          <Text weight={500} color={'#888'} >Carregando </Text>
          <Loader loading={loading} />
        </Styled.SuggestionsListLoading>
      ) : (
        <>
          {suggestions?.length > 0 ? (
            <Styled.SuggestionsList>
              {suggestions.map((suggestion, index) => (
                <>
                  <Styled.SuggestionItem
                    key={index}
                    onClick={() => handleSelectSuggestion(suggestion)}
                  >
                    {suggestion.description}
                  </Styled.SuggestionItem>
                </>
              ))}
            </Styled.SuggestionsList>
          ) : suggestions?.length === 0 ? (
            <Styled.SuggestionsListLoading>
              <Text weight={500} color={'#888'} >Endereço não encontrado</Text>
              <Loader loading={loading} />
            </Styled.SuggestionsListLoading>
          ) : null}

          {error && (
            <Styled.ErrorText color="danger" type="little">
              {error}
            </Styled.ErrorText>
          )}
        </>
      )}

    </Styled.Container>
  );
}

export default React.forwardRef(InputAddress)

import React, { useEffect, useState } from 'react';
import { useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import Text from '../Text';

import { InfoContainer, ColStyled, StatusColStyled } from './style';

const colorUnselected = '#808080';

export function TravelStatusCard({
  description,
  onTime,
  delayed,
  noRaster,
  customIndicator,
  show,
  showMdfe,
  fetchData,
  indicator,
  ocurrences,
  margins
}) {
  const query = useQuery();
  const history = useHistory();
  const [activeOnTime, setActiveOnTime] = useState(true);
  const [activeDelayed, setActiveDelayed] = useState(true);
  const [activeNoRaster, setActiveNoRaster] = useState(true);
  const [activePendency, setActivePendency] = useState(true);
  const [activeFinished, setActiveFinished] = useState(true);
  const [activePending, setActivePending] = useState(true);
  const [activeReproved, setActiveReproved] = useState(true);
  const [startFilter, setStartFilter] = useState(null);

  function activeLabel(indicator) {

    if (query.has('indicator')) {
      if (
        query.get('indicator') !== indicator ||
        query.get('indicatorType') !== 'onTime'
      ) {
        setActiveOnTime(false);
      } else {
        setActiveOnTime(true);
      }
      if (
        query.get('indicator') !== indicator ||
        query.get('indicatorType') !== 'delayed'
      ) {
        setActiveDelayed(false);
      } else {
        setActiveDelayed(true);
      }

      if (
        query.get('indicator') !== indicator ||
        query.get('indicatorType') !== 'noRaster'
      ) {
        setActiveNoRaster(false);
      } else {
        setActiveNoRaster(true);
      }

      if (
        query.get('indicator') !== indicator ||
        query.get('indicatorType') !== 'occurrencyPendency'
      ) {
        setActivePendency(false);
      } else {
        setActivePendency(true);
      }

      if (
        query.get('indicator') !== indicator ||
        query.get('indicatorType') !== 'occurrencyFinished'
      ) {
        setActiveFinished(false);
      } else {
        setActiveFinished(true);
      }

      if (
        query.get('indicator') !== indicator ||
        query.get('indicatorType') !== 'marginPending'
      ) {
        setActivePending(false);
      } else {
        setActivePending(true);
      }

      if (
        query.get('indicator') !== indicator ||
        query.get('indicatorType') !== 'marginReproved'
      ) {
        setActiveReproved(false);
      } else {
        setActiveReproved(true);
      }

    } else {
      setActiveOnTime(true);
      setActiveDelayed(true);
      setActiveNoRaster(true);
      setActiveFinished(true);
      setActivePendency(true);
      setActiveReproved(true);
      setActivePending(true);
    }
  }

  function filterIndicator(indicator, type) {
    if (
      query.get('indicator') === indicator &&
      query.get('indicatorType') === type
    ) {
      query.delete('indicator');
      query.delete('indicatorType');
    } else {
      query.set('indicator', indicator);
      query.set('indicatorType', type);
    }
    history.replace({ search: query.toString() });
    activeLabel(indicator);
    setStartFilter(startFilter === null ? true : !startFilter);
  }

  useEffect(() => {
    activeLabel(indicator);
  }, [query]);

  useEffect(() => {
    if (startFilter !== null) {
      fetchData();
    }
  }, [startFilter]);

  return (
    <div md={12}>
      <InfoContainer>
        <ColStyled>
          <Text id="description" fontSize={14}>
            {description}
          </Text>
        </ColStyled>
        <StatusColStyled>
          {/* ON TIME INDICATORS  */}
          {customIndicator ? (
            <>
              <Text id="customText">{customIndicator}</Text>
            </>
          ) : show & !margins ? (
            <button
              type="button"
              style={{ background: '#fff', width: '51px' }}
              onClick={() => filterIndicator(indicator, ocurrences === true ? "occurrencyFinished" : 'onTime')}
            >
              <Text id="onTime" color={activeOnTime || activeFinished ? null : colorUnselected}>
                {onTime}
              </Text>
            </button>
          ) : !showMdfe ? (
            <button
              type="button"
              style={{ background: '#fff', width: '51px' }}
            >
              <Text id="onTimeText">{onTime}</Text>
            </button>
          ) : (
            <button
              type="button"
              style={{ background: '#fff', width: '51px' }}
              onClick={() => filterIndicator(indicator, 'onTime')}
            >
              <Text id="onTimeMdfesText" color={activeOnTime ? null : colorUnselected}>{onTime}</Text>
            </button>
          )}
          {
            (show & !margins) ? <Text weight="bold"> - </Text> : showMdfe & !margins ? (
              <Text weight="bold"> - </Text>
            ) : null
          }
          {/* DELAYED INDICATORS  */}
          {
            show & !ocurrences ? (
              <button
                type="button"
                style={{ background: '#fff', width: '51px' }}
                onClick={() => filterIndicator(indicator, margins === true ? "marginReproved" : "delayed")}
              >
                <Text id="delayed" color={activeDelayed || activeReproved ? null : colorUnselected}>
                  {delayed}
                </Text>
              </button>
            ) : showMdfe ? (
              <button
                type="button"
                style={{ background: '#fff', width: '51px' }}
                onClick={() => filterIndicator(indicator, 'delayed')}
              >
                <Text id="delayedMdfesText" color={activeDelayed ? null : colorUnselected}>{delayed}</Text>
              </button>
            ) : null
          }
          {show & !ocurrences ? <Text weight="bold"> - </Text> : null}
          {/* NO RASTER INDICATORS  */}
          {
            show ? (
              <button
                type="button"
                style={{ background: '#fff', width: '51px' }}
                onClick={() => filterIndicator(indicator, ocurrences === true ? 'occurrencyPendency' : margins === true ? "marginPending" : 'noRaster')}
              >
                <Text id="noRaster" color={activeNoRaster || activePendency || activePending ? null : colorUnselected}>
                  {noRaster}
                </Text>
              </button>
            ) : null
          }
        </StatusColStyled >
      </InfoContainer >
    </div >
  );
}

import styled, { css } from 'styled-components';
import Text from 'v3/components/Text';

export const Label = styled(Text)`
  margin-bottom: 10px;
`;

export const ErrorText = styled(Text)`
  margin-top: 5px;
  ${props =>
    props.color === 'danger' &&
    css`
      color: #f64e60 !important;
    `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SuggestionsListLoading = styled.ul`
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 10px;
  top: 100%;
  padding: 5px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 50px;

`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: 100%;
  padding: 5px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 10;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  list-style-type: none;
`;

export const SuggestionItem = styled.li`
  font-size: 14px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  overflow: hidden;
  width: 100%;
  font-weight: 400;
  font-family: 'Poppins';


  border-bottom: 1px solid #e0e0e0;

  &:hover {
    background-color: #f0f0f0;
    width: 100%;
    height: 100%; 
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

`;

import React, { createContext, useCallback, useEffect, useState } from 'react';
import { cooperplaceApi } from 'services/api';
import { useModal } from 'hooks';
import { ShippingCompanyServiceLevelMapper } from 'v3/utils/mapper';

const defaultStates = {
  isTravelBlocked: false,
  setIsTravelBlocked: () => {},
  isTravelReproved: false,
  setIsTravelReproved: () => {},
  loadStatus: {},
  setLoadStatus: () => {},
  travelStatus: {},
  setTravelStatus: () => {},
  lastMarginLiberation: {},
  setLastMarginLiberation: () => {},
  isFetchingBenner: false,
  setIsFetchingBenner: () => {},
  setItensToFetchMargin: () => {},
  margins: {
    advance_money_percentage_external: 0,
    benner_margin: 0,
  },
  setMargins: () => {},
  isFetchingMargin: false,
  setIsFetchingMargin: () => {},
  verifyVehiclesModal: () => {},
  defaultValuesAvailable: [],
  setDefaultValuesAvailable: () => {},
  defaultValues: [],
  setDefaultValues: () => {},
  itemsToSetDefaultValues: {},
  setItemsToSetDefaultValues: () => {},
  shippingServiceLevelMapped: async id => {},
  defaultMargin: null,
  defaultAdvance: null,
  fetchBusinessMarginsValues: async id => {},
  isMarginAbove: async params => {},
};

export const BlockedTravelContext = createContext(defaultStates);

export function BlockedTravelProvider({ children }) {
  const [isTravelBlocked, setIsTravelBlocked] = useState(false);
  const [isTravelReproved, setIsTravelReproved] = useState(false);
  const [loadStatus, setLoadStatus] = useState({});
  const [travelStatus, setTravelStatus] = useState({});
  const [lastMarginLiberation, setLastMarginLiberation] = useState(null);
  const [itensToFetchMargin, setItensToFetchMargin] = useState({});
  const [margins, setMargins] = useState({
    advance_money_percentage_external: 0,
    benner_margin: 0,
  });
  const [itemsToSetDefaultValues, setItemsToSetDefaultValues] = useState({});
  const [defaultValuesAvailable, setDefaultValuesAvailable] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [defaultMargin, setDefaultMargin] = useState(0);
  const [defaultAdvance, setDefaultAdvance] = useState(0);
  const [isFetchingBenner, setIsFetchingBenner] = useState(false);
  const [isFetchingMargin, setIsFetchingMargin] = useState(false);
  const verifyVehiclesModal = useModal();

  const shippingServiceLevelMapped = useCallback(
    async shippingCompanyServiceLevel => {
      let defaultValues = [];
      const mappedShippingCompanyLevel =
        ShippingCompanyServiceLevelMapper[shippingCompanyServiceLevel];
      if (
        !shippingCompanyServiceLevel ||
        shippingCompanyServiceLevel === undefined ||
        mappedShippingCompanyLevel === undefined
      ) {
        defaultValues = defaultValuesAvailable.filter(
          item => item?.serviceLevel?.slug?.toLowerCase() === 'terceiro'
        );
      } else
        defaultValues = defaultValuesAvailable.filter(
          item =>
            item?.serviceLevel?.slug?.toLowerCase() ===
            mappedShippingCompanyLevel?.toLowerCase()
        );
      const defaultMargin = defaultValues?.find(
        item => item?.type === 'MARGEM_MINIMA'
      )?.value;
      const defaultAdvance = defaultValues?.find(
        item => item?.type === 'MAXIMO_ADIANTAMENTO'
      )?.value;
      setDefaultMargin(defaultMargin);
      setDefaultAdvance(defaultAdvance);
      setDefaultValues(defaultValues);
      return [defaultMargin, defaultAdvance];
    },
    [defaultValuesAvailable]
  );

  useEffect(() => {
    setIsTravelBlocked(loadStatus?.code === 'BLOCKED');
  }, [loadStatus?.code]);

  useEffect(() => {
    setIsTravelBlocked(travelStatus?.code === 'BLOCKED');
  }, [travelStatus?.code]);

  useEffect(() => {
    if (isTravelBlocked && lastMarginLiberation) {
      setIsTravelReproved(
        !lastMarginLiberation?.approval && lastMarginLiberation.userApprovalId
      );
    }
  }, [lastMarginLiberation, isTravelBlocked]);

  useEffect(() => {
    if (itemsToSetDefaultValues?.costCenterBusinessId) {
      fetchBusinessMarginsValues(itemsToSetDefaultValues?.costCenterBusinessId);
    }
  }, [itemsToSetDefaultValues?.costCenterBusinessId]);

  async function fetchBusinessMarginsValues(costCenterBusinessId) {
    try {
      const { data } = await cooperplaceApi.get('business-margins', {
        params: {
          businessId: costCenterBusinessId,
        },
      });
      setDefaultValuesAvailable(data);
      return;
    } catch (error) {
      return null;
    }
  }

  async function fetchMargin(marginParams) {
    try {
      const response = await cooperplaceApi.get('/margin', {
        params: marginParams,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async function fetchMarginErp(marginParams) {
    try {
      const response = await cooperplaceApi.get('/margin-erp', {
        params: marginParams,
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async function isMarginAbove(params) {
    const newItensToFetchMargin = {
      ...itensToFetchMargin,
      ...params,
    };
    const destination =
      newItensToFetchMargin.destinations[
        newItensToFetchMargin.destinations?.length - 1
      ];

    const newBennerMargin = await fetchMarginErp({
      loadId: newItensToFetchMargin.loadId,
      vehicleId: newItensToFetchMargin?.mainVehicle?.id,
      originCityId: newItensToFetchMargin.origin?.cityId,
      originCity: newItensToFetchMargin.origin?.city,
      originProvince: newItensToFetchMargin.origin?.province,
      originCountry: newItensToFetchMargin.origin?.countrySlug,
      destinationCityId: destination?.cityId,
      destinationCity: destination?.city,
      destinationProvince: destination?.province,
      destinationCountry: destination?.countrySlug,
      costCenterId: newItensToFetchMargin?.costCenterId,
      clientId: newItensToFetchMargin?.clientId,
    });

    const [marginValue] = await shippingServiceLevelMapped(
      itensToFetchMargin?.shippingCompany?.description_service_level
    );

    const newMargin = newBennerMargin?.value || marginValue;

    if (
      newItensToFetchMargin?.actualMargin < newMargin &&
      (margins?.benner_margin !== newMargin || params.isFreightValues)
    ) {
      return true;
    }

    return false;
  }

  async function setMargin() {
    if (itensToFetchMargin?.mainVehicle?.fleetType?.name === 'Próprio') {
      setMargins({
        advance_money_percentage_external: 0,
        benner_margin: 0,
      });
      return;
    }

    const cooperativeVehicleTypeId = itensToFetchMargin?.mainVehicle?.cooperative_vehicle_type_id || itensToFetchMargin?.mainVehicle?.cooperativeVehicleTypeId;
    if (
      !itensToFetchMargin?.loadId ||
      !itensToFetchMargin?.shippingCompany ||
      !cooperativeVehicleTypeId ||
      !itensToFetchMargin?.origin ||
      !itensToFetchMargin?.destinations ||
      itensToFetchMargin?.destinations.length === 0
    ) {
      const descriptionServiceLevel = itensToFetchMargin?.shippingCompany?.description_service_level || itensToFetchMargin?.shippingCompany?.descriptionServiceLevel;

      const [marginValue, advanceValue] = await shippingServiceLevelMapped(
        descriptionServiceLevel
      );

      setMargins({
        advance_money_percentage_external: advanceValue,
        benner_margin: marginValue,
      });
      return;
    }

    setIsFetchingMargin(true);
    try {
      const destination =
        itensToFetchMargin.destinations[
          itensToFetchMargin.destinations.length - 1
        ];
      const personId = itensToFetchMargin.shippingCompany?.personId || itensToFetchMargin.shippingCompany?.person_id;
      const [margin, marginErp] = await Promise.all([
        fetchMargin({
          loadId: itensToFetchMargin.loadId,
          shippingCompanyId: personId,
          vehicleId: itensToFetchMargin?.mainVehicle?.id,
        }),

        fetchMarginErp({
          loadId: itensToFetchMargin.loadId,
          vehicleId: itensToFetchMargin?.mainVehicle?.id,
          originCityId: itensToFetchMargin.origin?.cityId,
          originCity: itensToFetchMargin.origin?.city,
          originProvince: itensToFetchMargin.origin?.province,
          originCountry: itensToFetchMargin.origin?.countrySlug,
          destinationCityId: destination?.cityId,
          destinationCity: destination?.city,
          destinationProvince: destination?.province,
          destinationCountry: destination?.countrySlug,
        }),
      ]);

      let defaultMarginValue = 0;
      let defaultAdvanceValue = 0;

      if (!margin?.percentage || !marginErp?.value) {
        const response = await shippingServiceLevelMapped(
          itensToFetchMargin?.shippingCompany?.descriptionServiceLevel
        );
        defaultMarginValue = response[0];
        defaultAdvanceValue = response[1];
      }

      setMargins(old => ({
        ...old,
        advance_money_percentage_external:
          margin?.percentage || defaultAdvanceValue,
        benner_margin: marginErp?.value || defaultMarginValue,
      }));
    } catch (err) {
      //
    } finally {
      setIsFetchingMargin(false);
    }
  }

  useEffect(() => {
    setMargin();
  }, [
    itensToFetchMargin?.mainVehicle?.id,
    itensToFetchMargin?.mainVehicle?.cooperativeVehicleTypeId,
    itensToFetchMargin?.mainVehicle?.fleetType?.name,
    itensToFetchMargin?.loadId,
    itensToFetchMargin.shippingCompany?.person_id,
    itensToFetchMargin.origin?.cityId,
    itensToFetchMargin.origin?.city,
    itensToFetchMargin.origin?.province,
    itensToFetchMargin.origin?.countrySlug,
    itensToFetchMargin.destinations,
  ]);

  return (
    <BlockedTravelContext.Provider
      value={{
        isTravelBlocked,
        setIsTravelBlocked,
        isTravelReproved,
        setIsTravelReproved,
        loadStatus,
        setLoadStatus,
        travelStatus,
        setTravelStatus,
        lastMarginLiberation,
        setLastMarginLiberation,
        isFetchingBenner,
        setIsFetchingBenner,
        setItensToFetchMargin,
        margins,
        setMargins,
        isFetchingMargin,
        verifyVehiclesModal,
        setItemsToSetDefaultValues,
        fetchBusinessMarginsValues,
        defaultValues,
        shippingServiceLevelMapped,
        defaultMargin,
        defaultAdvance,
        defaultValuesAvailable,
        isMarginAbove,
      }}
    >
      {children}
    </BlockedTravelContext.Provider>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Fallback, Text } from 'v3/components';
import ApplicationLayout from 'v3/layouts/Application';
import { useQuery, useLocalStorage, useModal } from 'hooks';
import api, { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import {
  fetchBodies,
  fetchImplements,
  fetchTravelStatus,
  fetchVehiclesTypes,
} from 'utils/fetches';
import { DefaultLoadingBodyComponent } from 'v3/components/Card';
import { filtersMapper, FleetManagementProvider } from './shared';
import FleetManagementTable from './Table';
import Filters from './Filters';
import DrawerColumns from './DrawerColumns';
import NewRegisterModal from './Modals/CreateNewRegister';
import SendEmailsModal from './Modals/SendEmails';
import ErrorsModal from './Modals/Errors';
import DeleteRegisterModal from './Modals/DeleteRegister';
import CreateNewOutsideTravelModal from './Modals/CreateNewOutsideTravel';
import OutsideTravelModal from './Modals/OutsideTravelModal';
import SuggestionsModal from './Modals/SuggestionsModal';
import { usePermission } from 'hooks';

function FleetManagement() {

  usePermission('VISUALIZAR_GESTAO_FROTA', { redirect: true });

  const history = useHistory();
  const queryParams = useQuery();

  const [isFilterColumnsOpen, setIsFilterColumnsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filtersOptions, setFilterOptions] = useState({
    statusOperator: [],
    aggregation: [
      { value: 'Agregado', label: 'Agregado' },
      { value: 'Terceiro', label: 'Terceiro' },
    ],
    travelStatus: [],
    vehicleTypes: [],
    implementTypes: [],
    bodyTypes: [],
  });
  const [filters, setFilters] = useLocalStorage('fleet_management_filters', {
    statusOperator: [],
    tags: [],
    operators: [],
    plates: [],
    drivers: [],
    startDate: moment().subtract(1, 'month').format('DD/MM/YYYY'),
    endDate: moment().format('DD/MM/YYYY'),
    startAvailabilityForecast: null,
    endAvailabilityForecast: null,
    aggregation: null,
    branchOffices: [],
    withOccurrences: -1,
    active: -1,
    travelStatus: [],
    vehicleTypes: [],
    implementTypes: [],
    bodyTypes: [],
    fleet: [],
    shipping: []
  });

  const [rowsPerPage, setRowsPerPage] = useState({
    rowsPerPage: 10,
    page: 1,
  })

  const [fetchConfig, setFetchConfig] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [fleetData, setFleetData] = useState([]);

  const [suggestionData, setSuggestionData] = useState({
    data: [],
    meta: {}
  });

  const [unusedSuggestion, setUnusedSuggestion] = useState({
    data: [],
    meta: {},
  });

  const [attendedSuggestion, setAttendedSuggestion] = useState({
    data: [],
    meta: {},
  });

  const [loadSuggestionByVehicles, setLoadSuggestionByVehicle] = useState(false);
  const [loadSuggestionType, setLoadSuggestionType] = useState(null);

  const [travelOutside, setTravelOutside] = useState({
    data: [],
    meta: {}
  });
  const [expiredLoads, setExpiredLoads] = useState({
    data: [],
    meta: {}
  });

  const [editedCells, setEditedCells] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selected, setSelected] = useState(null);
  const snackbar = useSnackbar();
  const newRegisterModal = useModal();
  const sendEmailsModal = useModal();
  const errorsModal = useModal();
  const deleteModal = useModal();
  const newOutsideTravelModal = useModal();
  const outsideTravelModal = useModal();
  const suggestionsModal = useModal();
  const hasMounted = useRef(false);

  const handleCloseFilterColumns = async newColumns => {
    setLoading(true);
    try {
      setIsFilterColumnsOpen(false);
      await cooperplaceApi.post(`/user/definitions`, {
        type: 'fleet_management_columns',
        definition: JSON.stringify(newColumns),
      });
      setColumns(newColumns);
    } catch (error) {
      snackbar.show(
        <Text>Error ao salvar as colunas, tente novamente mais tarde</Text>,
        { type: 'error' }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOpenFilterColumns = () => {
    setIsFilterColumnsOpen(true);
  };

  const handleUpdateUnusedSuggested = async (select) => {
    try {
      const fetchOptions = {
        page: 1,
        perPage: 5,
        total: 0,
        lastPage: 0,
      };
      const page = 1;
      const {
        data: { data: loadUnuedSuggestions, meta },
      } = await cooperplaceApi.get(
        `fleet/management/rejectedLoad/${select.id}`,
        {
          params: { ...fetchOptions, page },
        }
      );
      setUnusedSuggestion({ data: loadUnuedSuggestions, meta });
    } catch (error) {
      setUnusedSuggestion({ data: [] });
    }
  };

  const handleGetColumns = async () => {
    try {
      const { data: userColumns } = await cooperplaceApi.get(
        'fleet/management/userPreferences'
      );
      return userColumns;
    } catch (error) {
      snackbar.show(
        <Text>Error ao buscar as colunas, tente novamente mais tarde</Text>,
        { type: 'error' }
      );
      return [];
    }
  };

  const verifyFilterAndSetArrayParams = (key, values) => {
    if (values.length) {
      queryParams.set(key, values.join(','));
    } else {
      queryParams.delete(key);
    }
  };

  const verifyFilterAndSetValueParams = (key, value) => {
    if (value) {
      queryParams.set(key, value);
    } else {
      queryParams.delete(key);
    }
  };

  const handleMountQueryParams = () => {
    const {
      startDate,
      endDate,
      startAvailabilityForecast,
      endAvailabilityForecast,
    } = filters;

    const statusOperator = filters?.statusOperator?.map(status => status.slug) || [];
    const tags = filters?.tags?.map(tag => tag.id) || [];
    const plates = filters?.plates?.map(
      plate => `${plate.id}-${plate.vehicleType}`
    );
    const branchOffices = filters?.branchOffices?.map(branch => branch.id) || [];
    const travelStatus = filters?.travelStatus?.map(status => status.id) || [];
    const drivers = filters?.drivers?.map(driver => driver.id) || [];
    const operators = filters?.operators?.map(operator => operator.id) || [];
    const vehicleTypes = filters?.vehicleTypes?.map(
      vehicleType => vehicleType.id
    ) || [];
    const implementTypes = filters?.implementTypes?.map(
      implementType => implementType.id
    ) || [];
    const bodyTypes = filters?.bodyTypes?.map(
      bodyType => bodyType.id
    ) || [];
    const fleets = filters?.fleet?.map(fleet => fleet.id) || [];
    const shipping = filters?.shipping?.map(shipping => shipping.id) || [];

    verifyFilterAndSetArrayParams('statusOperator', statusOperator);
    verifyFilterAndSetArrayParams('tags', tags);
    verifyFilterAndSetArrayParams('plates', plates);
    verifyFilterAndSetValueParams('startDate', startDate);
    verifyFilterAndSetValueParams('endDate', endDate);
    verifyFilterAndSetValueParams(
      'startAvailabilityForecast',
      startAvailabilityForecast
    );
    verifyFilterAndSetValueParams(
      'endAvailabilityForecast',
      endAvailabilityForecast
    );
    verifyFilterAndSetValueParams('aggregation', filters.aggregation?.value);
    verifyFilterAndSetArrayParams('branchOffices', branchOffices);
    verifyFilterAndSetValueParams('withOccurrences', filters.withOccurrences);
    verifyFilterAndSetValueParams('active', filters.active);
    verifyFilterAndSetArrayParams('travelStatus', travelStatus);
    verifyFilterAndSetArrayParams('drivers', drivers);
    verifyFilterAndSetArrayParams('operators', operators);
    verifyFilterAndSetArrayParams('vehicleTypes', vehicleTypes);
    verifyFilterAndSetArrayParams('implementTypes', implementTypes);
    verifyFilterAndSetArrayParams('bodyTypes', bodyTypes);
    verifyFilterAndSetArrayParams('fleet', fleets);
    verifyFilterAndSetArrayParams('shipping', shipping)

    history.push({ search: queryParams.toString() });
  };

  const handleGetData = async customFilter => {
    try {

      if(!{ ...filters, ...customFilter }.startDate || !{ ...filters, ...customFilter }.endDate){
        throw new Error('Erro ao aplicar filtros! Selecione uma data geral inicial e uma data geral  final')
      }
      setLoading(true);
      handleMountQueryParams();

      const { data: fleetManagementData } = await cooperplaceApi.get(
        'fleet/management/vehicleManagement',
        {
          params: filtersMapper({ ...filters, ...customFilter }, rowsPerPage),
        }
      );
      setFetchConfig({
        total: fleetManagementData?.meta?.total || 0,
        page: fleetManagementData?.meta?.current_page || 1,
        perPage: fleetManagementData?.meta?.per_page || 10,
      });
      setFleetData(fleetManagementData.data);
    } catch (err) {
      const textError = err?.response?.data?.error?.slice(0, 4) || err.message
      const statusCode = err?.response?.data?.status

      if (textError === 'DATE') {
        snackbar.show(
          <Text>
            {err.response.data.error.replace('DATE', '')}
          </Text>,
          {
            type: 'error',
          }
        );
      } else if (textError === 'Erro ao aplicar filtros! Selecione uma data geral inicial e uma data geral  final') {
        snackbar.show(
          <Text>
            {err.message}
          </Text>,
          {
            type: 'error',
          }
        );
      } else  if (statusCode === 401) {
        snackbar.show(
          <Text>
            Você não tem permissão para acessar a gestão de otimização de frota e cargas!
          </Text>,
          {
            type: 'error',
          }
        );
      } else {
        snackbar.show(
          <Text>
            Erro ao trazer dados de gestão de otimização de frota e cargas! por favor, recarregue a
            página e tente novamente
          </Text>,
          {
            type: 'error',
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getAvailableStatus = async () => {
    try {
      const { data: statuses } = await cooperplaceApi.get(
        'fleet/management/availableStatus'
      );
      return statuses;
    } catch (error) {
      snackbar.show(
        <Text>
          Erro ao buscar opções de status, por favor, recarregue a página
        </Text>,
        { type: 'error' }
      );
      return [];
    }
  };

  const getTravelStatus = async () => {
    try {
      const statuses = await fetchTravelStatus();
      return statuses;
    } catch (error) {
      snackbar.show(
        <Text>
          Erro ao buscar opções de status da viagem, por favor, recarregue a
          página
        </Text>,
        { type: 'error' }
      );
      return [];
    }
  };

  const fetchVehicleTypes = async () => {
    try {
      const vehicleTypes = await fetchVehiclesTypes();
      return vehicleTypes;
    } catch (error) {
      return [];
    }
  };

  const fetchImplementTypes = async () => {
    try {
      const implementTypes = await fetchImplements();
      return implementTypes;
    } catch (error) {
      return [];
    }
  };

  const fetchBodyTypes = async () => {
    try {
      const bodyTypes = await fetchBodies();
      return bodyTypes;
    } catch (error) {
      return [];
    }
  };

  const fetchSelectedTags = async ids => {
    try {
      const {
        data: { tags },
      } = await cooperplaceApi.get('tags/search-by-ids', {
        params: {
          ids,
        },
      });
      return tags;
    } catch (error) {
      return [];
    }
  };

  const fetchSelectedPlates = async plates => {
    try {
      const {
        data: { plates: resultPlates },
      } = await cooperplaceApi.get('generic-plates/by-id-and-type', {
        params: {
          plates,
        },
      });

      return resultPlates;
    } catch (error) {
      return [];
    }
  };

  const fetchSelectedBranchOffices = async ids => {
    try {
      const {
        data: { branchOffices },
      } = await cooperplaceApi.get('branch-offices/search-by-ids', {
        params: {
          ids,
        },
      });

      return branchOffices;
    } catch (error) {
      return [];
    }
  };

  const fetchSelectedDrivers = async ids => {
    try {
      const {
        data: { drivers },
      } = await cooperplaceApi.get('drivers/search-by-ids', {
        params: {
          ids,
        },
      });

      return drivers;
    } catch (error) {
      return [];
    }
  };

  const fetchSelectedOperators = async ids => {
    try {
      const {
        data: { users },
      } = await cooperplaceApi.get('users/search-by-ids', {
        params: {
          ids,
        },
      });

      return users;
    } catch (error) {
      return [];
    }
  };

  const fetchSelectedFleet = async ids => {
    try {
      const { data } = await cooperplaceApi.get(
        'group-vehicles/search-by-ids',
        {
          params: {
            ids,
          },
        }
      );

      return data;
    } catch (error) {
      return [];
    }
  };
  const fetchShipping = async ids => {
    try {
      const { data } = await cooperplaceApi.get(
        'select/shipping-company-by-ids',
        {
          params: {
            ids,
          },
        }
      );

      return data;
    } catch (error) {
      return [];
    }
  };
  const initialFilters = async params => {
    const obj = Object.fromEntries(queryParams.entries());
    const newObj = { ...filters };
    if (!newObj?.startDate) {
      newObj.startDate = moment().subtract(1, 'month').format('DD/MM/YYYY');
    }

    if (!newObj?.endDate) {
      newObj.endDate = moment().format('DD/MM/YYYY');
    }

    if (obj?.tags) {
      const tags = await fetchSelectedTags(obj.tags.split(','));
      newObj.tags = tags;
    }

    if (obj?.plates) {
      const plates = await fetchSelectedPlates(obj.plates.split(','));
      newObj.plates = plates;
    }

    if (obj?.startDate) {
      newObj.startDate = obj.startDate;
    }

    if (obj?.endDate) {
      newObj.endDate = obj.endDate;
    }

    if (obj?.startAvailabilityForecast) {
      newObj.startAvailabilityForecast = obj.startAvailabilityForecast;
    }

    if (obj?.endAvailabilityForecast) {
      newObj.endAvailabilityForecast = obj.endAvailabilityForecast;
    }

    if (obj?.aggregation) {
      newObj.aggregation = { value: obj.aggregation, label: obj.aggregation };
    }

    if (obj?.branchOffices) {
      const branchOffices = await fetchSelectedBranchOffices(
        obj.branchOffices.split(',')
      );
      newObj.branchOffices = branchOffices;
    }

    if (obj?.travelStatus) {
      const travelStatus = obj.travelStatus.split(',');
      let response = [];
      try {
        response = await api.get('travel-status');
      } catch (error) {
        // Handle exception
      }
      const filteredTravelStatus = []
      await response.data.forEach(e => {
        if (travelStatus.includes(e.id.toString())) {
          filteredTravelStatus.push(e)
        }
      })
      newObj.travelStatus = filteredTravelStatus;
    }

    if (obj?.drivers) {
      const drivers = await fetchSelectedDrivers(obj.drivers.split(','));
      newObj.drivers = drivers;
    }

    if (obj?.operators) {
      const operators = await fetchSelectedOperators(obj.operators.split(','));
      newObj.operators = operators;
    }

    if (obj?.implementTypes) {
      const implementTypes = obj.implementTypes.split(',');
      const filteredImplementTypes = params.implementTypes.filter(type =>
        implementTypes.includes(type.id.toString())
      );
      newObj.implementTypes = filteredImplementTypes;
    }

    if (obj?.vehicleTypes) {
      const vehicleTypes = obj.vehicleTypes.split(',');
      const filteredVehicleTypes = params.vehicleTypes.filter(type =>
        vehicleTypes.includes(type.id.toString())
      );
      newObj.vehicleTypes = filteredVehicleTypes;
    }

    if (obj?.active === 1) {
      newObj.active = obj.active === '1';
    }

    if (obj?.withOccurrences === 1) {
      newObj.withOccurrences = obj.withOccurrences === '1';
    }

    if (obj?.fleet) {
      newObj.fleet = await fetchSelectedFleet(obj.fleet.split(','));
    }

    if (obj?.shipping) {
      newObj.shipping = await fetchShipping(obj.fleet.split(','));
    }

    return newObj;
  };

  const initialFetch = async () => {
    setLoading(true);
    const [
      availableStatus,
      travelStatus,
      vehicleTypes,
      implementTypes,
      bodyTypes,
      userColumns,
    ] = await Promise.all([
      getAvailableStatus(),
      getTravelStatus(),
      fetchVehicleTypes(),
      fetchImplementTypes(),
      fetchBodyTypes(),
      handleGetColumns(),
    ]);
    setColumns(userColumns);

    setFilterOptions(old => ({
      ...old,
      travelStatus,
      statusOperator: availableStatus,
      vehicleTypes,
      implementTypes,
      bodyTypes,
    }));

    const initialFilter = await initialFilters({
      availableStatus,
      travelStatus,
      vehicleTypes,
      implementTypes,
    });
    setFilters(initialFilter);
    await handleGetData();
    setLoading(false);
  };

  const validImplementIds = (value) => {
    const implementIds =
      value?.plate_wagons?.length &&
      value.plate_wagons.map(({ id }) => id).filter(Boolean)

    if (implementIds === 0) {
      return []
    } else {
      return implementIds
    }
  }

  const handleSave = async () => {
    if (isEditing) {
      try {
        const mappedData = Object.entries(editedCells).map(([key, value]) => {
          return {
            vehicleManagementId: +key,
            implementIds: validImplementIds(value),
            driverId: value?.driver?.id && value.driver.id,
            operationTagId: value?.operation?.id && value.operation.id,
            shippingCompanyId: value?.shipment?.id && value.shipment.id,
            destinyDriverInterest:
              value?.local_driver_interest && value.local_driver_interest,
            userOperatorId: value?.operator?.id && value.operator.id,
            timeMaintenanceWagon:
              value?.next_maintenance_wagon &&
              moment(value.next_maintenance_wagon, 'DD/MM/YYYY HH:mm:ss').toISOString(),
            timeMaintenanceVehicle:
              value?.next_maintenance_vehicle &&
              moment(value.next_maintenance_vehicle, 'DD/MM/YYYY HH:mm:ss').toISOString(),

            operationalStatusId:
              value?.status_operational?.id && value.status_operational.id,
            obs: value?.observation && value.observation,
            branchOfficeOriginId:
              value?.branch_origin?.id && value.branch_origin.id,
            fleetId: value?.fleet?.id && value.fleet.id,
            configAutomaticAvailability: value?.config_automatic_availability?.value && value.config_automatic_availability?.value,

            dateDisponibility:
              value?.availability_date &&
              moment(value.availability_date, 'DD/MM/YYYY HH:mm:ss').toISOString(),

            cityDisponibilityId:
              value?.local_availability?.id && value.local_availability.id,

            dateEnd:
              value?.date_finish_travel &&
              moment(value.date_finish_travel, 'DD/MM/YYYY HH:mm:ss').toISOString(),

            dateUnload:
              value?.date_unload_travel &&
              moment(value.date_unload_travel, 'DD/MM/YYYY HH:mm:ss').toISOString(),

            dateLoad:
              value?.date_load_travel &&
              moment(value.date_load_travel, 'DD/MM/YYYY HH:mm:ss').toISOString(),
          };
        });
        const { data } = await cooperplaceApi.put(
          'fleet/management/vehicleManagement',
          { data: mappedData }
        );

        if (data?.error?.length) {
          setErrors(data.error);
          errorsModal.open();
        }
        setIsEditing(false);
      } catch (error) {
        snackbar.show(
          <Text>
            Erro ao salvar as alterações, por favor, tente novamente mais tarde
          </Text>,
          { type: 'error' }
        );
      }
      finally {
        await handleGetData({ page: 1 })
        setEditedCells([]);
      }
    } else {
      setIsEditing(true);
    }
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    if (hasMounted.current) {
      handleMountQueryParams();
    } else {
      hasMounted.current = true;
    }
  }, [filters]);

  return (
    <FleetManagementProvider
      value={{
        loading,
        setLoading,
        fetching,
        setFetching,
        columns,
        setColumns,
        isFilterColumnsOpen,
        handleCloseFilterColumns,
        handleOpenFilterColumns,
        handleUpdateUnusedSuggested,
        setFilters,
        filters,
        isEditing,
        setIsEditing,
        filtersOptions,
        handleGetData,
        fleetData,
        editedCells,
        setEditedCells,
        newRegisterModal,
        sendEmailsModal,
        errorsModal,
        handleSave,
        errors,
        setErrors,
        rowsPerPage,
        setRowsPerPage,
        fetchConfig,
        deleteModal,
        setSelected,
        selected,
        outsideTravelModal,
        newOutsideTravelModal,
        suggestionsModal,
        suggestionData,
        setSuggestionData,
        unusedSuggestion,
        setUnusedSuggestion,
        attendedSuggestion,
        setAttendedSuggestion,
        setLoadSuggestionByVehicle,
        loadSuggestionByVehicles,
        loadSuggestionType,
        setLoadSuggestionType,
        setTravelOutside,
        travelOutside,
        setExpiredLoads,
        expiredLoads
      }}
    >
      <ApplicationLayout
        title="Gestão de otimização de frota e cargas"
        contentFluid
        isOld={true}
      >
        <Row>
          <Col style={{ marginTop: 30 }}>
            <Fallback
              fall={columns?.length === 0}
              component={<DefaultLoadingBodyComponent />}
            >
              <DrawerColumns />
              <Filters />
              <FleetManagementTable />
              <NewRegisterModal />
              <SendEmailsModal />
              <ErrorsModal />
              <DeleteRegisterModal />
              <CreateNewOutsideTravelModal />
              <OutsideTravelModal />
              <SuggestionsModal />
            </Fallback>
          </Col>
        </Row>
      </ApplicationLayout>
    </FleetManagementProvider>
  );
}

export default FleetManagement;

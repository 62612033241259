import React, { useEffect, useRef, useState } from 'react';
import { Button, Fallback, Input, Select, Text } from 'v3/components';
import { useSnackbar } from 'v3/components/Snackbar';
import { cooperplaceApi } from 'services/api';
import { DefaultLoadingComponent } from 'v3/components/List';
import { Col, Row } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { StyledDataTable, StyledModal } from './styles';
import { useTravel } from '../..';

function Header() {
  return (
    <Text color="dark" type="header">
      Lista de checklist ativos
    </Text>
  );
}

function Body({
  checkListTypesOptions,
  travelData,
  modalHook,
  fetchTravelChecklists,
}) {
  const [checklists, setChecklists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const [filters, setFilters] = useState({
    checklistType: null,
    question: '',
    perPage: 5,
    page: 1,
    travelId: travelData?.id,
  });
  const snackbar = useSnackbar();
  const { fetchFeed } = useTravel();

  async function fetchChecklists(params) {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.get(
        'checklists/available-checklists',
        {
          params: {
            name: params?.question,
            limit: params?.perPage,
            page: params?.page,
            typeId: params?.checklistType?.id,
            travelId: params?.travelId,
          },
        }
      );
      setChecklists(data?.checklists?.data);
      setPagination(data?.checklists?.meta);
    } catch (error) {
      // catcher
      setChecklists([]);
    } finally {
      setLoading(false);
    }
  }

  const handleSelectChecklist = async checklist => {
    try {
      setLoading(true);
      const { data } = await cooperplaceApi.post('travel-checklist', {
        checklistId: checklist.id,
        travelId: travelData.id,
      });
      snackbar.show(<Text>{data?.data}</Text>, {
        type: 'success',
      });
      modalHook.close();
      await fetchTravelChecklists();
      fetchFeed();
    } catch (error) {
      // catcher
      let errorMessage = '';
      if (
        error?.response?.data?.errors &&
        Array.isArray(error?.response?.data?.errors)
      ) {
        errorMessage = error?.response?.data?.errors.join();
      }

      if (error?.response?.data?.error) {
        errorMessage = error?.response?.data?.error;
      }
      snackbar.show(
        <Text>{errorMessage || 'Erro ao vincular checklist a viagem'}</Text>,
        {
          type: 'error',
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = useRef(
    debounce(async params => {
      fetchChecklists(params);
    }, 300)
  ).current;

  const handleFilter = ({ target: { value: question } }) => {
    setFilters(prevState => {
      const newState = { ...prevState, question };
      debouncedSearch(newState);
      return newState;
    });
  };

  function onPageChanged(value) {
    const params = {
      ...filters,
      page: value,
    };
    setFilters(params);
    fetchChecklists(params);
  }

  function onPerPageChanged(value) {
    const params = {
      ...filters,
      page: 1,
      perPage: value,
    };
    setFilters(params);
    fetchChecklists(params);
  }

  useEffect(() => {
    fetchChecklists(filters);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: 8,
          alignItems: 'flex-end',
          marginBottom: 24,
        }}
      >
        <Row>
          <Col xs={12} md={6}>
            <Input
              onChange={handleFilter}
              label="Nome do checklist"
              value={filters.question}
              placeholder="Informe..."
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              onChange={checklistType =>
                setFilters(prevState => {
                  const newState = { ...prevState, checklistType };
                  fetchChecklists(newState);
                  return newState;
                })
              }
              value={filters.checklistType}
              options={checkListTypesOptions.filter(
                ({ slug }) => slug !== 'transhipment'
              )}
              label="Tipo de checklist"
              getOptionLabel={option => option.name}
              getOptionValue={option => option.slug}
              placeholder="Selecione..."
            />
          </Col>
        </Row>
      </div>
      <Fallback
        fall={checklists?.length === 0}
        component={
          loading ? (
            <DefaultLoadingComponent />
          ) : (
            <Col xs={12} className="d-flex justify-content-center my-5">
              <Button disabled>
                <Text>Nenhum registro encontrado.</Text>
              </Button>
            </Col>
          )
        }
      >
        <StyledDataTable
          data={checklists}
          loading={loading}
          total={pagination?.total}
          pages={pagination?.last_page || 1}
          onPageChanged={onPageChanged}
          onPerPageChanged={onPerPageChanged}
          perPageOptions={[{ v: 5 }, { v: 15 }, { v: 30 }]}
          columns={[
            {
              selector: row => row?.name,
            },
            {
              selector: row => row?.checkListType?.name,
            },
            {
              selector: row => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    loading={loading}
                    onClick={() => handleSelectChecklist(row)}
                  >
                    Selecionar
                  </Button>
                </div>
              ),
            },
          ]}
          withFilterOption={false}
        />
      </Fallback>
    </>
  );
}

export default function NewChecklistModal({
  modalHook,
  checkListTypesOptions,
  travelData,
  fetchTravelChecklists,
}) {
  return (
    <StyledModal
      show={modalHook.isOpen}
      body={
        <Body
          travelData={travelData}
          checkListTypesOptions={checkListTypesOptions}
          modalHook={modalHook}
          fetchTravelChecklists={fetchTravelChecklists}
        />
      }
      heading={<Header />}
      handleClose={modalHook.close}
      $width="50%"
    />
  );
}

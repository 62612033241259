import React from 'react';
import { Text, Modal, Button, Tooltip } from 'v3/components';
import {
  ContractDetailsModalContainer,
  SectionTable,
  TotalComputedContainer,
  TotalItemContainer,
  TotalsContainer,
} from './styles';
import theme from 'v3/theme';
import { formatCurrency } from 'v3/utils/formatter';
import { FaQuestionCircle } from 'react-icons/fa';

export function ContractDetailsModal({ isOpen, onClose, freightContract }) {
  const taxContracts = freightContract?.freightContractValues?.filter(
    item => item.operation.toLowerCase() === 'impostos'
  );
  const totalTax = taxContracts?.reduce((acc, curr) => {
    return (acc += curr.value);
  }, 0);
  const componentsContracts = freightContract?.freightContractValues?.filter(
    item => item.operation.toLowerCase() === 'componentes'
  );

  const componentsTotalComputed = componentsContracts?.reduce(
    (acc, curr) => {
      if (
        curr.description.toLowerCase() !== 'base de cálculo' &&
        curr.description.toLowerCase() !== 'repasse de serviços'
      ) {
        acc.componentsTotalWithoutCalculationBasis += curr.value;
        return acc;
      } else if (
        curr.description.toLowerCase() !== 'base de cálculo' &&
        curr.description.toLowerCase() === 'repasse de serviços'
      ) {
        acc.service += curr.value;
        return acc;
      } else {
        acc.calculationBasis += curr.value;
        return acc;
      }
    },
    {
      componentsTotalWithoutCalculationBasis: 0,
      service: 0,
      calculationBasis: 0,
    }
  );
  const releasesContracts = freightContract?.freightContractValues?.filter(
    item => item.operation.toLowerCase() === 'lançamentos'
  );

  const totalAdvances = releasesContracts?.reduce((acc, curr) => {
    const trimmedDescription = curr.description?.trim().toLowerCase();
    if (
      trimmedDescription?.startsWith('(+)') &&
      !trimmedDescription.includes('(+) pedágio')
    ) {
      return acc + curr.value;
    }
    return acc;
  }, 0);
  return (
    <Modal
      show={isOpen}
      size="lg"
      animation
      handleClose={onClose}
      heading={
        <>
          <Text type="subtitle" color="dark" weight="400">
            Contrato de Frete {freightContract?.contract}
          </Text>
          <Text style={{display:'block'}}type="regular" color="dark" weight="400">
            Viagem {freightContract?.travelId} - {freightContract?.travelExternal}
          </Text>
        </>
      }
      body={
        <ContractDetailsModalContainer>
          {componentsContracts?.length > 0 && (
            <ContractDetailSection
              title={'Componentes'}
              items={componentsContracts}
              totalComputed={
                componentsTotalComputed?.calculationBasis -
                Math.abs(
                  componentsTotalComputed?.componentsTotalWithoutCalculationBasis
                ) +
                componentsTotalComputed?.service
              }
            />
          )}
          {releasesContracts?.length > 0 && (
            <ContractDetailSection
              title={'Lançamentos'}
              items={releasesContracts}
            />
          )}
          {taxContracts?.length > 0 && (
            <ContractDetailSection title={'Impostos'} items={taxContracts} />
          )}
          <TotalsContainer>
            <TotalItemContainer>
              <Text color="dark" weight={500} fontSize={13} class="text-center">
                Adiantamentos
              </Text>
              <Text>{formatCurrency(totalAdvances)}</Text>
            </TotalItemContainer>
            <TotalItemContainer>
              <Text color="dark" weight={500} fontSize={13} class="text-center">
                Frete Tirar Margem
              </Text>
              <Text>{formatCurrency(freightContract?.freightTakeMargin)}</Text>
            </TotalItemContainer>
            <TotalItemContainer>
              <Text color="dark" weight={500} fontSize={13} class="text-center">
                Margem em %
              </Text>
              <Text>{freightContract?.margin} %</Text>
            </TotalItemContainer>
            <TotalItemContainer>
              <Text color="dark" weight={500} fontSize={13} class="text-center">
                Valor Impostos
              </Text>
              <Text>{formatCurrency(totalTax)}</Text>
            </TotalItemContainer>
            <TotalItemContainer>
              <Text color="dark" weight={500} fontSize={13} class="text-center">
                Valor Descontos
              </Text>
              <Text>{formatCurrency(freightContract?.discountsValue)}</Text>
            </TotalItemContainer>
            <TotalItemContainer>
              <Text color="dark" weight={500} fontSize={13} class="text-center">
                Valor Total
              </Text>
              <Text>{formatCurrency(freightContract?.totalValue)}</Text>
            </TotalItemContainer>
          </TotalsContainer>
        </ContractDetailsModalContainer>
      }
    />
  );
}

const ContractDetailSection = ({ title, items, totalComputed = 0 }) => {
  return (
    <section>
      <Text color={theme.colors.dark} weight={500}>
        {title}
      </Text>
      <SectionTable>
        <tbody>
          {items?.map(item => {
            let calculationBasis =
              item.description.toLowerCase() === 'base de cálculo';
            return (
              <tr key={item.id}>
                <td>
                  <Text
                    color={theme.colors.dark}
                    weight={calculationBasis && 700}
                  >
                    {item.type == 76 || item.type == 84 || item.type == 109 ? '(+)'
                    : item.description.indexOf('-') == 1 || item.description.indexOf('+') == 1 || item.description === 'Base de Cálculo' ? ''
                    : '(-)'} {item.description+' '}
                  {item.description === 'Base de Cálculo' && (
                    <Tooltip content="Valor Total a receber do Cliente Tomador">
                      <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
                    </Tooltip>
                  )}
                  {item.description === 'Taxa Administrativa' && (
                    <Tooltip content="Rentabilidade bruta da viagem">
                      <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
                    </Tooltip>
                  )}
                  </Text>
                </td>
                <td>
                  {' '}
                  <Text
                    color={theme.colors.dark}
                    weight={calculationBasis && 700}
                  >
                    {formatCurrency(item.value)}
                  </Text>
                </td>
              </tr>
            );
          })}
          {title === 'Componentes' && (
            <TotalComputedContainer>
              <td>
                <Text color={theme.colors.black} weight={500}>
                  Valor Calculado
                </Text>
              </td>
              <td>
                <Text color={theme.colors.black} weight={500}>
                  {formatCurrency(totalComputed)}
                </Text>
              </td>
            </TotalComputedContainer>
          )}
        </tbody>
      </SectionTable>
    </section>
  );
};

export const cancelReasons = [
  {
    label: '% de adiantamento baixa',
    value: '1 - % de adiantamento baixa',
  },
  {
    label: 'Cliente sem limite de crédito',
    value: '2 - Cliente sem limite de crédito',
  },
  {
    label: 'Cliente recusou o veículo',
    value: '3 - Cliente recusou o veículo',
  },
  {
    label: 'Exigências de PGR',
    value: '4 - Exigências de PGR',
  },
  {
    label: 'Falta de aceite da carga',
    value: '5 - Falta de aceite da carga',
  },
  {
    label: 'Falta de retorno da UF destino',
    value: '6 - Falta de retorno da UF destino',
  },
  {
    label: 'Falta de veículo',
    value: '7 - Falta de veículo',
  },
  {
    label: 'Frete abaixo do mercado ou fora da tabela',
    value: '8 - Frete abaixo do mercado ou fora da tabela',
  },
  {
    label: 'Repassado para outra transportadora',
    value: '9 - Repassado para outra transportadora',
  },
  {
    label: 'Veículo reprovado por checklist',
    value: '10 - Veículo reprovado por checklist',
  },
  {
    label: 'Ajuste de programação',
    value: '11 - Ajuste de programação',
  },
  {
    label: 'Especificação do veículo',
    value: '12 - Especificação do veículo',
  },
  {
    label: 'Problemas de qualidade ou falta de produto',
    value: '13 - Problemas de qualidade ou falta de produto',
  },
  {
    label: 'Veículo reprovado por checklist do cliente',
    value: '14 - Veículo reprovado por checklist do cliente',
  },
  {
    label: 'Carga duplicada',
    value: '15 - Carga duplicada',
  },
  {
    label: 'Carga para teste',
    value: '16 - Carga para teste',
  },
  {
    label: 'Erro de inclusão',
    value: '17 - Erro de inclusão',
  },
  {
    label: 'Cancelada pelo cliente tomador',
    value: '18 - Cancelada pelo cliente tomador',
  }
];

import React, { useEffect, useState } from 'react';
import { Text } from 'v3/components';
import Tabs from 'v3/components/Tabs';
import Tab from 'v3/components/Tab';
import theme from 'v3/theme';
import { useFleetManagement } from '../../shared';
import CargSuggestions from './CargSuggestions';
import UnusedSuggested from './UnusedSuggested';
import AttendedLoads from './AttendedLoads';
import ExpiredLoads from './ExpiredLoads';
import { cooperplaceApi } from 'services/api';


import { CounterContainer, HeadingContainer, StyledModal } from './styles';
import { Row } from 'react-bootstrap';

const TABS_OPTIONS = [
  {
    id: 1,
    name: 'Sugestão de cargas para disponibilidade do veículo',
    Component: CargSuggestions,
  },
  {
    id: 2,
    name: 'Cargas sugeridas não utilizadas',
    Component: UnusedSuggested,
  },
  {
    id: 3,
    name: 'Cargas vencidas',
    Component: ExpiredLoads,
  },
  {
    id: 4,
    name: 'Cargas atendidas',
    Component: AttendedLoads,
  },
];
function Body() {
  return (
    <div>
      <Tabs
        defaultActiveKey={1}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
          fontSize: '0.75rem',
        }}
        linkActiveColor="dark"
        linkActiveBackgroundColor="grayish_blue"
        linkWeight="500"
        linkPadding="0.5rem 1rem"
        linkBorder=" #dee2e6 #dee2e6 #fff"
      >
        {TABS_OPTIONS.map(({ id, Component, name }) => {
          return (
            <Tab
              title={name}
              eventKey={id}
              key={id}
              style={{ padding: '1rem 0.5rem' }}
            >
              {Component && <Component />}
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
}

function SuggestionsModal() {

  const [couterSuggestionData, couterSetSuggestionData] = useState(0);

  const {
    suggestionsModal: modalHook,
    setSelected,
    unusedSuggestion,
    attendedSuggestion,
    selected,
    expiredLoads
  } = useFleetManagement();

  const handleClose = () => {
    setSelected(null);
    modalHook.close();
  };

  const fetchCounterHistoryLoadSugestion = async (selectId) => {
    try {
      const response = await cooperplaceApi.get(`fleet/management/counterHistoryLoadSugestion/${selectId}`);
      couterSetSuggestionData(response.data);
      return response.data;
    } catch (ex) {
      return 0;
    }
  };

  useEffect(() => {
    if (selected?.id) {
      fetchCounterHistoryLoadSugestion(selected?.id)
    } else {
      couterSetSuggestionData(0)
    }
    return () => {
      couterSetSuggestionData(0);
    }
  }, [selected?.id])

  return (
    <StyledModal
      show={modalHook.isOpen}
      body={<Body />}
      heading={
        <HeadingContainer>
          <Row style={{display:'flex', gap:'5px', flexDirection:'column'}}>
            <Text type="header">Carregamentos</Text>
            <Text>Placa: {selected?.plate?.plate}</Text>
          </Row>
          <CounterContainer>
            <div>
              <Text type="label" color="gray" weight={500}>
                Sugestão
              </Text>
              <Text
                type="label"
                fontSize={18}
                color={theme.colors.success}
                weight={500}
              >
                {couterSuggestionData || 0}
              </Text>
            </div>
            <div>
              <Text type="label" color="gray" weight={500}>
                Atendidas
              </Text>
              <Text
                type="label"
                fontSize={18}
                color={theme.colors.success}
                weight={500}
              >
                {attendedSuggestion.meta?.total || 0}
              </Text>
            </div>
            <div>
              <Text type="label" color="gray" weight={500}>
                Cargas vencidas
              </Text>
              <Text
                type="label"
                fontSize={18}
                color={theme.colors.warning}
                weight={500}
              >
                {expiredLoads.meta?.total || 0}
              </Text>
            </div>
            <div>
              <Text type="label" color="gray" weight={500}>
                Não atendidas
              </Text>
              <Text
                type="label"
                fontSize={18}
                color={theme.colors.error}
                weight={500}
              >
                {unusedSuggestion.meta?.total || 0}
              </Text>
            </div>
          </CounterContainer>
        </HeadingContainer>
      }
      handleClose={handleClose}
    />
  );
}

export default SuggestionsModal;

import React, { memo, useEffect, useMemo, useState } from 'react';
import { Button, Link, Text, Tooltip } from 'v3/components';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import { StyledCheckbox } from 'components/CheckBox/styles';
import TextArea from 'v3/components/TextArea';
import Upload from 'v3/components/Upload';
import { useModal, usePermission } from 'hooks';
import { cooperplaceApi } from 'services/api';
import { useSnackbar } from 'v3/components/Snackbar';
import PDFView from 'v3/components/PDFView';
import Lightbox from 'react-image-lightbox';
import { formatDateTime } from 'v3/utils/formatter';
import { FaCheck, FaDownload, FaSearch, FaTimes } from 'react-icons/fa';
import { ChecklistProvider, useChecklist } from 'contexts/ChecklistDetails';
import theme from 'v3/theme';
import * as Styled from './styles';
import * as Function from './functions';
import SignatureModal from './SignatureModal';
import { useTravel } from '../..';

function CheckListBodyInformations() {
  const { checklist } = useChecklist();

  const travelChecklistType = {
    vehicle: 'Veículo',
    colect: 'Carga coleta',
    delivery: 'Entrega da carga',
    transhipment: 'Transbordo',
    noSelected: 'Não definido',
  };
  return (
    <Styled.StyledRow>
      <Col xs={12} md={6}>
        <Styled.StyledText bold>Título do Checklist:</Styled.StyledText>
        <Styled.StyledText>{checklist?.name}</Styled.StyledText>
      </Col>
      <Col xs={12} md={6}>
        <Styled.StyledText bold>Criado por:</Styled.StyledText>
        <Styled.StyledText>
          {checklist?.userCreated?.username && checklist?.createdAt
            ? `${checklist?.userCreated?.username} ${moment(
                checklist?.createdAt
              ).format('DD/MM/YYYY HH:mm:ss')}`
            : 'Não identificado'}
        </Styled.StyledText>
      </Col>
      <Col xs={12} md={6}>
        <Styled.StyledText bold>Tipo de Checklist:</Styled.StyledText>
        <Styled.StyledText>
          {travelChecklistType[checklist?.checklistType?.slug || 'noSelected']}
        </Styled.StyledText>
      </Col>
      <Col xs={12} md={6}>
        <Styled.StyledText bold>Respondido por:</Styled.StyledText>
        <Styled.StyledText>
          {checklist?.userUpdated?.username && checklist?.answeredAt
            ? `${checklist?.userUpdated?.username} ${moment(
                checklist?.answeredAt
              ).format('DD/MM/YYYY HH:mm:ss')}`
            : 'Não respondido'}
        </Styled.StyledText>
      </Col>
      {checklist?.clients?.length > 0 && (
        <>
          <Col xs={12} md={6}>
            <>
              <Styled.StyledText bold>
                {checklist?.clients.length === 1 ? 'CNPJ:' : 'CNPJs:'}
              </Styled.StyledText>
              {checklist?.clients.map(client => {
                if (client?.person?.cpfCnpj) {
                  return (
                    <Styled.StyledText>
                      {client?.person?.cpfCnpj}
                    </Styled.StyledText>
                  );
                }
                return null;
              })}
            </>
          </Col>
          <Col xs={12} md={6}>
            {checklist?.answeredAt && (
              <>
                <Styled.StyledText bold>Nome do Motorista:</Styled.StyledText>
                <Styled.StyledText>
                  {checklist?.driverSign?.sign?.user?.username ? checklist?.driverSign?.sign?.user?.username :
                  checklist?.travel?.driver?.socialName ? checklist?.travel?.driver?.socialName :
                  'Não informado'}
                </Styled.StyledText>
              </>
            )}
          </Col>
          <Col xs={12} md={6}>
            <Styled.StyledText bold>Negociação:</Styled.StyledText>
            <Styled.StyledText>
              {checklist?.checklist?.negotiation?.name
                ? checklist.checklist.negotiation.name
                : 'Não informado'}
            </Styled.StyledText>
          </Col>
        </>
      )}
      <Col xs={12} md={6}>
      {checklist?.answeredAt && (
        <>
          <Styled.StyledText bold>Telefone Motorista:</Styled.StyledText>
          <Styled.StyledText>
            {checklist?.driverSign ? checklist?.driverSign?.sign?.user?.phonenumber :
            checklist?.travel?.driver?.phone ? checklist?.travel?.driver?.phone :
            'Não Informado'}
          </Styled.StyledText>
        </>
      )}
      </Col>
      <Col xs={12} md={6}>
        <Styled.StyledText bold>Motorista Responde Pelo APP:</Styled.StyledText>
        <Styled.StyledText>
          {checklist?.availableForDriver ? 'Sim' : 'Não'}
        </Styled.StyledText>
      </Col>
      <Col xs={12} md={6}>
      {checklist?.answeredAt && (
        <>
          <Styled.StyledText bold>Localização Assinatura Motorista:</Styled.StyledText>
          {checklist?.driverSign ?
          <a href={`https://www.google.com/maps/search/?api=1&query=${checklist?.driverSign?.latitude},${checklist?.driverSign?.longitude}`} target="_blank">
            <Text className="ml-2" type="label" color="gray">
            {`${checklist?.driverSign?.latitude},${checklist?.driverSign?.longitude}`}
            </Text>
          </a>
          :'Não Assinado'}
        </>
      )}
      </Col>
      <Col xs={12} md={12}>
        <Styled.StyledText bold>Descrição do Checklist:</Styled.StyledText>
        <Styled.StyledText style={{ 'word-wrap': 'break-word'}}>{checklist?.description}</Styled.StyledText>
      </Col>
    </Styled.StyledRow>
  );
}

function Question({
  question,
  index,
  openUploadAttachment,
  setOpenUploadAttachment,
  cleanAttachments,
}) {
  const { answered } = useChecklist();
  return (
    <Col xs={12}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        <Styled.StyledText>{index}</Styled.StyledText>
        <Styled.QuestionContainer>
          <Styled.StyledText>{question?.question}</Styled.StyledText>
          {question?.required && (
            <Styled.StyledText as="span">*</Styled.StyledText>
          )}
        </Styled.QuestionContainer>
        {question?.needAttachment && !answered && (
          <Button
            variant={openUploadAttachment ? 'secondary' : 'primary'}
            style={{ margin: '8px 0' }}
            onClick={() => {
              setOpenUploadAttachment(prevState => {
                if (cleanAttachments && prevState === true) {
                  cleanAttachments();
                }
                return !prevState;
              });
            }}
          >
            {openUploadAttachment ? 'Cancelar ' : 'Anexo'}
          </Button>
        )}
      </div>
    </Col>
  );
}

function DisplayAttachments({ attachments = [], attachment }) {
  const [isHovering, setHovering] = useState(false);
  const [isShowingModal, setShowingModal] = useState(false);

  const fullPath = useMemo(() => Function.fullPath(attachment), [attachment]);

  const attachmentsWithoutPDF = useMemo(
    () => Function.attachmentsWithoutPDF(attachments),
    [attachments]
  );

  const [lightbox, setLightbox] = useState({
    isOpen: false,
    attachments: attachmentsWithoutPDF,
    index: 0,
  });

  const lightboxIndexes = useMemo(
    () => Function.lightboxIndexes(lightbox),
    [lightbox.index, lightbox.attachments]
  );

  const lightboxTitle = useMemo(
    () => Function.lightboxTitle(lightbox),
    [lightbox.index, lightbox.attachments]
  );
  return (
    <div>
      <Styled.StyledModal
        show={isShowingModal}
        dialogClassName="modal-90w"
        onHide={() => setShowingModal(false)}
        heading="Visualizar PDF"
        body={<PDFView file={fullPath} />}
      />
      <div
        className="position-relative ml-2"
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={{
          width: 270,
          height: 160,
          background: `url(${
            attachment?.name.split('.').pop() === 'pdf'
              ? '/images/default-doc.png'
              : fullPath
          }) center center`,
          backgroundSize: '100%',
        }}
      >
        {lightbox.isOpen && (
          <Lightbox
            mainSrc={Function.getImagePath({
              index: lightbox.index,
              alwaysDisplay: true,
              lightbox,
              lightboxIndexes,
            })}
            nextSrc={Function.getImagePath({
              index: lightboxIndexes.next,
              lightbox,
              lightboxIndexes,
            })}
            prevSrc={Function.getImagePath({
              index: lightboxIndexes.previous,
              lightbox,
              lightboxIndexes,
            })}
            onCloseRequest={() =>
              setLightbox(prev => ({ ...prev, isOpen: false }))
            }
            onMovePrevRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.previous,
              }))
            }
            onMoveNextRequest={() =>
              setLightbox(prev => ({
                ...prev,
                index: lightboxIndexes.next,
              }))
            }
            imageTitle={lightboxTitle}
            reactModalStyle={{
              overlay: {
                zIndex: 2000,
              },
            }}
          />
        )}
        {isHovering && (
          <Styled.Backdrop>
            {attachment.name.split('.').pop() !== 'xlsx' && (
              <button
                type="button"
                onClick={() =>
                  Function.handleClickOpenAttachment({
                    attachment,
                    setShowingModal,
                    setLightbox,
                    attachmentsWithoutPDF,
                  })
                }
              >
                <FaSearch color="#fff" size={32} />
              </button>
            )}
            <a href={fullPath} download>
              <FaDownload color="#fff" size={32} />
            </a>
          </Styled.Backdrop>
        )}
        <Styled.DateTime>
          <Text type="label" color="white">
            {formatDateTime(attachment?.created_at ? attachment?.created_at : attachment?.createdAt ? attachment?.createdAt : 'Não informado')}
          </Text>
        </Styled.DateTime>
      </div>
    </div>
  );
}

function RenderAttachment({ question }) {
  const { answered, setQuestions } = useChecklist();
  return answered ? (
    question?.attachments[0]?.attachment?.name.indexOf('mp4') > 0 ?
    <video width="320" height="240" controls>
      <source src={process.env.REACT_APP_URL_BLOB+'/'+question?.attachments[0]?.attachment?.path+'/'+question?.attachments[0]?.attachment?.name}/>
    </video>
    :
    <Styled.AnswerContainer>
      {question?.attachments.length > 0 &&
        question.attachments.map(({ attachment }) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <DisplayAttachments
              attachment={attachment}
              attachments={question.attachments}
            />
            <Text
              type="regular"
              style={{
                'max-width': '75%',
                'word-wrap': 'break-word',
              }}
            >
              {attachment.description}
            </Text>
          </div>
        ))}
    </Styled.AnswerContainer>
  ) : (
    <Upload
      name="file"
      label="Arquivo máximo de 20MB"
      accept=".jpg,.jpeg,.png,.mkv,.mp4"
      multiple
      onChange={({ target: { files } }) => {
        setQuestions(prevState => {
          const newQuestions = [...prevState];
          const index = newQuestions.findIndex(({ id }) => id === question?.id);
          const currentQuestion = newQuestions[index];
          newQuestions[index].error = null;
          newQuestions[index].attachment = {
            files,
            questionId: currentQuestion.id,
          };
          return newQuestions;
        });
      }}
    />
  );
}

const Response = memo(function ResponseComponent({ question }) {
  const { answered, setQuestions } = useChecklist();
  const OPTIONS_TYPES = ['unique', 'multiple', 'logic'];

  if (question?.type === 'image' || question?.type === 'video') {
    return <RenderAttachment question={question} />;
  }

  if (OPTIONS_TYPES.includes(question?.type)) {
    return (
      <Styled.AnswerContainer>
        {question?.responses?.length > 0 &&
          question.responses.map((response, responseIndex) => (
            <div key={response.id}>
              <StyledCheckbox
                onChange={(_a, isChecked) =>
                  Function.handleOptions({
                    isChecked,
                    responseIndex,
                    setQuestions,
                    question,
                  })
                }
                checked={response.selected === true}
                disabled={answered}
              />
              <Styled.StyledText as="span">
                {response.description}
              </Styled.StyledText>
            </div>
          ))}
        {answered && question?.attachments?.length > 0 && (
          <>
            <Styled.StyledText bold>
              Imagens anexadas a pergunta:
            </Styled.StyledText>
            <RenderAttachment question={question} />
          </>
        )}
      </Styled.AnswerContainer>
    );
  }

  return (
    <Styled.AnswerContainer>
      <TextArea
        value={question?.descriptiveResponse}
        maxLength={250}
        onChange={e => Function.handleTextArea(e, setQuestions, question)}
        disabled={answered}
      />
      {answered && question?.attachments?.length > 0 && (
        <>
          <Styled.StyledText bold>
            Imagens anexadas a pergunta:
          </Styled.StyledText>
          <RenderAttachment question={question} />
        </>
      )}
    </Styled.AnswerContainer>
  );
});

const cleanSubQuestions = (setQuestions, subQuestion) => {
  setQuestions(prevState => {
    const newState = [...prevState];
    const indexSubQuestion = newState.findIndex(
      item => item.id === subQuestion.id
    );
    newState[indexSubQuestion].responses.forEach((_response, i) => {
      newState[indexSubQuestion].responses[i].selected = false;
    });
    newState[indexSubQuestion].descriptiveResponse = null;
    newState[indexSubQuestion].attachments = [];
    return prevState;
  });
};

const QuestionsList = memo(function QuestionsListComponent({
  question,
  index,
}) {
  const { questions, setQuestions } = useChecklist();
  const [openUploadAttachment, setOpenUploadAttachment] = useState(false);
  const cleanAttachments = () => {
    setQuestions(prevState => {
      const newQuestions = [...prevState];
      newQuestions[index].attachment = {};
      return newQuestions;
    });
  };

  if (question?.isSubQuestion) return null;
  let subQuestions;

  if (question?.hasSubQuestion) {
    subQuestions = questions.filter(
      ({ fatherQuestionId }) => fatherQuestionId === question?.id
    );
  }

  if (question?.mediaError && !openUploadAttachment) {
    setOpenUploadAttachment(true);
  }

  return (
    <Col md={12} lg={6} key={question?.id}>
      <Question
        index={`${question.order}`}
        question={question}
        openUploadAttachment={openUploadAttachment}
        setOpenUploadAttachment={setOpenUploadAttachment}
        cleanAttachments={cleanAttachments}
      />
      <Response question={question} />
      {question?.error && (
        <Styled.StyledText color="#F64E60">{question?.error}</Styled.StyledText>
      )}
      {openUploadAttachment && (
        <div style={{ padding: '0 7.5px', marginTop: 8 }}>
          <Upload
            label="Arquivo máximo de 1MB"
            accept=".jpg,.jpeg,.png"
            multiple
            onChange={({ target: { files } }) => {
              setQuestions(prevState => {
                const newQuestions = [...prevState];
                const currentQuestion = newQuestions[index];
                currentQuestion.attachment = {
                  files,
                  questionId: currentQuestion.id,
                };
                currentQuestion.mediaError = null;
                currentQuestion.error = null;
                return newQuestions;
              });
              return null;
            }}
          />
          {question?.mediaError && (
            <Styled.StyledText color="#F64E60">
              {question?.mediaError}
            </Styled.StyledText>
          )}
        </div>
      )}
      {question?.hasSubQuestion &&
        subQuestions?.length &&
        subQuestions.map((subQuestion, indexSubquestion) => {
          const isSelectedTrigger = question.responses.find(response => {
            return question.questionTrigger.find(
              trigger => response.id === trigger.id && response.selected
            );
          });

          if (!isSelectedTrigger) {
            cleanSubQuestions(setQuestions, subQuestion);
          }

          if (isSelectedTrigger) {
            return (
              <div style={{ marginLeft: 15 }}>
                <Question
                  index={`${question.order}.${indexSubquestion + 1}`}
                  question={subQuestion}
                />
                <Response question={subQuestion} />
                {subQuestion?.error && (
                  <Styled.StyledText color="#F64E60">
                    {subQuestion?.error}
                  </Styled.StyledText>
                )}
              </div>
            );
          }
          return null;
        })}
    </Col>
  );
});

function CheckListQuestions() {
  const { questions } = useChecklist();

  return (
    <Styled.StyledRow style={{ marginTop: 16 }}>
      {questions.map((question, index) => (
        <QuestionsList key={question?.id} question={question} index={index} />
      ))}
    </Styled.StyledRow>
  );
}

function Header({ downloadChecklist }) {
  const { isFetching, checklist } = useChecklist();

  return (
    <Styled.SpaceBetween>
      <div>
        <Text color="dark" type="header">
          Detalhes do Checklist
        </Text>
        <Text color="dark" type="header">
          {checklist?.checklist?.default ? ' - Padrão - ' : ' - '}
        </Text>
        <Text color="dark" type="header">
          {checklist?.id}
        </Text>
      </div>
      {checklist?.status?.slug === 'ANSWERED' && (
        <Button loading={isFetching} onClick={downloadChecklist}>
          Exportar
        </Button>
      )}
    </Styled.SpaceBetween>
  );
}

function Body() {
  return (
    <div>
      <CheckListBodyInformations />
      <CheckListQuestions />
    </div>
  );
}

function Footer({ warningModal, handleSaveChecklist, toSign }) {
  const { isFetching, answered, checklist } = useChecklist();
  const hasPermissionToAnull = usePermission('ANULAR_CHECKLIST_VIAGEM');
  return (
    <div style={{ display: 'flex', gap: 10 }}>
      {checklist.status.slug !== 'ANSWERED' &&
        (hasPermissionToAnull ? (
          <Button
            loading={isFetching}
            onClick={warningModal.open}
            variant="error"
          >
            Anular checklist
          </Button>
        ) : (
          <Tooltip
            content={
              <Text>
                Você não tem permissão para anular um checklist de viagem!
              </Text>
            }
          >
            <Button
              disabled={!hasPermissionToAnull}
              loading={isFetching}
              variant="error"
            >
              Anular checklist
            </Button>
          </Tooltip>
        ))}
      {!answered && (
        <Button loading={isFetching} onClick={handleSaveChecklist}>
          Enviar
        </Button>
      )}

      {answered &&
        (checklist?.driverSign ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text color={theme.colors.success}>
              Assinado pelo Motorista <FaCheck />
            </Text>
            <Text>{checklist?.driverSign?.sign?.user?.username}</Text>
            <Text>
              {moment(checklist?.driverSign?.created_at).format('DD/MM/YYYY HH:mm')}
            </Text>
          </div>
        ) : (
          <Text color={theme.colors.danger}>
            Não assinado pelo Motorista <FaTimes />
          </Text>
        ))}

      {answered &&
        (checklist?.inspectorSign ? (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text color={theme.colors.success}>
              Assinado pelo Vistoriador <FaCheck />
            </Text>
            <Text>{checklist?.inspectorSign?.sign?.user?.username}</Text>
            <Text>
            {moment(checklist?.inspectorSign?.created_at).format('DD/MM/YYYY HH:mm')}
            </Text>
          </div>
        ) : (
          <Button loading={isFetching} onClick={toSign}>
            Assinar
          </Button>
        ))}
    </div>
  );
}

export default function CheckListDetailModal({
  modalHook,
  checklist,
  travelData,
  fetchTravelChecklists,
  setSelectedChecklistId,
  onConfirmResponse = async () => {},
  hideFooter,
  handleClose = () => {},
}) {
  const anullModal = useModal();
  const confirmResponseModal = useModal();
  const confirmaSignatureModal = useModal();
  const signatureModal = useModal();
  const snackbar = useSnackbar();
  const { fetchFeed } = useTravel();
  const [isFetching, setIsFetching] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [signature, setSignature] = useState(null);
  const [location, setLocation] = useState(null);

  const handleCancelChecklist = async () => {
    if (!checklist?.id) return;
    try {
      setIsFetching(true);
      await cooperplaceApi.delete(`/travel-checklist/${checklist.id}`, {
        params: {
          travelId: travelData?.id,
        },
      });
      await fetchTravelChecklists();
      await onConfirmResponse();
      fetchFeed();
      anullModal.close();
      modalHook.close();
    } catch (error) {
      snackbar.show(<Text>Erro ao anular checklist</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  const handleSaveChecklist = async () => {
    const isValidChecklist = Function.validateChecklist(
      questions,
      setQuestions
    );
    if (!isValidChecklist) return;
    confirmResponseModal.open();
  };

  const downloadChecklist = async () => {
    try {
      setIsFetching(true);
      await cooperplaceApi
        .get(`travel-checklist/${checklist?.id}/export`, {
          params: {
            travelId: travelData?.id,
          },
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
        })
        .then(response => {
          const fileName = `checklist_${checklist.checklistType.slug}_${checklist.id}.pdf`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
    } catch (error) {
      snackbar.show(<Text>Erro ao exportar checklist</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  const handleConfirmResponse = async () => {
    try {
      setIsFetching(true);
      const questionsWithMedia = questions.filter(
        question => !!question.attachment?.files
      );

      if (questionsWithMedia.length) {
        await Function.sendMedias(questionsWithMedia);
      }

      const confirmedMediasQuestions = [...questions];
      questionsWithMedia.forEach(question => {
        const index = confirmedMediasQuestions.findIndex(
          confirmedQuestion => confirmedQuestion.id === question.id
        );
        confirmedMediasQuestions[index].sentAttachment = true;
      });
      await Function.answerChecklist(confirmedMediasQuestions, checklist?.id);
      await fetchTravelChecklists();
      await onConfirmResponse();
      fetchFeed();
      modalHook.close();
    } catch (error) {
      snackbar.show(<Text>Erro ao responder checklist</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
      confirmResponseModal.close();
    }
  };

  const getUserSignature = async () => {
    try {
      setIsFetching(true);
      const {
        data: { base64 },
      } = await cooperplaceApi.get('users/signature');
      setSignature(base64);
      return base64;
    } catch (error) {
      snackbar.show(<Text>Erro ao trazer assinatura</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
    return null;
  };

  const signChecklist = async () => {
    try {
      setIsFetching(true);
      const userIp = await fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          const { ip } = data;
          return ip;
        })
        .catch(error => {
          console.error('Erro ao obter o IP:', error);
        });

      const response = await cooperplaceApi.post(
        `travel-checklist-response/${checklist.id}/signature`,
        {
          latitude: location?.latitude,
          longitude: location?.longitude,
          ip: userIp,
        }
      );

      snackbar.show(
        <Text>
          {response?.data?.message || 'Checklist assinado com sucesso!'}
        </Text>,
        {
          type: 'success',
        }
      );

      fetchTravelChecklists();
      fetchFeed();
      setSelectedChecklistId(checklist.id);
    
    } catch (error) {
      snackbar.show(<Text>Erro ao assinar checklist</Text>, {
        type: 'error',
      });
    } finally {
      setIsFetching(false);
    }
  };

  const toSign = () => confirmaSignatureModal.open();

  const confirmSign = async () => {
    confirmaSignatureModal.close();
    const base64 = await getUserSignature();
    if (base64) {
      await signChecklist();
    } else {
      signatureModal.open();
    }
  };

  const getLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
        },
        error => {
          console.error('Erro ao obter localização:', error.message);
        }
      );
    } else {
      console.error('Geolocalização não suportada');
    }
  };

  useEffect(() => {
    if (checklist?.questions?.length) {
      const formatedResponses = checklist.questions
        .sort((a, b) => a.order - b.order)
        .map(Function.normalizeQuestions);
      setQuestions(formatedResponses);
    }
  }, [checklist]);

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <ChecklistProvider
      value={{
        checklist,
        questions,
        setQuestions,
        answered: !!checklist?.userUpdated,
        isFetching,
      }}
    >
      <Styled.StyledModal
        centered
        show={modalHook.isOpen}
        body={<Body />}
        footer={
          hideFooter ? null : (
            <Footer
              warningModal={anullModal}
              handleSaveChecklist={handleSaveChecklist}
              toSign={toSign}
            />
          )
        }
        $width="1000px"
        heading={<Header downloadChecklist={downloadChecklist} />}
        handleClose={() => {
          handleClose();
          modalHook.close();
        }}
      />
      <Styled.StyledModal
        centered
        show={anullModal.isOpen}
        heading={<Styled.StyledText>Anular checklist</Styled.StyledText>}
        handleClose={anullModal.close}
        $width="1000px"
        body={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Styled.StyledText>
              Essa ação é{' '}
              <Styled.StyledText color="#F64E60" as="span">
                irreversível
              </Styled.StyledText>
              , tem certeza que deseja prosseguir?
            </Styled.StyledText>
          </div>
        }
        footer={
          <div style={{ display: 'flex', gap: 8 }}>
            <Button
              loading={isFetching}
              onClick={anullModal.close}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button loading={isFetching} onClick={handleCancelChecklist}>
              Anular
            </Button>
          </div>
        }
        backdropClassName="backdrop-occurrences-aditional-field"
      />
      <Styled.StyledModal
        centered
        show={confirmResponseModal.isOpen}
        heading={<Styled.StyledText>Responder checklist</Styled.StyledText>}
        handleClose={confirmResponseModal.close}
        $width="1000px"
        body={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Styled.StyledText>
              Confirma as respostas inseridas no checklist?
            </Styled.StyledText>
            <Styled.StyledText>
              Após respostas salvas não é possível alterar
            </Styled.StyledText>
          </div>
        }
        footer={
          <div style={{ display: 'flex', gap: 8 }}>
            <Button
              loading={isFetching}
              onClick={confirmResponseModal.close}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button loading={isFetching} onClick={handleConfirmResponse}>
              Confirmar
            </Button>
          </div>
        }
        backdropClassName="backdrop-occurrences-aditional-field"
      />
      <SignatureModal
        signChecklist={signChecklist}
        signature={signature}
        signatureModal={signatureModal}
        snackbar={snackbar}
        setIsFetching={setIsFetching}
        isFetching={isFetching}
        $width="1000px"
      />
      <Styled.StyledModal
        centered
        show={confirmaSignatureModal.isOpen}
        heading={<Styled.StyledText>Assinar checklist</Styled.StyledText>}
        handleClose={confirmaSignatureModal.close}
        $width="1000px"
        body={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Styled.StyledText>
              Tem certeza de que deseja assinar o checklist?
            </Styled.StyledText>
            <Styled.StyledText>
              Você será o responsável pelas informações contidas.
            </Styled.StyledText>
          </div>
        }
        footer={
          <div style={{ display: 'flex', gap: 8 }}>
            <Button
              loading={isFetching}
              onClick={confirmaSignatureModal.close}
              variant="secondary"
            >
              Cancelar
            </Button>
            <Button loading={isFetching} onClick={confirmSign}>
              Confirmar
            </Button>
          </div>
        }
        backdropClassName="backdrop-occurrences-aditional-field"
      />
    </ChecklistProvider>
  );
}

import React, { useMemo } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import {
  FaEye,
  FaEyeSlash,
  FaQuestionCircle,
  FaRegTrashAlt,
} from 'react-icons/fa';
import { formatCurrency } from 'utils/formatter';
import theme from 'v3/theme';
import { Text, Badge, Tooltip, Select, Button } from 'v3/components';
import InputCurrency from 'v3/components/Input/Currency';
import { makeConversion } from 'v3/utils/formatter';
import TextArea from 'v3/components/TextArea';

import { CalculatorButton } from 'v3/components/Calculator';
import {
  CalculatorWrap,
  HeaderTotalSummaryContainer,
  LabelsRowCol,
  StyledCardHeader,
  StyledExtraItemRow,
} from './styles';

export function LabelsRow() {
  return (
    <Row className="my-2">
      <Col md={2} />
      <LabelsRowCol md={1}>
        <Text type="label" color="dark" weight={500}>
          %
        </Text>
      </LabelsRowCol>
      <LabelsRowCol md={3}>
        <Text type="label" color="dark" weight={500}>
          R$
        </Text>
      </LabelsRowCol>
      <LabelsRowCol md={3}>
        <Text type="label" color="dark" weight={500}>
          $
        </Text>
      </LabelsRowCol>
      <Col md={3} />
    </Row>
  );
}

export function CardHeaderComponent({
  isOpen = false,
  setIsOpen,
  label = '',
  tooltipContent = '',
  rightSection = null,
  totalsSection = null,
  styles = {},
  hasTagsRow = true,
  tagsContent = '',
}) {
  const shouldShowTagsRow = hasTagsRow && isOpen === false;
  return (
    <StyledCardHeader style={{ ...styles }}>
      {isOpen ? (
        <button onClick={() => setIsOpen(!isOpen)} className="arrowButton">
          <MdKeyboardArrowUp size={24} />
        </button>
      ) : (
        <button onClick={() => setIsOpen(!isOpen)} className="arrowButton">
          <MdKeyboardArrowDown size={24} />
        </button>
      )}
      <Text as="p" className="label">
        {label}
        <Tooltip
          content={
            <Text type="label" color="white">
              {tooltipContent}
            </Text>
          }
        >
          <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
        </Tooltip>
      </Text>
      {isOpen && !!rightSection && <div>{rightSection}</div>}
      {isOpen === false && <div>{totalsSection}</div>}
      {shouldShowTagsRow && (
        <div className="tagsRow">
          {tagsContent?.split(',').map((tag, index) => {
            return (
              <Badge
                variant="secondary"
                key={`${index}_${new Date().getTime()}`}
                pill
                style={{ padding: '0.5rem' }}
              >
                {tag}
              </Badge>
            );
          })}
        </div>
      )}
    </StyledCardHeader>
  );
}

export function CardSummaryComponent({
  label = '',
}) {
  return (
    <>
      <Text as="p" className="label" >
        {label}
      </Text>
    </>
  );
}

export function HeaderTotalSummary({ label, totalInReal, totalInDollar }) {
  return (
    <HeaderTotalSummaryContainer>
      <Text type="medium" as="p" weight={500}>
        {label}
      </Text>
      <Text type="medium" as="p" weight={500}>
        {formatCurrency(totalInReal, 'BRL', 'R$')}
      </Text>
      <Text type="medium" as="p" weight={500}>
        {formatCurrency(totalInDollar, 'USD', '$', 4)}
      </Text>
    </HeaderTotalSummaryContainer>
  );
}

export function FreightItemRow({
  label,
  shouldDisablePercentageField = false,
  percentageValueReference = 0,
  percentageValue = null,
  onChangePercentageValue = () => {},
  value,
  onChangeValue = () => {},
  isBRLCurrency = true,
  dollarToUse = 0,
  selectOptions = [],
  selectValue = {},
  onChangeSelectValue = () => {},
  isRequired = false,
  errorField = '',
  hasTooltip = false,
  hasValidation = false,
  tooltipContent = '',
  shouldDisableField = false,
  wasValueRemovedFromTotal = false,
  advanceMarginError = false,
  showCalculator = false,
  id,
  decimalDigits = 2,
}) {
  const hasPercentageValue = !!percentageValue;
  const hasSelectField = selectOptions.length > 0;
  const isPercentageValueReferenceNull =
    hasPercentageValue &&
    (!percentageValueReference || percentageValueReference == '0.00');

  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }

  const disableCalculator = useMemo(() => {
    return isPercentageValueReferenceNull || shouldDisableField;
  }, [isPercentageValueReferenceNull, shouldDisableField]);

  return (
    <Row className="my-2 align-items-start">
      <Col sm={12} md={2}>
        <Text type="regular" weight={500} color={label.indexOf('negociado') > 0 ? 'danger' : 'dark'}>
          {label}
        </Text>
      </Col>
      {hasPercentageValue ? (
        <Col md={1}>
          <InputCurrency
            value={percentageValue}
            decimalDigits={decimalDigits}
            onChange={onChangePercentageValue}
            disabled={
              isPercentageValueReferenceNull ||
              shouldDisableField ||
              shouldDisablePercentageField
            }
            id={id ? `input-${id}-porcentagem` : undefined}
          />
        </Col>
      ) : (
        <Col md={1} />
      )}
      <Col md={6} className="d-flex align-items-center">
        <InputCurrency
          className="mr-2"
          value={isBRLCurrency ? value : convertValue(value)}
          onChange={onChangeValue}
          disabled={
            isPercentageValueReferenceNull ||
            isBRLCurrency === false ||
            shouldDisableField
          }
          error={
            ((isRequired || hasValidation) && isBRLCurrency
              ? errorField
              : '') ||
            (advanceMarginError &&
              '% de adiantamento negociado deve ser menor que o % máxima de adiantamento no ERP')
          }
          fontColor={wasValueRemovedFromTotal ? 'red' : '#494950'}
          id={id ? `input-${id}-real` : undefined}
        />
        <InputCurrency
          value={isBRLCurrency ? convertValue(value) : value}
          onChange={onChangeValue}
          disabled={
            isPercentageValueReferenceNull ||
            isBRLCurrency === true ||
            shouldDisableField
          }
          error={
            (isRequired || hasValidation) && !isBRLCurrency ? errorField : ''
          }
          fontColor={wasValueRemovedFromTotal ? 'red' : '#494950'}
          id={id ? `input-${id}-dolar` : undefined}
        />
        {showCalculator && (
          <CalculatorWrap>
            {!disableCalculator ? (
              <Nav>
                <CalculatorButton
                  dollarToUse={dollarToUse}
                  id={`button-abrir-calculadora-${id}`}
                  disabled={disableCalculator}
                  isBRLCurrency={isBRLCurrency}
                  onChange={(event, newValue) => {
                    onChangeValue(event, newValue);
                  }}
                />
              </Nav>
            ) : (
              <>&nbsp;</>
            )}
          </CalculatorWrap>
        )}
      </Col>
      {hasSelectField ? (
        <>
          <Col
            md={3}
            style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}
          >
            <Select
              disableClear
              value={selectValue}
              onChange={onChangeSelectValue}
              options={selectOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.code}
              id={`select-${id}`}
            />
            {hasTooltip && (
              <Tooltip
                content={
                  <Text type="label" color="white">
                    {tooltipContent}
                  </Text>
                }
              >
                <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
              </Tooltip>
            )}
          </Col>
        </>
      ) : hasTooltip ? (
        <Col md={3}>
          <Tooltip
            content={
              <Text type="label" color="white">
                {tooltipContent}
              </Text>
            }
          >
            <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
          </Tooltip>
        </Col>
      ) : (
        <Col md={3} />
      )}
    </Row>
  );
}

export function SummaryRow({ label, totalInBRL, totalInUSD, id }) {
  return (
    <>
      <hr style={{ height: '1px' }} />
      <Row className="my-2">
        <Col md={2}>
          <Text type="regular" weight={500}>
            {label}
          </Text>
        </Col>
        <Col md={1} />
        <Col md={3}>
          <Text id={id && `label-${id}-real`} type="regular">
            {formatCurrency(totalInBRL, 'BRL')}
          </Text>
        </Col>
        <Col md={3}>
          <Text id={id && `label-${id}-dolar`} type="regular">
            {formatCurrency(totalInUSD, 'USD', '$', 4)}
          </Text>
        </Col>
        <Col md={3} />
      </Row>
    </>
  );
}

export function ExtraFieldsRow({
  label = 'Gerenciar valores extras',
  openModal,
  id,
}) {
  return (
    <Row className="my-2">
      <Col>
        <Button id={id} variant="primary" size="sm" onClick={() => openModal()}>
          <Text color="white" weight={500} type="medium">
            {label}
          </Text>
        </Button>
      </Col>
    </Row>
  );
}

export function ExtraItemRow({
  label = '',
  value = 0,
  showToDriver = false,
  isBRLCurrency = true,
  dollarToUse = 0,
  onSetExtraFields = () => {},
  id,
}) {
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  return (
    <StyledExtraItemRow>
      <Col md={3}>
        <Text id={id ? `label-${id}` : undefined} type="regular">
          {label}
        </Text>
      </Col>
      <Col md={3}>
        <InputCurrency
          value={isBRLCurrency ? value : convertValue(value)}
          disabled
          id={id && `input-${id}-real`}
        />
      </Col>
      <Col md={3}>
        <InputCurrency
          value={!isBRLCurrency ? value : convertValue(value)}
          disabled
          id={id && `input-${id}-dolar`}
        />
      </Col>
      <Col md={3}>
        <button type="button" id={id && `button-${id}-excluir`}>
          <FaRegTrashAlt
            color={theme.colors.danger}
            onClick={onSetExtraFields}
            title="Remover item"
          />
        </button>
        <button
          type="button"
          disabled
          style={{ background: 'transparent', cursor: 'default' }}
        >
          {showToDriver ? (
            <FaEye
              color={theme.colors.dark_blue}
              title="Motorista poderá visualizar este valor"
            />
          ) : (
            <FaEyeSlash
              color={theme.colors.dark_blue}
              title="Motorista não visualiza este valor"
            />
          )}
        </button>
      </Col>
    </StyledExtraItemRow>
  );
}

export function ObservationsContainer({
  hasNegotiatorObs = false,
  pcpLabel = '',
  pcpValue = '',
  onChangePcpValue = () => {},
  pcpError = '',
  negotiatorLabel = '',
  negotiatorValue = '',
  onChangeNegotiatorValue = () => {},
  negotiatorError = '',
  shouldDisableFieldPcp = false,
  id,
}) {
  return (
    <Row className="mt-2">
      <Col xs={12} md={12}>
        <Text type="medium" weight={500}>
          {pcpLabel}
        </Text>
        <TextArea
          value={pcpValue}
          onChange={onChangePcpValue}
          error={pcpError}
          disabled={shouldDisableFieldPcp}
          id={id && `textarea-pcp-${id}`}
        />
      </Col>
      {hasNegotiatorObs && (
        <Col xs={12} md={12}>
          <Text type="medium" weight={500}>
            {negotiatorLabel}
          </Text>
          <TextArea
            id={id && `textarea-negociador-${id}`}
            value={negotiatorValue}
            onChange={onChangeNegotiatorValue}
            error={negotiatorError}
          />
        </Col>
      )}
    </Row>
  );
}

export function ValueDetailsRow({
  label,
  percentageValue = null,
  value,
  exchangeToUse,
  obs = null,
  hasTooltip = false,
  tooltipContent = '',
  isBRLCurrency = true,
  wasValueRemovedFromTotal = false,
  id,
  color = null,
  weight = null
}) {
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, exchangeToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  const hasPercentageValue =
    percentageValue !== null && percentageValue !== undefined;
  const percentageValueScale = hasPercentageValue
    ? String(percentageValue)?.length
    : 0;

  return (
    <Row>
      <Col xs={12} md={2}>
        <Text
          type="regular"
          color={label.indexOf('negociado') > 0 ? 'danger' : 'dark'}
          as="p"
          weight={ weight || 500}
        >
          {label}
        </Text>
      </Col>
      {hasPercentageValue ? (
        <Col xs={12} md={1}>
          <Text
            type="regular"
            color="gray"
            style={{ wordBreak: 'break-word' }}
            as="p"
            weight={ weight }
            title={percentageValueScale > 6 ? 'Valor aproximado' : ''}
            id={id && `label-${id}-porcentagem`}
          >
            {percentageValueScale >= 6 ? (
              <span style={{ cursor: 'help' }} title="Valor aproximado">
                &#8776;
              </span>
            ) : null}
            {` ${percentageValue}%`}
          </Text>
        </Col>
      ) : (
        <Col md={1} />
      )}
      <Col xs={12} md={3}>
        <Text
          weight={weight}
          type="regular"
          color={`${
            color ||
            (label === 'Frete negociado'
              ? 'danger'
              : label == '% adiantamento negociado'
              ? 'danger'
              : wasValueRemovedFromTotal
              ? 'red'
              : 'gray')
          }`}
          as="p"
          id={id && `label-${id}-real`}
        >
          {wasValueRemovedFromTotal && '-'}
          {isBRLCurrency
            ? formatCurrency(value, 'R$')
            : formatCurrency(convertValue(value), 'R$')}
        </Text>
      </Col>
      <Col xs={12} md={3}>
        <Text
          weight={ weight }
          type="regular"
          color={`${
            color ||
            (label === 'Frete negociado'
              ? 'danger'
              : label == '% adiantamento negociado'
              ? 'danger'
              : wasValueRemovedFromTotal
              ? 'red'
              : 'gray'
          )}`}
          as="p"
          id={id && `label-${id}-dolar`}
        >
          {wasValueRemovedFromTotal && '-'}
          {!isBRLCurrency
            ? formatCurrency(value, 'USD', '$', 4)
            : formatCurrency(convertValue(value), 'USD', '$', 4)}
        </Text>
      </Col>
      <Col
        xs={12}
        md={3}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {obs ? (
          <Text type="regular" color="gray" as="p">
            {obs}
          </Text>
        ) : null}
        {hasTooltip && (
          <Tooltip
            content={
              <Text type="label" color="white">
                {tooltipContent}
              </Text>
            }
          >
            <FaQuestionCircle style={{ color: theme.colors.light_blue }} />
          </Tooltip>
        )}
      </Col>
    </Row>
  );
}

export function ExtraItemDetailsRow({
  label,
  percentageValue = null,
  value,
  exchangeToUse,
  showToDriver = false,
  isBRLCurrency = true,
}) {
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, exchangeToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  return (
    <Row>
      <Col xs={12} md={2}>
        <Text type="regular" color="dark" as="p" weight={500}>
          {label}
        </Text>
      </Col>
      {percentageValue ? (
        <Col xs={12} md={1}>
          <Text type="regular" color="gray" as="p">
            {`${percentageValue}%`}
          </Text>
        </Col>
      ) : (
        <Col md={1} />
      )}
      <Col xs={12} md={3}>
        <Text type="regular" color="gray" as="p">
          {isBRLCurrency
            ? formatCurrency(value, 'R$')
            : formatCurrency(convertValue(value), 'R$')}
        </Text>
      </Col>
      <Col xs={12} md={3}>
        <Text type="regular" color="gray" as="p">
          {!isBRLCurrency
            ? formatCurrency(value, 'USD', '$', 4)
            : formatCurrency(convertValue(value), 'USD', '$', 4)}
        </Text>
      </Col>
      <Col xs={12} md={3}>
        <button
          disabled
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'default',
            background: 'transparent',
          }}
        >
          {showToDriver ? (
            <FaEye
              color={theme.colors.dark_blue}
              title="Motorista poderá visualizar este valor"
            />
          ) : (
            <FaEyeSlash
              color={theme.colors.dark_blue}
              title="Motorista não visualiza este valor"
            />
          )}
        </button>
      </Col>
    </Row>
  );
}

export function Observations({
  hasNegotiatorObs = false,
  load,
  negotiatorObs = '',
}) {
  return (
    <Row>
      <Col md={12}>
        <Text type="medium" weight={500}>
          Observações PCP
        </Text>
        <Text type="medium" color="dark" as="p" weight={500}>
          {load?.pcp_observation || 'Não informado'}
        </Text>
      </Col>
      {hasNegotiatorObs && (
        <Col md={12}>
          <Text type="medium" weight={500}>
            Observações Negociador
          </Text>
          <Text type="medium" color="dark" as="p" weight={500}>
            {negotiatorObs || 'Não informado'}
          </Text>
        </Col>
      )}
    </Row>
  );
}

import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import Card from 'components/Card';
import { Link } from 'v3/components';

const CardViagem = props => {
  function formatDate(date) {
    if (date === 'Invalid date') {
      return 'Não ocorreu';
    }
    return date;
  }

  const steps = {};
  const { logs } = props.travel;

  logs.forEach(log => {
    const status = log.obs?.split(' ');
    if (status) {
      const step = status[status.length - 1]
        .replace('.', '')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      steps[step] = {
        date: log.created_at,
        user: log.userCreated.username,
        user_type: log.userCreated.profile.name,
      };
    }
  });

  return (
    <div>
      <Card
        style={{ marginBottom: '10px' }}
        bordered
        borderhexcolor={props.status_color}
      >
        <Grid container>
          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong>ID Carga: </strong>
            </Typography>
            <Link
              to={`/cargas/${props.travel.load_id}`}
              target="_blank"
              withoutIcon
            >
              <Typography variant="body2" gutterBottom>
                {props.travel.load_id}
              </Typography>
            </Link>
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong>Número viagem: </strong>
            </Typography>
            <Link
              to={`/viagens/${props.travel.id}`}
              target="_blank"
              withoutIcon
            >
              <Typography variant="body2" gutterBottom>
                {props.travel.id}
              </Typography>
            </Link>
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong>ID Externo: </strong>
            </Typography>
            <Link
              to={`/viagens/${props.travel.id}`}
              target="_blank"
              withoutIcon
            >
              <Typography variant="body2" gutterBottom>
                {props.travel.travel_number ? props.travel.travel_number : 'Não informado'}
              </Typography>
            </Link>
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong> Tomador - CNPJ: </strong>
            </Typography>
            <Link
              to={`/clientes/${props.travel.load.client.id}`}
              target="_blank"
              withoutIcon
            >
              <Typography variant="body2" gutterBottom>
                {props.travel.load.client.social_name} - {props.travel.load.client.cgccpf}
              </Typography>
            </Link>
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong>Transportadora: </strong>
            </Typography>
            <Link
              to={`/transportadoras/${props.travel.shipping_company}`}
              target="_blank"
              withoutIcon
            >
              <Typography variant="body2" gutterBottom>
                {props.travel.shipper && props.travel.shipper.social_name}
              </Typography>
            </Link>
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong> Motorista: </strong>
            </Typography>
            <Link
              to={`/motoristas/${props.travel.driver_id}`}
              target="_blank"
              withoutIcon
            >
              <Typography variant="body2" gutterBottom>
                {props.travel.driver.social_name}
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <br />

        <Grid container>

        <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong> Ordem de coleta: </strong>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {formatDate(
                moment(props.travel.created_at).format('DD/MM/YYYY HH:mm')
              )}
            </Typography>
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong> Carregamento: </strong>
            </Typography>

            {steps.carregamento && (
              <>
                <Typography variant="body2" gutterBottom>
                  {formatDate(
                    moment(steps.carregamento.date).format('DD/MM/YYYY HH:mm')
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.carregamento.user}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.carregamento.user_type}
                </Typography>
              </>
            )}

            {!steps.carregamento && (
              <Typography variant="body2" gutterBottom>
                Não ocorreu
              </Typography>
            )}
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong> Emissão: </strong>
            </Typography>

            {steps.emissao && (
              <>
                <Typography variant="body2" gutterBottom>
                  {formatDate(
                    moment(steps.emissao.date).format('DD/MM/YYYY HH:mm')
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.emissao.user}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.emissao.user_type}
                </Typography>
              </>
            )}

            {!steps.emissao && (
              <Typography variant="body2" gutterBottom>
                Não ocorreu
              </Typography>
            )}
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong> Descarregamento: </strong>
            </Typography>

            {steps.descarregamento && (
              <>
                <Typography variant="body2" gutterBottom>
                  {formatDate(
                    moment(steps.descarregamento.date).format(
                      'DD/MM/YYYY HH:mm'
                    )
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.descarregamento.user}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.descarregamento.user_type}
                </Typography>
              </>
            )}

            {!steps.descarregamento && (
              <Typography variant="body2" gutterBottom>
                Não ocorreu
              </Typography>
            )}
          </Grid>

          <Grid sm={2}>
            <Typography variant="caption" component="p" gutterBottom>
              <strong> Em análise: </strong>
            </Typography>

            {steps.analise && (
              <>
                <Typography variant="body2" gutterBottom>
                  {formatDate(
                    moment(steps.analise.date).format('DD/MM/YYYY HH:mm')
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.analise.user}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {steps.analise.user_type}
                </Typography>
              </>
            )}

            {!steps.analise && (
              <Typography variant="body2" gutterBottom>
                Não ocorreu
              </Typography>
            )}
          </Grid>

          {steps.cancelado && (
            <Grid sm={2}>
              <Typography variant="caption" component="p" gutterBottom>
                <strong> Cancelado </strong>
              </Typography>
              <Typography variant="body2" gutterBottom>
                {formatDate(
                  moment(steps.cancelado.date).format('DD/MM/YYYY HH:mm')
                )}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {steps.cancelado.user}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {steps.cancelado.user_type}
              </Typography>
            </Grid>
          )}

          {!steps.cancelado && (
            <Grid sm={2}>
              <Typography variant="caption" component="p" gutterBottom>
                <strong> Finalizado: </strong>
              </Typography>

              {steps.finalizado && (
                <>
                  <Typography variant="body2" gutterBottom>
                    {formatDate(
                      moment(steps.finalizado.date).format('DD/MM/YYYY HH:mm')
                    )}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {steps.finalizado.user}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {steps.finalizado.user_type}
                  </Typography>
                </>
              )}

              {!steps.finalizado && (
                <Typography variant="body2" gutterBottom>
                  Não ocorreu
                </Typography>
              )}
            </Grid>
          )}

          {props.travel.att_user && (
            <>
              <Grid sm={2}>
                <Typography variant="caption" component="p" gutterBottom>
                  <strong> Documentos: </strong>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {formatDate(
                    moment(props.travel.att_created).format('DD/MM/YYYY HH:mm')
                  )}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {props.travel.att_user}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </div>
  );
};

export default CardViagem;

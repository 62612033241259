import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Card, Text } from 'v3/components';
import { PreFreightValues, SummaryPayingTransporterCard } from 'v3/pages/Load/Details/Values/FreightDetailsComponents';
import {
  CardHeaderComponent,
  ExtraFieldsRow,
  ExtraItemRow,
  FreightItemRow,
  HeaderTotalSummary,
  LabelsRow,
  SummaryRow,
  ValueDetailsRow,
} from 'v3/pages/Load/Register/PriceData/components';
import { makeConversion } from 'v3/utils/formatter';
import { percentage } from 'utils/number';
import { ExtraFieldsContainer } from 'v3/pages/Load/Register/PriceData/styles';
import { useModal } from 'hooks';
import { differenceInHours } from 'date-fns';
import { ceilDecimals } from 'v3/utils/functions';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import LoadExtraFieldsTravelEditing from '../../shared/Prices/loadExtraFieldsModal';
import { LoadShipperTransferCardEditing } from '../../shared/Prices/LoadFreight';
import { TravelContext } from '../controller';

export function LoadFreightValues() {
  const [, useTravel] = TravelContext;
  const travel = useTravel();
  const [discountsCardToggle, setDiscountsCardToggle] = useState(true);
  const [transferCardToggle, setTransferCardToggle] = useState(true);
  function onChangeNegotiatorObs(value) {
    travel.setData(old => ({
      ...old,
      negotiator_observation: value,
    }));
  }
  function onChangeAdvanceMoneyPercentage(event, value) {
    if (value?.length <= 6) {
      let advanceMoney = 0;
      if (travel.data?.balance) {
        advanceMoney = percentage(Number(value), Number(travel.data.balance));
      }
      travel.setData(old => ({
        ...old,
        advanceMoney,
        advance_money_percentage: value,
      }));
    }
  }
  function onChangeAdvanceMoneyValue(event, value) {
    if (value?.length <= 10) {
      const advancePercentage =
        (Number(value) / Number(travel?.data?.balance)) * 100;
      travel.setData(old => ({
        ...old,
        advance_money_percentage: advancePercentage,
        advanceMoney: value,
      }));
    }
  }
  function onChangeBalance(event, value) {
    if (value?.length <= 10) {
      let advanceMoney = 0;
      const advancePercentage = Number(value)
        ? travel?.data?.advance_money_percentage
        : 0;
      let newMargin = 0;
      if (travel?.data?.advance_money_percentage) {
        advanceMoney = percentage(
          travel?.data?.advance_money_percentage,
          value
        );
      }
      if (travel?.data?.editableFareCompany) {
        newMargin = ceilDecimals(
          ((Number(travel?.data?.editableFareCompany) - Number(value)) /
            Number(travel?.data?.editableFareCompany)) *
            100,
          2
        );
      }
      travel.setData(old => ({
        ...old,
        balance: value,
        advanceMoney: Number.isNaN(advanceMoney) ? 0 : advanceMoney,
        advance_money_percentage: advancePercentage,
        margin: newMargin,
      }));
    }
  }
  function onChangeIssueRate(event, value) {
    travel.setData(old => ({
      ...old,
      issueRate: value,
    }));
  }
  function onSaveShipmentDiscounts(shipmentDiscounts) {
    travel.setData(old => ({
      ...old,
      shipmentDiscounts: [...shipmentDiscounts],
    }));
  }
  function onSaveShipmentTransfer(shipmentTransfer) {
    travel.setData(old => ({
      ...old,
      shipmentTransfer: [...shipmentTransfer],
    }));
  }
  function onChangeDiscountsTotal(total) {
    travel.setData(old => ({
      ...old,
      total_shipment_discounts: +total,
    }));
  }
  function onChangeTransferTotal(total) {
    travel.setData(old => ({
      ...old,
      total_shipment_transfer: +total,
    }));
  }

  function isWithin24HourInterval(vehicleCreateDate) {
    const isWithinInterval = differenceInHours(
      new Date(),
      new Date(vehicleCreateDate)
    );
    return isWithinInterval <= 24;
  }
  function getIssueRateValue(
    shippingDescriptionServiceLevel,
    loadCurrency,
    dollarToUse,
    wasVehicleRegisteredWithin24Hours
  ) {
    const serviceLevels = {
      Terceiro: 65,
      Agregado: 40,
      'Agregado Ouro': 0,
    };
    const valueAccordingToServiceLevel =
      serviceLevels[shippingDescriptionServiceLevel];
    if (loadCurrency === 'BRL') {
      return wasVehicleRegisteredWithin24Hours
        ? 65
        : valueAccordingToServiceLevel !== undefined
        ? valueAccordingToServiceLevel
        : 65;
    }
    if (loadCurrency === 'USD') {
      return wasVehicleRegisteredWithin24Hours
        ? +makeConversion(65.0, '/', dollarToUse)?.toFixed(4)
        : valueAccordingToServiceLevel !== undefined
        ? +makeConversion(
            valueAccordingToServiceLevel,
            '/',
            dollarToUse
          )?.toFixed(4)
        : +makeConversion(65.0, '/', dollarToUse)?.toFixed(4);
    }
    return 65;
  }

  useEffect(() => {
    let issueRate = '65.00';
    let updatedFareCompany = travel?.data?.fare_company;
    let updatedTotalInputs = travel?.data?.total_inputs;
    if (
      travel.data?.shippingCompany?.shippingCompanies &&
      travel.data?.vehicle?.main
    ) {
      const checkDateInterval = isWithin24HourInterval(
        travel.data.vehicle?.main?.created_at
      );
      const { description_service_level } =
        travel.data?.shippingCompany?.shippingCompanies;

      const isGoldAggregatedLevel =
        travel.data?.shippingCompany?.shippingCompanies?.description_service_level?.toLowerCase() ===
        'agregado ouro';

      if (isGoldAggregatedLevel) {
        updatedTotalInputs =
          Number(travel?.data?.total_inputs) - Number(travel?.data?.ad_valorem);
        updatedFareCompany =
          Number(travel?.data?.total_taker_value) - updatedTotalInputs;
      }

      issueRate = getIssueRateValue(
        description_service_level,
        travel?.data?.freight_values_currency,
        travel?.data?.freight_values_exchange,
        checkDateInterval
      );

      travel.setData(old => ({
        ...old,
        issueRate,
        editableFareCompany: updatedFareCompany,
        editableTotalInputs: updatedTotalInputs,
      }));
    }
    travel.setData(old => ({
      ...old,
      issueRate,
      editableFareCompany: updatedFareCompany,
      editableTotalInputs: updatedTotalInputs,
    }));
  }, [travel?.data?.vehicle?.main, travel?.data?.shippingCompany]);

  const isVehicleGoldAggregated =
    travel?.data?.shippingCompany?.shippingCompanies?.description_service_level?.toLowerCase() ===
    'agregado ouro';

  useEffect(() => {
    if (travel?.data?.editableFareCompany && travel?.data?.balance) {
      const newMargin = ceilDecimals(
        ((Number(travel?.data?.editableFareCompany) -
          Number(travel?.data?.balance)) /
          Number(travel?.data?.editableFareCompany)) *
          100,
        2
      );
      travel.setData(old => ({
        ...old,
        margin: newMargin,
      }));
    }
  }, [travel?.data?.editableFareCompany]);

  return (
    <Col xs={12}>
      <Card
        header={
          <Text color="#464E5F" type="header">
            Resumo de valores de frete
          </Text>
        }
      >
        <PreFreightValues
          load={{
            freightExchange: travel?.data?.freight_values_exchange,
            exchangeDay: travel?.data?.exchange_day,
            currency: travel?.data?.freight_values_currency,
            loadValueType: travel?.data?.load_value_type,
            negotiationType: travel?.data?.negotiation_type,
          }}
        />
        <Row>
          <NegotiatedValuesRegister
            isBRLCurrency={travel?.data?.isBRLCurrency}
            dollarToUse={travel?.data?.freight_values_exchange}
            travel={travel}
            onChangeAdvanceMoneyPercentage={onChangeAdvanceMoneyPercentage}
            onChangeAdvanceMoneyValue={onChangeAdvanceMoneyValue}
            onChangeBalance={onChangeBalance}
          />
        </Row>
        <Row>
          <LoadShipperDiscountsCard
            onChangeIssueRate={onChangeIssueRate}
            cardToggle={discountsCardToggle}
            setCardToggle={setDiscountsCardToggle}
            dollarToUse={travel?.data?.freight_values_exchange}
            isBRLCurrency={travel?.data?.isBRLCurrency}
            totalComputed={travel?.data?.total_shipment_discounts}
            onChangeTotal={onChangeDiscountsTotal}
            load={{
              ferry: travel?.data?.ferry,
              ferryObservation: travel?.data?.ferryObservation,
            }}
            shipperDiscountsExtraFields={travel?.data?.shipmentDiscounts?.map(
              item => {
                return { ...item, value: Number(item?.value)?.toFixed(2) };
              }
            )}
            setShipperDiscountsExtraFields={onSaveShipmentDiscounts}
            issueRate={travel?.data?.issueRate}
          />
        </Row>
        <Row>
          <LoadShipperTransferCardEditing
            isVehicleGoldAggregated={isVehicleGoldAggregated}
            cardToggle={transferCardToggle}
            setCardToggle={setTransferCardToggle}
            dollarToUse={travel?.data?.freight_values_exchange}
            errors={travel?.errors}
            isBRLCurrency={travel?.data?.isBRLCurrency}
            negotiatorObs={travel?.data?.negotiator_observation}
            setNegotiatorObs={onChangeNegotiatorObs}
            shipperTransferExtraFields={travel?.data?.shipmentTransfer?.map(
              item => {
                return { ...item, value: Number(item?.value)?.toFixed(2) };
              }
            )}
            setShipperTransferExtraFields={onSaveShipmentTransfer}
            totalComputed={travel?.data?.total_shipment_transfer}
            setTotalShipperTransfer={onChangeTransferTotal}
            hasObservationFields
            load={{
              tollObservation: travel?.data?.tollObservation,
              tollValue: travel?.data?.toll_value,
              dischargeObservation: travel?.data?.dischargeObservation,
              dischargeValue: travel?.data?.discharge_value,
              grissAdObservation: travel?.data?.grissAdObservation,
              gris: travel?.data?.gris,
              adValorem: travel?.data?.ad_valorem,
              chargeObservation: travel?.data?.chargeObservation,
              chargeValue: travel?.data?.charge_value,
              ferry: travel?.data?.ferry,
              ferryObservation: travel?.data?.ferryObservation,
              pcpObservation: travel?.data?.pcp_observation,
              cargoValue: travel?.data?.cargo_value,
            }}
          />
        </Row>
        <Row>
          <SummaryPayingTransporterCard
            cardTitle="Resumo a pagar ao transportador"
            isBRLCurrency={travel?.data?.isBRLCurrency}
            dollarToUse={travel?.data?.freight_values_exchange}
            advanceMoneyPercentage = {Number(travel?.data?.advance_money_percentage)}
            balanceValue={travel?.data?.balance}
            totalShipmentTransfer={travel?.data?.total_shipment_transfer}
            totalShipmentDiscounts={travel?.data?.total_shipment_discounts}
          />
        </Row>
      </Card>
    </Col>
  );
}

function NegotiatedValuesRegister({
  isBRLCurrency,
  dollarToUse,
  onChangeAdvanceMoneyPercentage = () => {},
  onChangeAdvanceMoneyValue = () => {},
  onChangeBalance = () => {},
  travel,
}) {
  const { margins } = useContext(BlockedTravelContext);
  const [negotiatedCardToggle, setNegotiatedCardToggle] = useState(true);
  const dontNeedFreightValue =
    travel?.data?.vehicle?.main?.fleetType?.name === 'Próprio';

  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }
  useEffect(() => {
    if (travel?.errors?.balance || travel?.errors?.advance_money_percentage) {
      setNegotiatedCardToggle(true);
    }
  }, [travel?.errors]);
  return (
    <Col xs={12}>
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Valores negociados"
          isOpen={negotiatedCardToggle}
          setIsOpen={() => setNegotiatedCardToggle(!negotiatedCardToggle)}
          tooltipContent="Valores negociados com transportador e
cliente a partir dos valores principais"
          totalsSection={
            <HeaderTotalSummary
              label="Frete tirar margem"
              totalInReal={
                isBRLCurrency
                  ? travel?.data?.editableFareCompany
                  : convertValue(travel?.data?.editableFareCompany)
              }
              totalInDollar={
                isBRLCurrency
                  ? convertValue(travel?.data?.editableFareCompany)
                  : travel?.data?.editableFareCompany
              }
            />
          }
        />
        {negotiatedCardToggle && (
          <>
            <LabelsRow />
            <ValueDetailsRow
              label="% máxima de adiantamento no ERP"
              value={
                percentage(
                  margins?.advance_money_percentage_external ?? 0,
                  travel?.data?.balance ?? 0
                ) || 0
              }
              exchangeToUse={dollarToUse}
              percentageValue={
                margins?.advance_money_percentage_external || '0'
              }
              isBRLCurrency={isBRLCurrency}
              id="maxima-adiantamento-erp"
            />
            <FreightItemRow
              label="% adiantamento negociado"
              percentageValue={travel?.data?.advance_money_percentage || '0'}
              percentageValueReference={travel?.data?.balance}
              onChangePercentageValue={onChangeAdvanceMoneyPercentage}
              onChangeValue={onChangeAdvanceMoneyValue}
              value={travel?.data?.advanceMoney || '0.00'}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              hasValidation
              showCalculator
              errorField={travel?.errors?.advance_money_percentage}
              id="adiantamento-negociado"
            />
            <ValueDetailsRow
              label="Frete tirar margem"
              value={travel?.data?.editableFareCompany || 0}
              exchangeToUse={dollarToUse}
              isBRLCurrency={isBRLCurrency}
              id="frete-tirar-margem"
            />
            <FreightItemRow
              label={`Frete negociado${dontNeedFreightValue ? '' : ' *'}`}
              onChangeValue={onChangeBalance}
              value={travel?.data?.balance || '0.00'}
              isBRLCurrency={isBRLCurrency}
              dollarToUse={dollarToUse}
              isRequired
              errorField={travel?.errors?.balance}
              showCalculator
              id="frete-negociado"
            />
            <ValueDetailsRow
              label="Margem ERP"
              value={
                percentage(
                  margins?.benner_margin ?? 0,
                  travel?.data?.editableFareCompany ?? 0
                ) || 0
              }
              exchangeToUse={dollarToUse}
              percentageValue={margins?.benner_margin || '0'}
              isBRLCurrency={isBRLCurrency}
              id="margem-erp"
            />
            <ValueDetailsRow
              label="Margem Viagem"
              value={
                Number(travel?.data?.editableFareCompany) -
                  Number(travel?.data?.balance) || 0
              }
              exchangeToUse={dollarToUse}
              percentageValue={travel?.data?.margin || '0'}
              isBRLCurrency={isBRLCurrency}
              id="margem-viagem"
            />
          </>
        )}
      </Card>
    </Col>
  );
}

function LoadShipperDiscountsCard({
  onChangeIssueRate,
  cardToggle,
  setCardToggle,
  dollarToUse,
  isBRLCurrency,
  totalComputed,
  onChangeTotal,
  load,
  shipperDiscountsExtraFields = [],
  setShipperDiscountsExtraFields,
  issueRate,
}) {
  const shipperDiscountsExtraFieldsModal = useModal();
  function convertValue(rawValue) {
    const operation = isBRLCurrency ? '/' : '*';
    const processedValue = +makeConversion(rawValue, operation, dollarToUse);
    return operation === '/'
      ? processedValue.toFixed(4)
      : processedValue.toFixed(2);
  }

  const totalDiscountsMemo = useMemo(() => {
    let total = 0;
    if (shipperDiscountsExtraFields?.length) {
      total = shipperDiscountsExtraFields.reduce((acc, curr) => {
        if (curr?.delete) {
          return acc;
        }
        return acc + Number(curr?.value);
      }, 0);
    }

    if (issueRate) {
      total += Number(issueRate);
    }

    return total;
  }, [shipperDiscountsExtraFields, load?.ferry, issueRate]);
  useEffect(() => {
    onChangeTotal(totalDiscountsMemo);
  }, [totalDiscountsMemo]);
  const tagsContent = useMemo(() => {
    let tags = '';
    if (Number(issueRate) > 0) {
      tags += 'Taxa de emissão,';
    }
    if (
      shipperDiscountsExtraFields?.filter(item => item?.delete !== true)
        ?.length > 0
    ) {
      tags += 'Valores extras,';
    }
    return tags;
  }, [load]);

  return (
    <Col xs={12}>
      <LoadExtraFieldsTravelEditing
        extraFields={shipperDiscountsExtraFields}
        isOpen={shipperDiscountsExtraFieldsModal.isOpen}
        onClose={shipperDiscountsExtraFieldsModal.close}
        setExtraFields={setShipperDiscountsExtraFields}
        loadCurrency={isBRLCurrency ? 'R$' : '$'}
        isBRLCurrency={isBRLCurrency}
        dollarToUse={dollarToUse}
        id="descontos-transportador"
      />
      <Card containerCardBoxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)">
        <CardHeaderComponent
          label="Descontos ao Transportador"
          tooltipContent="Se caracteriza todo desconto que será feito depois de tirar a margem (Taxa administrativa)."
          isOpen={cardToggle}
          setIsOpen={() => setCardToggle(!cardToggle)}
          totalsSection={
            <HeaderTotalSummary
              label="Valor descontos"
              totalInReal={
                isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
              totalInDollar={
                !isBRLCurrency ? totalComputed : convertValue(totalComputed)
              }
            />
          }
          hasTagsRow
          tagsContent={tagsContent}
        />
        {cardToggle && (
          <>
            <FreightItemRow
              label="Taxa de emissão"
              hasTooltip
              tooltipContent="Valor da taxa para emissão dos documentos, é preenchido automaticamente conforme nível de associado"
              onChangeValue={onChangeIssueRate}
              value={issueRate || '0'}
              isBRLCurrency={isBRLCurrency}
              showCalculator
              dollarToUse={dollarToUse}
              id="taxa-emissao"
            />
            <>
              {shipperDiscountsExtraFields?.filter(item => item.delete !== true)
                ?.length > 0 ? (
                <Text type="regular" weight={500}>
                  Valores extras
                </Text>
              ) : null}
              <ExtraFieldsContainer>
                {shipperDiscountsExtraFields?.map((item, index) => {
                  if (item?.delete) {
                  } else
                    return (
                      <ExtraItemRow
                        key={`${index}_${new Date().getTime()}`}
                        label={item?.label}
                        value={item?.value}
                        dollarToUse={dollarToUse}
                        isBRLCurrency={isBRLCurrency}
                        onSetExtraFields={() =>
                          setShipperDiscountsExtraFields(
                            shipperDiscountsExtraFields?.map((item, idx) => {
                              if (idx === index) {
                                return {
                                  ...item,
                                  delete: true,
                                };
                              }
                              return item;
                            })
                          )
                        }
                        id={`campo-extra-desconto-transportador-${index}`}
                        showToDriver={item?.show_to_driver}
                      />
                    );
                })}
              </ExtraFieldsContainer>
            </>
            <SummaryRow
              label="Valor descontos"
              totalInBRL={
                isBRLCurrency
                  ? totalDiscountsMemo
                  : convertValue(totalDiscountsMemo)
              }
              totalInUSD={
                !isBRLCurrency
                  ? totalDiscountsMemo
                  : convertValue(totalDiscountsMemo)
              }
              id="valor-descontos"
            />
            <ExtraFieldsRow
              id="button-gerenciar-valores-extras-valor-descontos"
              openModal={() => shipperDiscountsExtraFieldsModal.open()}
            />
          </>
        )}
      </Card>
    </Col>
  );
}

/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import moment from 'moment';
import { validatePermission } from 'actions/index';
import {
  Button,
  Text,
  Card,
  Input,
  Select,
  Badge,
  Modal,
  Map,
} from 'v3/components';
import InputAddress from 'v3/components/InputAddress';
import List from 'v3/components/BulletList';
import MarkerLetter from 'v3/components/Map/MarkerLetter';
import { useSnackbar } from 'v3/components/Snackbar';
import { formatAddress } from 'v3/utils/formatter';
import { Row, Col } from 'react-bootstrap';
import { FaRegCalendar, FaTrash, FaPencilAlt } from 'react-icons/fa';
import theme from 'v3/theme';
import api, { cooperplaceApi } from 'services/api';
import { BlockedTravelContext } from 'contexts/BlockedTravelProvider';
import { useModal } from 'hooks';
import { UpdateClientAddressData } from 'v3/pages/Client/shared/Modals/UpdateAddressData';
import { checkClientAddressData } from 'v3/pages/PreLoad/functions';
import {
  ConflictDriverModal,
  MinimumMarginModal,
} from 'v3/pages/Travel/shared';
import * as Styled from './styles';
import * as Controller from './controller';
import { fetchCountries } from '../controller';
import { fetchSelectOptions } from '../../Register/controller';

const actionOptions = [
  { name: 'Coleta', id: 'collect' },
  { name: 'Entrega', id: 'delivery' },
  { name: 'Aduaneiro', id: 'customhouse' },
];

export default function Locale({
  originsData,
  destinationsData,
  loadId,
  loadingCard,
  isAttended,
  getLoad,
  groLoadData = null,
  isDisabledGROAnalysis,
  load,
}) {
  const { isTravelBlocked, isMarginAbove } = useContext(BlockedTravelContext);
  const snackbar = useSnackbar();
  const mapsRef = useRef(null);
  const mapRef = useRef(null);
  const initial_data = {
    country: {},
    address: '',
    formattedAddress: '',
    neighborhood: '',
    city: '',
    province: '',
    number: '',
    zipCode: '',
    complement: '',
    lat: '',
    lng: '',
    scheduledTime: '',
    startSchedule: '',
    loadId,
    type: 'destination',
    countrySlug: '',
    action: '',
  };

  const [origins, setOrigins] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [location, setLocation] = useState(initial_data);
  const [errors, setErrors] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [show, setShow] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [disable, setDisable] = useState(false);
  const [hasPermissionToEdit, setPermissionToEdit] = useState();

  const [disableNumber, setDisableNumber] = useState(false);
  const [disableZipCode, setDisableZipCode] = useState(false);
  const [disableCity, setDisableCity] = useState(false);
  const [disableProvince, setDisableProvince] = useState(false);
  const [disableNeighborhood, setDisableNeighborhood] = useState(false);
  const [confirmModalValidator, setConfirmModalValidator] = useState({
    confirmMinimiumMargin: false,
    passDriverValidation: false,
  });
  const [validateDriverProps, setValidateDriverProps] = useState({
    conflictTravels: [],
    driver: {},
  });

  const updateClientAddressModal = useModal();
  const [clientCurrentAddress, setClientCurrentAddress] = useState(null);
  const [client, setClient] = useState(null);
  const lowerMarginModal = useModal();
  const driverAvailableModal = useModal();

  const locations = useMemo(
    () => [
      ...origins.map(origin => ({
        latitude: origin.lat,
        longitude: origin.lng,
      })),
      ...destinations.map(destination => ({
        latitude: destination.lat,
        longitude: destination.lng,
      })),
    ],
    [destinations, origins]
  );

  const getMapBounds = () => {
    const maps = mapsRef.current;
    if (maps) {
      const bounds = new maps.LatLngBounds();
      if (locations[0].latitude && locations[0].longitude) {
        locations.forEach(item => {
          bounds.extend(new maps.LatLng(item.latitude, item.longitude));
        });
      }

      return bounds;
    }

    return null;
  };

  useState(() => {
    setPermissionToEdit(validatePermission('EDITAR_CARGAS'));
    fetchCountries(setCountryList);
  }, []);

  useEffect(() => {
    setDisable(loadingSave || loadingDelete || isAttended !== null);
  }, [loadingSave, loadingDelete]);

  useEffect(() => {
    const bounds = getMapBounds();
    if (mapRef.current) {
      mapRef.current.fitBounds(bounds);
    }
  }, [destinations, origins]);

  const ordered = useMemo(() => {
    return {
      origins: origins.sort(
        (a, b) =>
          moment(a.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
      destinations: destinations.sort(
        (a, b) =>
          moment(a.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf() -
          moment(b.scheduledTime, 'DD/MM/YYYY HH:mm').toDate().valueOf()
      ),
    };
  }, [origins, destinations]);

  function getDestinations() {
    if (destinationsData) {
      const formatted_destinations = destinationsData.map(destination => ({
        ...destination,
        country: countryList.find(
          element => element.abbreviation === destination.countrySlug
        ),
        zipCode: destination.zipCode || '',
        number: destination.number || '',
        neighborhood: destination.neighborhood || '',
        complement: destination.complement || '',
        action: actionOptions.find(
          ({ id }) => id === destination.action.toLowerCase()
        ),
        scheduledTime: destination.scheduledTime
          ? moment(destination.scheduledTime).format('DD/MM/YYYY HH:mm')
          : '',
        startSchedule: destination.startSchedule
          ? moment(destination.startSchedule).format('DD/MM/YYYY HH:mm')
          : '',
        type: 'destination',
      }));

      setDestinations(formatted_destinations);
      disableAddress(location.place);
    }
  }
  useEffect(() => getDestinations(), [destinationsData]);

  function getOrigins() {
    if (originsData) {
      const formatted_origins = originsData.map(origin => ({
        ...origin,
        country: countryList.find(
          element => element.abbreviation === origin.countrySlug
        ),
        zipCode: origin.zipCode || '',
        number: origin.number || '',
        neighborhood: origin.neighborhood || '',
        complement: origin.complement || '',
        action: actionOptions.find(({ id }) => id === 'collect'),
        scheduledTime: origin.scheduledTime
          ? moment(origin.scheduledTime).format('DD/MM/YYYY HH:mm')
          : '',
        formattedAddress: origin.formatted,
        startSchedule: origin.startSchedule
          ? moment(origin.startSchedule).format('DD/MM/YYYY HH:mm')
          : '',
        type: 'origin',
      }));

      setOrigins(formatted_origins);
    }
  }
  useEffect(() => getOrigins(), [originsData]);

  function disableAddress(address, city) {
    if (disable) {
      setDisableNumber(true);
      setDisableZipCode(true);
      setDisableCity(true);
      setDisableProvince(true);
      setDisableNeighborhood(true);
    } else {
      setDisableNumber(!!address?.number);
      setDisableZipCode(address?.zip_code ? true : !!address?.zipCode);
      setDisableCity(!!city);
      setDisableProvince(!!city?.province);
      setDisableNeighborhood(!!address?.neighborhood);
    }
  }

  function handleChange(value, key) {
    setLocation({ ...location, [key]: value });
  }

  async function fetchProvinces(search, uf, country) {
    try {
      const response = await api.get('cities', {
        params: { search, uf, country },
      });
      return response.data;
    } catch (ex) {
      return [];
    }
  }

  function setDestinationCity(city, options) {
    let cityModel = city;
    if (options.action === 'create-option') {
      cityModel = {
        name: city.id,
        province: null,
      };
    }

    if (cityModel) {
      setLocation({
        ...location,
        cityModel,
        province: cityModel?.province?.uf,
      });
    } else {
      setLocation({
        ...location,
        cityModel: null,
        province: '',
      });
    }
  }

  async function handleLocationAddress(place) {
    const { address } = place;

    const [city] = await fetchProvinces(
      place.city,
      place.province,
      place.country_abbreviation
    );
    setLocation({
      ...location,
      address: address || '',
      formattedAddress: place.formatted_address,
      neighborhood: place.neighborhood || '',
      cityModel: city,
      province: city?.province?.uf || '',
      number: place.number || '',
      complement: place.name || '',
      zipCode: place.zip_code || '',
      lat: place.lat,
      lng: place.lng,
    });
    disableAddress(place, city);
  }

  function handleEditLocation(selected_location) {
    const editableTravelStatus = [2, 8, 9, 11].includes(isAttended?.statusId);

    setShow(false);
    setLocation({
      ...selected_location,
      cityModel: selected_location.cityModel || {
        name: selected_location.city,
      },
    });
    if (isAttended === null || editableTravelStatus) {
      setDisable(false);
      disableAddress(selected_location);
    }
  }

  function warning() {
    const scheduled_time = moment(location.scheduledTime, 'DD/MM/YYYY HH:mm');

    if (scheduled_time.isBefore(moment())) {
      snackbar.show(
        <Text>
          Esta carga não aparecerá no aplicativo pois a hora da coleta já
          passou.
        </Text>,
        { type: 'error', duration: 8000 }
      );
    }
  }

  function handleRemoveDestination(destination) {
    if (destinations.length < 2) {
      snackbar.show(<Text>Carga deve ter pelo menos um destino</Text>, {
        type: 'error',
      });
      return;
    }

    setLoadingDelete(true);
    Controller.deleteDestination(destination.id)
      .then(() => {
        setDestinations(list =>
          list.filter(item => destination.id !== item.id)
        );
      })
      .catch(() => {
        snackbar.show(<Text>Não foi possível excluir o destino</Text>, {
          type: 'error',
        });
      })
      .finally(() => {
        setLoadingDelete(false);
      });
  }

  function onGoogleAPILoaded(map, maps) {
    if (map) {
      mapRef.current = map;
      mapsRef.current = maps;

      setTimeout(() => {
        const bounds = getMapBounds();

        map.fitBounds(bounds);
      }, 100);
    }
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function validateDriver({
    originScheduledTime,
    destinationScheduledTime,
    driverId,
    travelId,
  }) {
    const validation = {
      travels: [],
      isOnAnotherTravel: false,
    };

    const response = await cooperplaceApi.get('travels/validateDriver', {
      params: {
        originScheduledTime,
        destinationScheduledTime,
        driverId,
        travelId,
      },
    });

    const { travels } = response.data;
    validation.travels = travels;
    validation.isOnAnotherTravel = !!travels.length;
    return validation;
  }

  async function handleSaveLocation({
    confirmMinimiumMargin = confirmModalValidator.confirmMinimiumMargin,
    passDriverValidation = confirmModalValidator.passDriverValidation,
  } = {}) {
    const validate = Controller.validate(location, origins, destinations);

    if (validate.hasError) {
      setErrors(validate);
      return;
    }

    const isOrigin = location.id === origins[0]?.id;
    const lastDestination = destinations[destinations.length - 1];
    const isLastDestination = location.id === lastDestination.id;

    setLoadingSave(true);
    if (groLoadData !== null) {
      try {
        await cooperplaceApi.post(
          `policies/${groLoadData.policyId}/validator`,
          {
            type: ['route'],
            operationId: groLoadData?.operationId,
            load: {
              productId: groLoadData?.productId,
              loadOrigins:
                location?.type === 'origin'
                  ? { cityId: location?.cityModel?.id }
                  : { cityId: originsData[0]?.cityModel?.id },
              loadDestinations:
                location?.type === 'destination'
                  ? [{ cityId: location?.cityModel?.id }]
                  : [
                      {
                        cityId:
                          destinations[destinations?.length - 1]?.cityModel?.id,
                      },
                    ],
            },
          }
        );
      } catch (error) {
        const message = error?.response?.data?.error || 'Erro ao validar rota!';
        snackbar.show(
          <Text type="body" weight={500}>
            {message}
          </Text>,
          {
            type: 'error',
          }
        );
        setLocation(initial_data);
        setLoadingSave(false);
        return;
      }
    }

    try {
      if (load?.attended?.driver_id && !passDriverValidation) {
        const validateDriverParams = {
          originScheduledTime: isOrigin
            ? moment(location.start_schedule, 'DD/MM/YYYY HH:mm').toISOString()
            : moment(
                origins[0].start_schedule,
                'DD/MM/YYYY HH:mm'
              ).toISOString(),
          destinationScheduledTime: isLastDestination
            ? moment(location.scheduled_time, 'DD/MM/YYYY HH:mm').toISOString()
            : moment(
                lastDestination.scheduled_time,
                'DD/MM/YYYY HH:mm'
              ).toISOString(),
          driverId: load?.attended?.driver_id,
          travelId: load?.attended?.id,
        };

        const { isOnAnotherTravel, travels } = await validateDriver(
          validateDriverParams
        );

        if (isOnAnotherTravel) {
          if (travels.length >= 2) {
            snackbar.show(
              <Text>
                {`O motorista está vinculado nas seguintes viagens nesse período: "${travels.join(
                  ', '
                )}"`}
              </Text>,
              {
                type: 'error',
              }
            );
            setLoadingSave(false);
            return;
          }

          if (travels.length === 1 && !passDriverValidation) {
            setValidateDriverProps(old => ({
              ...old,
              conflictTravels: travels,
              driver: load?.attended?.driver,
            }));
            driverAvailableModal.open();
            setLoadingSave(false);
            return;
          }
        }
      }

      if (!confirmMinimiumMargin && load?.attended?.id) {
        const addressParams = {
          origin: isOrigin
            ? {
                city_id: location.cityModel?.id,
                city: location?.cityModel?.name,
                province: location?.cityModel?.province?.uf,
                country_slug: location?.country.abbreviation,
              }
            : origins[0],
          destinations: isLastDestination
            ? [
                ...destinations.slice(0, -1),
                {
                  city_id: location?.cityModel?.id,
                  city: location?.cityModel?.name,
                  province: location?.cityModel?.province?.uf,
                  country_slug: location?.country.abbreviation,
                },
              ]
            : destinations,
        };

        const isMarginERPAbove = await isMarginAbove(addressParams);

        if (isMarginERPAbove) {
          lowerMarginModal.open();
          setLoadingSave(false);
          return;
        }
      }
    } catch (error) {
      setLoadingSave(false);
      snackbar.show(
        <Text>Erro ao validar motorista e previsão de margem</Text>,
        {
          type: 'error',
        }
      );
      return;
    }

    try {
      if (load?.attended?.driver_id && !passDriverValidation) {
        const validateDriverParams = {
          originScheduledTime: isOrigin
            ? moment(location.start_schedule, 'DD/MM/YYYY HH:mm').toISOString()
            : moment(
                origins[0].start_schedule,
                'DD/MM/YYYY HH:mm'
              ).toISOString(),
          destinationScheduledTime: isLastDestination
            ? moment(location.scheduled_time, 'DD/MM/YYYY HH:mm').toISOString()
            : moment(
                lastDestination.scheduled_time,
                'DD/MM/YYYY HH:mm'
              ).toISOString(),
          driverId: load?.attended?.driver_id,
          travelId: load?.attended?.id,
        };

        const { isOnAnotherTravel, travels } = await validateDriver(
          validateDriverParams
        );

        if (isOnAnotherTravel) {
          if (travels.length >= 2) {
            snackbar.show(
              <Text>
                {`O motorista está vinculado nas seguintes viagens nesse período: "${travels.join(
                  ', '
                )}"`}
              </Text>,
              {
                type: 'error',
              }
            );
            setLoadingSave(false);
            return;
          }

          if (travels.length === 1 && !passDriverValidation) {
            setValidateDriverProps(old => ({
              ...old,
              conflictTravels: travels,
              driver: load?.attended?.driver,
            }));
            driverAvailableModal.open();
            setLoadingSave(false);
            return;
          }
        }
      }

      if (!confirmMinimiumMargin && load?.attended?.id) {
        const addressParams = {
          origin: isOrigin
            ? {
                city_id: location.cityModel?.id,
                city: location?.cityModel?.name,
                province: location?.cityModel?.province?.uf,
                country_slug: location?.country.abbreviation,
              }
            : origins[0],
          destinations: isLastDestination
            ? [
                ...destinations.slice(0, -1),
                {
                  city_id: location?.cityModel?.id,
                  city: location?.cityModel?.name,
                  province: location?.cityModel?.province?.uf,
                  country_slug: location?.country.abbreviation,
                },
              ]
            : destinations,
        };

        const isMarginERPAbove = await isMarginAbove(addressParams);

        if (isMarginERPAbove) {
          lowerMarginModal.open();
          setLoadingSave(false);
          return;
        }
      }
    } catch (error) {
      setLoadingSave(false);
      snackbar.show(
        <Text>Erro ao validar motorista e previsão de margem</Text>,
        {
          type: 'error',
        }
      );
      return;
    }

    const result = await Controller.save(location);

    setLoadingSave(false);
    setConfirmModalValidator({
      confirmMinimiumMargin: false,
      passDriverValidation: false,
    });

    if (result.success) {
      setErrors({});
      snackbar.show(<Text>{result.success}</Text>, {
        type: 'success',
      });

      if (location.type === 'origin') {
        const edited_origins = origins.map(item =>
          location.id === item.id ? { ...item, ...location } : item
        );

        warning();

        setOrigins(edited_origins);
      } else if (!location.id) {
        destinations.push({ ...location, id: result.data.id });
        setDestinations(destinations);
      } else {
        const edited_destinations = destinations.map(item =>
          location.id === item.id ? { ...item, ...location } : item
        );

        setDestinations(edited_destinations);
      }

      setLocation(initial_data);
      setDisable(true);
      await getLoad();
    } else {
      snackbar.show(<Text>{result.error}</Text>, {
        type: 'error',
      });
    }
  }

  function locationsObject(showAllDestinations = true) {
    const destinationsToDisplay = showAllDestinations
      ? ordered.destinations
      : ordered.destinations.slice(-1);

    const items = [
      ...ordered.origins.map((item, index) => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
            <div className="mb-1">
              <Button
                disabled={isTravelBlocked || isDisabledGROAnalysis}
                size="sm"
                onClick={() => handleEditLocation(item)}
                title={
                  isDisabledGROAnalysis &&
                  'Não é possível edição durante período de análise GRO'
                }
                id={`button-editar-origem-${index}`}
              >
                <FaPencilAlt />
              </Button>
            </div>
          </div>
        ),
        body: (
          <>
            {item?.client ? (
              <Text color="gray" type="regular">
                {`Cliente: ${item?.client?.socialName} - ${item?.client?.cpfCnpj}`}
              </Text>
            ) : null}
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule && `${item.startSchedule} até `}
              {item.scheduledTime}
            </Text>
          </>
        ),
        filledCircle: false,
      })),
    ];

    if (!showAllDestinations && ordered.destinations.length > 1)
      items.push({
        header: (
          <Text color="light_blue" type="regular">
            <Styled.ModalLauncher onClick={handleShow} className="">
              {ordered.destinations.length - 1} destinos
            </Styled.ModalLauncher>
          </Text>
        ),
        link: true,
      });

    items.push(
      ...destinationsToDisplay.map((item, index) => ({
        header: (
          <div className="d-flex flex-row justify-content-between">
            <Text>{item.action.name}</Text>
            <div className="mb-1">
              <Button
                variant="error"
                size="sm"
                loading={loadingDelete}
                disabled={disable || !hasPermissionToEdit || isTravelBlocked}
                onClick={() => handleRemoveDestination(item)}
                className="mr-1"
                id={`button-deletar-destino-${index}`}
              >
                <FaTrash />
              </Button>
              <Button
                size="sm"
                onClick={() => handleEditLocation(item)}
                disabled={
                  !hasPermissionToEdit ||
                  isTravelBlocked ||
                  isDisabledGROAnalysis
                }
                title={
                  isDisabledGROAnalysis &&
                  'Não é possível edição durante período de análise GRO'
                }
                id={`button-editar-destino-${index}`}
              >
                <FaPencilAlt />
              </Button>
            </div>
          </div>
        ),
        body: (
          <>
            {item?.client ? (
              <Text color="gray" type="regular">
                {`Cliente: ${item?.client?.socialName} - ${item?.client?.cpfCnpj}`}
              </Text>
            ) : null}
            <Text className="my-2" color="gray" type="regular">
              {formatAddress(item)}
            </Text>
            <Text color="gray" type="regular">
              {item.startSchedule && `${item.startSchedule} até `}
              {item.scheduledTime}
            </Text>
          </>
        ),
        filledCircle: true,
      }))
    );

    return items;
  }

  function handleUpdateAddress(data) {
    delete data.id;
    delete data.country_id;
    delete data.current;
    setLocation({
      ...location,
      client,
      city_id: data?.city_id,
      address: data?.address,
      number: data?.number,
      neighborhood: data?.neighborhood,
      complement: data?.complement,
      zip_code: data?.zip_code,
      lat: data?.lat,
      lng: data?.lng,
      city: data?.city_name,
      province: data?.uf_name,
      client_id: client?.id,
      formatted_address: data?.formatted_address,
    });
  }

  function handleClientChange(event) {
    disableAddress({}, null);
    if (event !== null) {
      const currentAddress = event?.addresses.filter(
        address => address.current
      )[0];
      if (checkClientAddressData(currentAddress)) {
        snackbar.show(
          <Text>Endereço do cliente desatualizado! Complete o cadastro</Text>,
          { type: 'error' }
        );
        setClientCurrentAddress(currentAddress);
        setClient(event);
        return updateClientAddressModal.open();
      }
      setLocation({ ...location, client: event });
    } else {
      setLocation({ ...location, client: event });
    }
  }

  const handleConfirmLowerMargin = value => {
    setConfirmModalValidator(old => ({ ...old, ...value }));
    lowerMarginModal.close();
    handleSaveLocation(value);
  };

  return (
    <>
      <Modal
        scrollable
        show={show}
        onHide={handleClose}
        heading={<Text>Origem / Destinos</Text>}
        body={
          <Row>
            <Col xs={12} className="mt-4 pl-4">
              <List items={locationsObject()} />
            </Col>
          </Row>
        }
      />
      <Col xs={12}>
        <UpdateClientAddressData
          show={updateClientAddressModal.isOpen}
          onSubmit={handleUpdateAddress}
          onClose={() => updateClientAddressModal.close()}
          currentAddressData={clientCurrentAddress}
          countryOptions={countryList}
        />
        <Card
          loading={loadingCard}
          header={
            <Text color="#464E5F" type="header">
              Origem / Destino
            </Text>
          }
        >
          <Row>
            <Col lg={6} md={6} xs={12}>
              <Row className="form">
                <Col xs={12} className="mb-3">
                  <Select
                    label="País *"
                    options={countryList}
                    value={location.country}
                    onChange={country => {
                      handleChange(country, 'country');
                    }}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    error={errors.country}
                    isDisabled={disable}
                    id="select-pais"
                  />
                </Col>
                <Col xs={12} className="mb-3">
                  <Select.Async
                    label="Cliente"
                    onSearch={search =>
                      fetchSelectOptions('persons/customers', { search })
                    }
                    value={location?.client}
                    getOptionLabel={option => {
                      let label =
                        option?.socialName && option?.cpfCnpj
                          ? `${option?.socialName} - ${option?.cpfCnpj}`
                          : `${option?.social_name} - ${option?.cgccpf}`;

                      if (option.city) {
                        label += ` - ${option?.city}`;
                      }
                      return label;
                    }}
                    getOptionValue={option => option?.id}
                    onChange={event => {
                      handleClientChange(event);
                    }}
                    isDisabled={disable}
                    id="select-cliente"
                  />
                </Col>
                <Col xs={8} className="mb-3">
                    <InputAddress
                      label="Logradouro*"
                      placeholder=""
                      value={location.address}
                      onSelectedAddress={handleLocationAddress}
                      onChange={event => {
                        setLocation(old => ({
                          ...old,
                          address: event?.target?.value,
                          number: '',
                          complement: '',
                          neighborhood: '',
                          zip_code: '',
                          lat: '',
                          lng: '',
                          cityModel: '',
                          province: '',
                        }));
                      }}
                      types={[]}
                      error={errors.address}
                      country={
                        location.country ? location.country.abbreviation : null
                      }
                      disabled={disable}
                      id="input-logradouro"
                    />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="Número"
                    value={location.number}
                    onChange={event =>
                      handleChange(event.target.value, 'number')
                    }
                    disabled={disableNumber}
                    id="input-numero"
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="Complemento"
                    value={location.complement}
                    onChange={event =>
                      handleChange(event.target.value, 'complement')
                    }
                    disabled={disable}
                    id="input-complemento"
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="Bairro"
                    value={location.neighborhood}
                    onChange={event =>
                      handleChange(event.target.value, 'neighborhood')
                    }
                    disabled={disableNeighborhood}
                    id="input-bairro"
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="CEP"
                    value={location.zip_code}
                    onChange={event =>
                      handleChange(event.target.value, 'zip_code')
                    }
                    error={errors.zip_code}
                    disabled={disableZipCode}
                    id="input-cep"
                  />
                </Col>
                <Col xs={8} className="mb-3">
                  <Select.Async
                    label="Cidade *"
                    onSearch={city =>
                      fetchProvinces(city, '', location.country?.abbreviation)
                    }
                    value={location.cityModel}
                    horizontal
                    creatable
                    onChange={setDestinationCity}
                    getOptionLabel={option =>
                      `${`${option.name} ${
                        option.province?.uf ? `- ${option.province?.uf}` : ''
                      }`}`
                    }
                    getOptionValue={option =>
                      `${`${option.name} ${
                        option.province?.uf ? `- ${option.province?.uf}` : ''
                      }`}`
                    }
                    error={errors.cityModel}
                    isDisabled={disableCity}
                    id="select-cidade"
                  />
                </Col>
                <Col xs={4} className="mb-3">
                  <Input
                    label="UF *"
                    value={location.province}
                    onChange={event =>
                      handleChange(event.target.value, 'province')
                    }
                    error={errors.province}
                    disabled={disableProvince}
                    id="input-uf"
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <Input
                    label="Latitude"
                    disabled
                    value={location?.lat}
                    error={errors.lat}
                    tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                    id="input-latitude"
                  />
                </Col>
                <Col md={6} className="mt-2">
                  <Input
                    label="Longitude"
                    disabled
                    value={location?.lng}
                    error={errors.lng}
                    tooltip="Selecione um endereço sugerido para obter dados de latitude e longitude"
                    id="input-longitude"
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Input
                    placeholder="Digite uma data"
                    label={
                      location.type === 'destination'
                        ? 'Data Início da entrega *'
                        : 'Data Início da coleta *'
                    }
                    icon={<FaRegCalendar color="#494950" />}
                    value={location.startSchedule}
                    onChange={event =>
                      handleChange(event.target.value, 'startSchedule')
                    }
                    masked
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      ':',
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    error={errors.startSchedule}
                    disabled={disable}
                    id="input-data-inicio"
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Input
                    placeholder="Digite uma data"
                    label={
                      location.type === 'destination'
                        ? 'Data máxima da entrega *'
                        : 'Data máxima da coleta *'
                    }
                    icon={<FaRegCalendar color="#494950" />}
                    value={location.scheduledTime}
                    onChange={event =>
                      handleChange(event.target.value, 'scheduledTime')
                    }
                    masked
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      '/',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      ':',
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    error={errors.scheduledTime}
                    disabled={disable}
                    id="input-data-maxima"
                  />
                </Col>
                <Col xs={6} className="mb-3">
                  <Select
                    label="Ação *"
                    value={location.action}
                    onChange={event => handleChange(event, 'action')}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    error={errors.action}
                    options={actionOptions}
                    isDisabled={location.type === 'origin'}
                    id="select-acao"
                  />
                </Col>
                <Col xs={12} className="text-center">
                  <Button
                    className="mt-3"
                    onClick={handleSaveLocation}
                    loading={loadingSave}
                    disabled={
                      disable || !hasPermissionToEdit || isTravelBlocked
                    }
                    id="button-salvar-endereco"
                  >
                    <Text weight={500} type="regular">
                      Salvar
                      {location.type === 'origin' ? ' origem' : ' destino'}
                    </Text>
                  </Button>
                </Col>
                {errors.dateError && (
                  <Col xs={12} className="text-center">
                    <Text type="label" color="#f64e60">
                      {errors.dateError}
                    </Text>
                  </Col>
                )}
              </Row>
            </Col>

            <Col lg={6} md={6} xs={12}>
              <div className="container-fluid load-adresses list-maps pt-3 pb-5">
                <Row className="form">
                  <Col xs={12}>
                    {locations.length > 0 && (
                      <Map
                        height={200}
                        yesIWantToUseGoogleMapApiInternals
                        position={{
                          lat: locations[0].latitude,
                          lng: locations[0].longitude,
                        }}
                        onGoogleApiLoaded={({ map, maps }) =>
                          onGoogleAPILoaded(map, maps)
                        }
                      >
                        {origins.length > 0 &&
                          origins.map(origin => (
                            <MarkerLetter
                              background={theme.colors.light_blue}
                              lat={origin.lat}
                              lng={origin.lng}
                              color="#fff"
                              letter="C"
                            />
                          ))}
                        {destinations.length > 0 &&
                          destinations.map(destination => (
                            <MarkerLetter
                              background={theme.colors.light_blue}
                              lat={destination.lat}
                              lng={destination.lng}
                              color="#fff"
                              letter={
                                destination.action.id === 'delivery' ? 'E' : 'A'
                              }
                            />
                          ))}
                      </Map>
                    )}
                  </Col>
                  {origins.length === 0 && destinations.length === 0 && (
                    <Col xs={12} className="text-center">
                      <Badge type="warning" outlined className="rounded">
                        Essa carga ainda nao possui origem e nem destino.
                      </Badge>
                    </Col>
                  )}
                  <Col xs={12} className="pt-3">
                    <List items={locationsObject(false)} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      <MinimumMarginModal
        isOpen={lowerMarginModal.isOpen}
        onClose={() => {
          setConfirmModalValidator(old => ({
            ...old,
            confirmMinimiumMargin: false,
          }));
          lowerMarginModal.close();
        }}
        confirm={handleConfirmLowerMargin}
      />
      <ConflictDriverModal
        isOpen={driverAvailableModal.isOpen}
        onClose={() => {
          setConfirmModalValidator(old => ({
            ...old,
            passDriverValidation: false,
          }));
          driverAvailableModal.close();
        }}
        onConfirm={() => {
          setConfirmModalValidator(old => ({
            ...old,
            passDriverValidation: true,
          }));
          handleSaveLocation({ passDriverValidation: true });
          driverAvailableModal.close();
        }}
        travels={validateDriverProps?.conflictTravels}
        travelData={{ driver: validateDriverProps.driver }}
      />
    </>
  );
}

import React, { useCallback, useState, useEffect } from 'react';

import moment from 'moment';
import dashboardApi from 'services/apis/dashboard';
import { Table, Text, Link, Button } from 'v3/components';
import { useQuery } from 'hooks';
import { formatDateTime } from 'v3/utils/formatter';
import { fetchUserById } from 'utils/fetches';

import Card, { Number, Description, SeeAll } from './Card';

export default function AwaitingIssueTravels({ dashboard, name, id }) {
  const [isFetching, setFetching] = useState(false);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [currentPage, setCurrentPage] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [daysFromNow, setDaysFromNow] = useState();
  const [groupUser, setGroupUser] = useState();

  const queryParams = useQuery();

  useEffect(() => {
    setDaysFromNow(moment(new Date()).diff(filter.start_date, 'days'));
  }, [filter.start_date]);

  useEffect(() => {
    fetchUserById().then((response) => {
      const groupMercosul = response?.groups?.find(group => group.slug === 'MERCOSUL');
      setGroupUser(groupMercosul);
    })
  }, [])

  function setQueryParams(filters) {
    const { tags = [], start_date, end_date } = filters;

    queryParams.delete('tags');

    if (tags) {
      tags.forEach(tag => {
        queryParams.append('tags', tag.id);
      });
    }

    queryParams.set('status', 9);

    if (start_date) {
      queryParams.set('start_date', start_date);
    }

    if (end_date) {
      queryParams.set('end_date', end_date);
    }

    if (tags?.length === 0) {
      queryParams.set('no-tags', true);
    } else {
      queryParams.delete('no-tags');
    }
  }

  const fetchAwaitingIssueTravels = useCallback(async (filters = {}) => {
    try {
      setLoading(true);
      const {
        tags = [],
        clients = [],
        start_date,
        end_date,
        page = 1,
        negotiators = [],
        costCenters = [],
        recuseCostCenters = [],
        pcps = [],
        companies = [],
      } = filters;

      if (page === 1) {
        setFetching(true);
      }

      setQueryParams(filters);
      setFilter(filters);
      setCurrentPage(+page);

      const response = await dashboardApi.get('/travels-awaiting-issuance', {
        params: {
          tags: tags?.map(tag => tag.id),
          clients: clients?.map(client => client.id),
          start_date,
          negotiators: negotiators?.map(negotiator => negotiator?.id),
          costCenters: costCenters?.map(costCenter => costCenter?.id),
          recuseCostCenters: recuseCostCenters?.map(costCenter => costCenter?.id),
          pcps: pcps?.map(pcp => pcp?.id),
          companies: companies?.map(company => company?.id),
          end_date: end_date || undefined,
          page,
        },
      });

      setPagination(response?.data?.last?.pagination);

      if (page === 1) {
        setData(response?.data);
      } else {
        setData(oldData => ({
          ...oldData,
          last: {
            ...oldData?.last,
            data: [...oldData?.last?.data, ...response?.data?.last?.data],
          },
        }));
      }
    } catch (ex) {
      // Handle exception
    } finally {
      setFetching(false);
      setLoading(false);
    }
  }, []);

  return (
    <Card
      title="Viagens aguardando emissão"
      fetchFn={fetchAwaitingIssueTravels}
      onFiltersChanged={fetchAwaitingIssueTravels}
      excludeFilterByRecuseCostCenters={false}
      isLoading={isFetching}
      dashboard={dashboard}
      name={name}
      excludeFilterByBranchOffice={true}
      id={id}
      ListComponent={
        <>
          <Table striped>
            <thead>
              <tr>
                {
                  groupUser && (
                    <th>
                      <Text weight="500" color="dark">
                        Doc.MERCOSUL
                      </Text>
                    </th>
                  )
                }
                <th>
                  <Text weight="500" color="dark">
                    Centro de custo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Empresa
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Tags da carga
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Nº Pedido Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Viagem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Carregamento
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Negociador
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Origem
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Destino
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Cliente
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Motorista
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Veículo
                  </Text>
                </th>
                <th>
                  <Text weight="500" color="dark">
                    Último Evento
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.last?.data?.map(travel => {
                const title = `Cliente ${travel.social_name} - ${travel.cgccpf}`;

                return (
                  <tr>
                    {
                      groupUser && (
                        <td>
                          <Text type="regular" color="gray">
                            {travel?.status_mercosul || '-'}
                          </Text>
                        </td>
                      )
                    }
                    <td>
                      <Text type="regular" color="gray">
                        {travel?.cost_center || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel?.company_name || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel?.load_tags || '-'}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel?.client_order_number || '-'}
                      </Text>
                    </td>
                    <td>
                      <Link
                        to={`/viagens/${travel.attended_id}`}
                        title={title}
                        target="_blank"
                      >
                        {travel.attended_id}
                      </Link>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {formatDateTime(travel.scheduled_origin)}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel.user_created}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {travel.city_origin} - {travel.uf_origin}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {travel.city_destination} - {travel.uf_destination}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {travel.social_name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray" truncate>
                        {travel.driver_name}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel.plate}
                      </Text>
                    </td>
                    <td>
                      <Text type="regular" color="gray">
                        {travel.last_log}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {pagination?.lastPage > 1 && pagination?.to < pagination?.total && (
            <div className="text-center mt-3 col-12">
              <Button
                className="mt-2 ml-auto align-item-center"
                onClick={() => {
                  fetchAwaitingIssueTravels({
                    page: currentPage + 1,
                    tags: filter?.tags,
                    clients: filter?.clients,
                    start_date: filter?.start_date,
                    end_date: filter?.end_date,
                    costCenters: filter?.costCenters,
                    recuseCostCenters: filter?.recuseCostCenters,
                    pcps: filter?.pcps,
                    companies: filter?.companies,
                    negotiators: filter?.negotiators,
                  });
                }}
                loading={loading}
              >
                <Text type="regular" color="white" truncate>
                  Ver mais
                </Text>
              </Button>
            </div>
          )}
          {!!data.total && (
            <SeeAll
              to={{ pathname: '/viagens', search: queryParams.toString() }}
            >
              ver todas as {data.total} viagens
            </SeeAll>
          )}
        </>
      }
      NumericComponent={
        <div className="d-flex justify-content-center text-center flex-column">
          <Number color="warning">{data?.total}</Number>
          <Description>
            aguardando emissão
            {daysFromNow > 1
              ? ` nos últimos ${daysFromNow} dias`
              : ` no último dia`}
          </Description>
        </div>
      }
    />
  );
}
